// import { Card, CardMedia,  Stack } from "@mui/material";
import {
	Box,
	Typography,
	Stack,
	Container,
	Grid,
	Card,
	CardContent,
	CardMedia,
} from "@mui/material";
import React from "react";
import { studentImg, logo } from "../assets";

// import StackCard from "../component/common/StackCard";
import ImgCard from "../component/common/ImgCard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DevicesIcon from "@mui/icons-material/Devices";
import VerifiedIcon from "@mui/icons-material/Verified";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const LearningExperience = ({ product }) => {
	// const cousrse =[
	//   {id:1, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},
	//   {id:2, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},
	//   {id:3, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},
	//   {id:4, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},

	// ]
	const courses = [
		{
			id: 1,
			title: "24/7 Accessibility",
			Desc: "Learn anytime, anywhere with our seamless platform.",
			icon: <AccessTimeIcon fontSize="large" />,
		},
		{
			id: 2,
			title: "Multi-Device Support",
			Desc: "Access courses on any device, be it mobile, tablet, or desktop.",
			icon: <DevicesIcon fontSize="large" />,
		},
		{
			id: 3,
			title: "Certified Quality",
			Desc: "Get certified courses with top-notch content and instructors.",
			icon: <VerifiedIcon fontSize="large" />,
		},
		{
			id: 4,
			title: "Dedicated Support",
			Desc: "Our support team is here to help you 24/7.",
			icon: <SupportAgentIcon fontSize="large" />,
		},
	];

	return (
		<div>
			<Container>
				<Grid container direction="row " sx={{ my: 6 }}>
					<Grid display="flex" justifyContent="center" lg={6} md={6} xs={12}>
						<ImgCard src={studentImg} />
					</Grid>

					<Grid textAlign="" alignContent="center" xs={12} md={6} lg={6}>
						<div className="ml-2 align-middle">
							<h1 className="text-6xl font-extrabold">
								Premium <span className="text-pink-600"> Learning </span>
								<br />
								Experience
							</h1>
							{courses.map((course, id) => (
								<Card
									variant="elevation"
									elevation={5}
									sx={{
										display: "flex",
										alignItems: "center",
										my: 2,
										backgroundColor: "transparent",
									}}
								>
									<Box sx={{ p: 2 }}>{course.icon}</Box>
									<CardContent>
										<Typography component="h5" variant="h6">
											{course.title}
										</Typography>
										<Typography variant="body2" color="text.secondary">
											{course.Desc}
										</Typography>
									</CardContent>
								</Card>
							))}
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default LearningExperience;
