


    import { Facebook, FactCheck, Google, Visibility, VisibilityOff } from '@mui/icons-material'
    import { Box, Button, Card, CardMedia, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from '@mui/material'
    import React, { useState } from 'react'
    import CssCarousal from '../component/slider/CssCarousal'
    import { SwiperSlide } from 'swiper/react'
    import img from '../assets/hero1.png'
import { useNavigate } from 'react-router-dom'
    const TeacherLogin = () => {
      const navigate = useNavigate()
      

      const [showPassword, setShowPassword] = React.useState(false);
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      const handleClickShowPassword = () => setShowPassword((show) => !show);
       const [formvalue, setFormvalue] = useState({})
      const handleChange =(event)=>{
        const name =event.target.name
        const value =event.target.value
        setFormvalue({
          ...formvalue, [name]:value
        })
      
      }

      const handleClick =()=>{
        if(
          formvalue.email == 'admin@sudo24.com'&& formvalue.password =='1234')
{  
  const user ={
    'user':'fffff',
    '_role':'teacher'
  }
  localStorage.setItem('_user',user)
        navigate('/dashboard')  
      }else{
        alert('username  or password is invalid')
      }
      }
      return (
        <div>
          <Container sx={{mt:5}} maxWidth='lg'>
            <Box sx={{ 
              boxShadow:'10',
               
            }}>
              <Grid direction={{md:'row', xs:'column-reverse'}} container >
                <Grid item xs={12} md={6}>
                <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
              <Box sx={{m:5}}>
                <Typography sx={{mb:5}} component='h2'>
                  Sign In
                </Typography>
                <TextField
                 sx={{width:'100%',
                mb:2}}
                 required
                 name='email'
                 value={formvalue.email}
                 onChange={handleChange}
                 id="outlined-required"
                 label="Email or username"
                
                 />
                {/* <TextField
                 sx={{
                  width:'100%',
                  mb:2
                }}
                   required
                    id="outlined-required"
                 label="Password"
              defaultValue="Password"
              >
                 <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
              </TextField> */}
               
          <OutlinedInput
            sx={{
              width:'100%',
              mb:2
            }}
            id="outlined-adornment-password"
            placeholder='Password'
            name='password'
            value={formvalue.password}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            
          />

              <Button disabled={formvalue.password && formvalue.email ? false: true} onClick={handleClick} variant='contained' size='large' sx={{
                width:'100%'
              }} color='secondary'>
                Sign In
              </Button>
              <Box display='flex' justifyContent='space-between'>
               <Button variant='text'>
                 Forgot Password
               </Button>
               <Button variant='text'>
                 Forgot Username
               </Button>
    
              </Box>
              <Divider>OR</Divider>
              <Stack sx={{mt:1}} rowGap={1.5}>
                {/* <Button startIcon={<Facebook/>} variant='contained'>
                 login via Truecaller
                </Button> */}
                <Button color='error' size='large' variant='contained'>
                 login via Secret ID
                </Button>
                {/* <Button color='info' startIcon={<Facebook/>} variant='contained'>
                 login via Facebook
                </Button> */}
              </Stack>
    
              </Box>
    
            </Card>
                          </Grid>
                          <Grid xs={12} md={6}>
                            <CssCarousal>
                            {
      [0,1,3].map((key)=>
    <SwiperSlide key={key}>
    <CardMedia
        component="img"
        alt="Contemplative Reptile album cover"
        src={ img}
        sx={{
          position:'relative',
            p:0,
            my:2,
            width: { xs: '100%', sm: '100%' },
          borderRadius: 2,
          ":hover":{
            transitionTimingFunction:'ease-in-out',
            backgroundColor:'blueviolet'
          }
        }}
      />
    </SwiperSlide>
    )}
    
                            </CssCarousal>
                          </Grid>
                        </Grid>
            <Card>
    
            </Card>
            </Box>
          </Container>
        </div>
      )
    }
    
    export default TeacherLogin