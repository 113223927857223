import { HomeOutlined, Search } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardMedia, Grid, Input, InputBase, Paper, Stack, TextField, TextareaAutosize, Typography, styled } from '@mui/material'
import React, { Component , useState, useEffect} from 'react'
import { hero2 } from '../assets';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Faq from '../component/common/Faq';
import VisuallyHiddenInput from '../component/common/VisuallyHiddenInput';
import { accorditionse, topics } from '../data/contactus';
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet';



const ContactUs = () => {
   const [isLoding, setisLoding] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setisLoding(false)
    }, 1000);
  
  },[])
  
if(isLoding){
  return<Loading/>
}
  

    
  return (
    <>
    <Helmet>
      <title>
        Contact US
      </title>
    </Helmet>
    
    <Grid
      sx={{mt:3, mb:50, mx:3}} rowGap={7} container> 
        <Grid item lg={12} >

            <Typography variant='h4' sx={{mb:1}} component='div'>
                Contact Us
             </Typography>
        </Grid> 
        <Grid item sm={12} lg={12} >

            
           <Card  sx={{
            position:'relative'
           }} alignItems='center'>
            <CardMedia
            component='img'
            src={hero2}
            sx={{
                objectFit:'cover',
                height:'50vh'
            }}
            alt='image'
            />
            <CardContent   sx={{
                position:'absolute',
                 zIndex:20,
                  top:2 ,
                  left:2,
                  display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection:'column',
                  
                   height:'100%',
                   width:'100%',
                 
                  }
                  }>

            <Typography variant='h4' sx={{mb:2}} fontWeight={800} component='div'>
                How we Can Help you
            </Typography>
            <Paper
            sx={{
                borderRadius:4,
                boxShadow:3,
                px:2,
                py:0
            }}
            >
             <Search/>
           <InputBase  sx={{
               maxWidth:'50vh',
               minWidth:'300px'
           }} type='text' aria-label='send' placeholder='Send Massage'/>
            </Paper>

                </CardContent>
        </Card>
   
        </Grid>
       <Grid alignItems='center' item sm={12} lg={12} >
       
                <Typography textAlign='center' variant='h4' sx={{mb:2}} fontWeight={800} component='div'>
                   Browse Our Topics
                </Typography>
                <Grid rowGap={4} container>
                   
                 
                {topics.map((topic, id)=>
                        <Grid item key={id} xs={6} lg={4}>
                        <Card 
                        elevation={5}
                         sx={{
                            p:3,
                         mx:3,
                         backgroundColor:'transparent',
                         ":hover":{
                            boxShadow:5,
                            zoom:1.02
                         }
                        }}>
                            <Stack alignItems='center'> 
                              
                              {topic.icon}
                            </Stack>
                            <Typography fontWeight={700} sx={{mb:2}} variant='h5' component='div' textAlign='center'> 
                               {topic.title}
                            </Typography>
                            <Typography variant='p' component='div' textAlign='center'> 
                               Get Started
                            </Typography>
                            <Typography  variant='p' component='div' textAlign='center'> 
                               Get Started
                            </Typography>
                            <Typography  variant='p' component='div' textAlign='center'> 
                               Get Started
                            </Typography>

                        </Card>
                     
                    </Grid>
                )}
                 
                </Grid>
            
       </Grid>
       <Grid  sx={{
        boxShadow:5
       }} item sm={12} lg={12}>
        <Grid direction={{md:'row',xs:'column-reverse'}} container>
            <Grid item xs={12} md={6}>
            <Card 
                        elevation={0}
                         sx={{
                             p:3,
                             
                             backgroundColor:'transparent',
                             
                            }}>
                            

                       
                <Box sx={{m:5}}>

                            <Typography fontWeight={800} sx={{mb:5}} variant='h4' component='div' textAlign='center'> 
                              FAQ's
                            </Typography>
                            <Faq accorditions={accorditionse}/>
                </Box>
                          

                        </Card>
            </Grid>
            <Grid item xs={12} md={6}>
            <Card 
                        elevation={0}
                         sx={{
                            p:3,
                         backgroundColor:'transparent',
                      
                        }}>
                             <Box sx={{m:5}}>
            <Typography sx={{mb:5}} fontWeight={800} textAlign='center' component='div' variant='h4'>
            Raise Your Ticket
            </Typography>
            <TextField
             sx={{width:'100%',
            mb:2}}
             required
             id="outlined-required"
             label="Name"
             placeholder='Name'
          
             />
            <TextField
             sx={{
              width:'100%',
              mb:2
            }}
               required
                id="outlined-required"
             label="Email"
             placeholder='Email'
         
          />
            <TextField
             sx={{
              width:'100%',
              mb:2
            }}
               required
                id="outlined-required"
             label="Mobile No"
             placeholder='Mobile No'
         
          />
            <TextField
             sx={{
              width:'100%',
              mb:2
            }}
            minRows={5}
           
               
             label="Massage"
             placeholder='Massage'
         />
         <Box display='flex' justifyContent='space-between'>

             <Button
             component="label"
             role={undefined}
             variant="contained"
            size='small' 
             tabIndex={-1}
             startIcon={<CloudUploadIcon />}
             >
             Upload file
             <VisuallyHiddenInput type="file" />
           </Button>
       
         <Button variant='contained' size='large' color='success'>
            Submit
         </Button>
               </Box>
         
       

          </Box>
                        </Card>
            </Grid>
        </Grid>
       </Grid>
       <Grid alignItems='center' item sm={12} lg={12} >
       
       <Typography textAlign='center' variant='h4' sx={{mb:2}} fontWeight={800} component='div'>
         Need More Help
       </Typography>
       <Box display='flex' rowGap={2} flexDirection='column' justifyContent='center' alignItems='center'>

       <Button variant='contained' color='success' >
        Chat With Us
       </Button>
       <Button  variant='contained' color='info' >
        Call with Us
       </Button>
       </Box>
       </Grid>
      </Grid>
    </>
  )
}

export default ContactUs