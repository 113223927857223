import {
	Box,
	Button,
	Card,
	Container,
	Divider,
	Grid,
	InputBase,
	Paper,
	Stack,
	Typography,
	IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCard from "../component/ProductCard";
import Hero from "../section/Hero";
import Storeis from "../section/Storeis";
import Steps from "../section/Steps";
import Award from "../section/Award";
import Hire from "../section/Hire";
import Support from "../section/Support";
import LearningExperience from "../section/LearningExperience";
import CarouselNav from "../component/slider/CarosalNav";
import EffectCarousel from "../component/slider/FadeCarousal";
import StudentCard from "../component/common/StudentCard";
import ContainerCard from "../component/common/ContainerCard";
import Specialist from "../section/Specialist";
import Reviews from "../component/Reviews";
// assets
import {
	hero1,
	hero2,
	hero4,
	hero14,
	hero15,
	hero13,
	studentImg,
} from "../assets";
import Courses from "../section/Courses";
import LifeCard from "../component/common/LifeCard";
import { SwiperSlide } from "swiper/react";
import ImgCard from "../component/common/ImgCard";
import CssCarousal from "../component/slider/CssCarousal";
import { blue } from "@mui/material/colors";
import { Search } from "@mui/icons-material";
import SliderCard from "../component/common/SltderCard";
import Highlights from "../section/Home/highlights";
import GridCard from "../component/common/cards/GridCard";
import { listCourses, listData, listProduct } from "../data/listproduct";
import { Helmet } from "react-helmet";
import Reels from "../section/Reels";
import Loading from "../component/common/Loading";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AnimatedStats from "../section/AnimatedStats";

import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import app from "../assets/app.mp4";
const Home = () => {
	const [isLoding, setisLoding] = useState(true);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	useEffect(() => {
		setTimeout(() => {
			setisLoding(false);
		}, 1000);
	}, []);

	if (isLoding) {
		return <Loading />;
	}

	const listProduct1 = [
		{ id: "1", img: hero1 },
		{ id: "2", img: hero2 },
		{ id: "3", img: hero4 },
		{ id: "4", img: hero1 },
		{ id: "5", img: hero2 },
		{ id: "6", img: hero4 },
		{ id: "7", img: hero1 },
		{ id: "8", img: hero2 },
		{ id: "9", img: hero4 },
		{ id: "7", img: hero1 },
		{ id: "8", img: hero2 },
		{ id: "9", img: hero4 },
	];

	const teacherImages = [hero13, hero14, hero15];
	const StudentImages = [hero15, hero13, hero14];
	const heroCard = [
		{
			id: 1,
			title: "Become Teacher",
			to: "/becomeateacher",
			img: teacherImages,
		},
		{
			id: 1,
			to: "/becomeastudent",
			title: "Become  Student",
			img: StudentImages,
		},
		// {id:1, title: 'Become  Student', img:hero2}
	];

	const item = (
		<>
			<Paper
				component="form"
				sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 500 }}
			>
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder="Enter You Email Id"
					type="email"
					inputProps={{ "aria-label": "Enter You Email Id" }}
				/>
				<Button
					color="success"
					variant="contained"
					type=""
					sx={{ p: "10px" }}
					aria-label="Email"
				>
					<Search />
				</Button>
			</Paper>
		</>
	);

	return (
		<Grid
			sx={{
				backgroundImage: "linear-graidient( red, yelllow)",
				p: 0,
				overflowX: "hidden",
				display: "block",
				mt: { xs: 0, md: 0 },
			}}
			container
		>
			<Helmet>
				<title>Home</title>
			</Helmet>
			<div className="relative lg:my-6  flex">
				<div className="left-[5vw] z-10 md:top-[15vh] top-[8vh]   absolute lg:h-[60vh] overflow-hidden  ">
					<h1 className="lg:text-3xl xs:text-lg lg:mb-14 xs:mb-2 font-bold ">
						"Empowering Minds,
						{isMobile ? (
							<>
								<p className="xs:mb-1">Igniting Futures: </p>
								<p>
									Explore Limitless <br /> Learning with Us!"
								</p>
							</>
						) : (
							<>
								<span className="lg:my-5 ">Igniting Futures:</span>
								<p>Explore Limitless Learning with Us!" </p>
							</>
						)}
						<span className="text-green-700">Tommorrow's </span>
						<span className="text-pink-500">skill Today</span>
					</h1>
					<Typography
						sx={{ display: { xs: "none", md: "block" } }}
						color={"whitesmoke"}
						component={"p"}
					>
						Elearn is a global training provider Elearn is a global training
						provider Elearn is a global training provider
					</Typography>
					<Paper
						component="form"
						sx={{
							p: { md: "2px 4px", xs: 0 },
							display: "flex",
							alignItems: "center",
							width: { md: "400px", xs: "200px" },
						}}
					>
						<InputBase
							sx={{ mx: { md: 1, xs: 0 }, flexGrow: 1 }}
							placeholder="Enter You Email Id"
							type="email"
							inputProps={{ "aria-label": "Enter You Email Id" }}
						/>
						<Button
							color="success"
							variant="contained"
							type=""
							sx={{ p: "10px" }}
							aria-label="Email"
						>
							<Search />
						</Button>
					</Paper>
				</div>
				<div className=" lg:h-[80vh]   lg:mt-1 xs:mt-3  opacity-30  overflow-x-hidden  flex">
					<Card sx={{ display: "flex" }}>
						<CarouselNav />
					</Card>
				</div>
				<div className="mx- right-[5vw] md:right-9 top-[6vh] z-20  lg:flex   lg:top-[15vh] absolute lg:h-[60vh] overflow-hidden  ">
					{/* <Box>
						<Card>
							<Grid container> */}
					<EffectCarousel />
					{/* </Grid>
						</Card>
					</Box> */}
				</div>
			</div>
			<Grid
				rowGap={2}
				alignContent="center"
				display={"flex"}
				justifyContent="space-evenly"
				className=" py-9"
				container
			>
				{heroCard.map((product, id) => (
					<Grid lg={5} md={6} sm={12} xs={12} key={id}>
						<StudentCard product={product} />
					</Grid>
				))}
			</Grid>
			<LearningExperience />
			<Grid container>
				<Courses listProduct={listCourses} />
			</Grid>
			<Reels />
			<Specialist heading={"Our Specilist"} listProduct={listProduct} />
			<AnimatedStats />
			{/* <Grid container>
				<Stack
					sx={{
						boxShadow: 2,
						py: 3,
						m: 3,
						":hover": {
							boxShadow: 8,
						},
					}}
					textAlign="center"
					width="100%"
					justifyContent="center"
				>
					<Stack justifyContent="center">
						<Typography component="div" variant="h4">
							India's Most Loved PlateForm
						</Typography>
					</Stack>
					<Stack justifyContent="center">
						<Typography component="div" variant="subtitle2">
							With SUDO24, Begin your journey with success
						</Typography>
					</Stack>
					<Grid sx={{ mt: 5 }} textAlign="center" container>
						{["10k", "10k", "10k", "10k"].map((subscribe, key) => (
							<Grid item md={3} xs={12} lg={3} key={key}>
								<Typography component="div" variant="h4">
									{subscribe}
								</Typography>
								<Typography component="div" variant="subtitle1">
									Video Lectures
								</Typography>
							</Grid>
						))}{" "}
					</Grid>
				</Stack>
			</Grid> */}
			<Specialist heading={"What Students Say"} listProduct={listData} />
			{/* <Reviews heading={"What Students Say"} listProduct={listData} /> */}
			<div className="my-8 w-full">
				<Hire
					heading="Asked Questions"
					desc="You Have Any Question Ask Frankly "
					item={item}
				/>
			</div>
			<Highlights
			// heading={"Our Highlights"}
			// listProduct={listProduct1}
			/>

			<div className="w-full my-7">
				<ContainerCard width="lg">
					<Grid container>
						<Grid item lg={6}>
							<div className="mx-8 my-7 text-left">
								<h1 className="text-5xl mb-[3vw] font-extrabold">
									Get The Sudo24 App
								</h1>
								<desc className="text-slate-600   ">
									Download lesson and learn anytime, anywhere with the Sudo24
									App.
								</desc>
								<div className="flex gap-5 text-slate-600">
									Download the app for android & apple iOS
								</div>
								<Box className="flex gap-5 mt-4">
									<IconButton component="a" href="#">
										<AndroidIcon style={{ fontSize: 40 }} />
									</IconButton>
									<IconButton component="a" href="#">
										<AppleIcon style={{ fontSize: 40 }} />
									</IconButton>
								</Box>
							</div>
						</Grid>
						<Grid item xs={12} md={6} container justifyContent="center">
							<Box
								mt={{ xs: 4, md: 0 }}
								textAlign="center"
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<video
									style={{
										width: "100%",
										maxWidth: "500px",
										maxHeight: "300px",
										borderRadius: "8px",
									}}
									src={app}
									type="video/mp4"
									autoPlay
									muted
									loop
								>
									Your browser does not support the video tag.
								</video>
							</Box>
						</Grid>
					</Grid>
				</ContainerCard>
			</div>
		</Grid>
	);
};

export default Home;
