import React from 'react'
import ReelCard from '../component/common/ReelCard'
import Reel from '../assets/reels/reel1.mp4'
import Reel2 from '../assets/reels/reel2.mp4'
import { Box, Card, Container, Grid, Stack } from '@mui/material'
import { SwiperSlide } from 'swiper/react'
import CssCarousal from '../component/slider/CssCarousal'
import ProductCard from '../component/ProductCard'
import Heading from '../component/common/Heading/Heading'
const Reels = () => {

    const reels =[
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        {id:1, src:Reel, },
        {id:1, src:Reel2, },
        
    
      ]
  return (
    <>
     <Heading heading='Popular Reels' subtitle2=' Live learning with industry experts. Jobs at technology companies.' />
        <Grid display='block' container className='' >
          <Card elevation={0} sx={{mx:2, display:'flex', bgcolor:'transparent'}}>
        <CssCarousal
        
      
        breakpoints={{300:{
          width:180,
          slidesPerView:'1'
         }}}
          slide={{xs:1, md:3}} >

    {reels.map((product, id)=>
               <SwiperSlide>
              <Grid lg={12} md={12} sm={12} xs={12} key={id} >
                 <ReelCard src={product.src}/>
              </Grid>
               </SwiperSlide>
                )}
          
                </CssCarousal>
                
              </Card>  
        </Grid>
   
 
  {/* <div className=' overflow-hidden  block'>
    

    <Grid sx={{display:'flex'}} container>
    

    <CssCarousal
    navigation
    
    slide={2} >

        {reels.map((Reel, id)=>
        <SwiperSlide>

       

        <ReelCard key={id} src={Reel.src} />
        
        </SwiperSlide>
       
       )}
       </CssCarousal>
    
    
       </Grid>
          
      </div> */}
  </>
  )
}

export default Reels