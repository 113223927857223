import { Box, Card, CardMedia, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import img from '../../assets/hero1.png'
import { ArrowBack } from '@mui/icons-material'
import { Link } from 'react-router-dom'
const StudentRefer = () => {

    const earn =[
        {id:1, title:'Selected', earning:'0.00'},
        {id:2, title:'UnSelected', earning:'0.00'},
    ]
  return (
    <>
     <Box mt={{lg:3,xs:0}}>
         <Stack direction='row'  justifyContent='flex-start'>
         <Link to='/dashboard'>
             <ArrowBack/> 
             </Link>
             <Typography
             component='div'
             fontWeight={800}
             variant='h6'>
              No of refer 
             </Typography>
         </Stack>
             <Container maxWidth='sm'>
         <Card sx={{bgcolor:'transparent'}}>
            <CardMedia
            component='img'
            sx={{
                objectFit:'cover',
                height:'200px'
               }}
            src={img}
            />
         </Card>
         <Box  sx={{ my:2}}>
         <Card sx={{textAlign:'center'}}>
           <Typography
           component='div'
           variant='h3'>
            Total Reffral
           </Typography>
           <Typography
           component={'div'}
           variant='h2'>
            200.00
           </Typography>
         </Card>
         {earn.map((card )=>
                
                <Stack 
               
                key={card.id}
                borderRadius={2}
                my={1}
                textAlign='center'
                bgcolor='lightgrey'
                fontWeight={800} direction='row' justifyContent='space-between' 
                px={1}>
                   <Typography
                   component='div'
                   variant='h6'>
                      {card.title}
                   </Typography>
                   <Typography
                   textAlign='center'
                   component='div'
                   color='lightcoral'
                   variant='body1'>
                     {card.earning}
                   </Typography>
                </Stack>
                )}
               </Box>
               </Container>
         </Box>
    </>
  )
}

export default StudentRefer