import React, { useEffect, useState } from 'react'
import Loading from './Loading';
import { Box } from '@mui/material';

const LoadingPage = ({children}) => {
    const [isLoding, setisLoding] = useState(true)
    useEffect(() => {
      setTimeout(() => {
        setisLoding(false)
      }, 1000);
    
    },[])
    
  if(isLoding){
    return(
        <Box t mt={2} >
        <Loading/>
    
        </Box>
    )
  }
  return (
    <div>{children}</div>
  )
}

export default LoadingPage