import { ArrowBack, CheckBoxRounded, Forward, LockClockOutlined, LockClockSharp } from '@mui/icons-material'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'

import React from 'react'
import StackCard from '../../component/common/StackCard'
import { Link } from 'react-router-dom'

const LiveClass = () => {
  return (
    <>
    <Box mt={{md:3,xs:7}}>
       <Typography
       component='div'
       variant='subtitle1'>
        <Link to='/dashboard' >
                 <ArrowBack/>
          </Link>
        Upcoming Classes
       </Typography>
       <Box my={3}>
        <Box >
        <Grid mb={2} container>
          <Grid item xs={3}>
            <Box width='100%'
            height='100%'
            bgcolor='lightgrey'>

            </Box>
          </Grid>
          <Grid px={1} item xs={9}>
            <Typography component='div'
            variant='body1'>
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            </Typography>
            <Box mt={1}>
              <Stack gap={2} direction='row'>
               <LockClockOutlined/> Time <Typography
               component='div'
               variant='caption'>
                00.00
               </Typography>
              </Stack>
            </Box>
          </Grid>

        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <Box width='100%'
            height='100%'
            bgcolor='lightgrey'>

            </Box>
          </Grid>
          <Grid px={1} item xs={9}>
            <Typography component='div'
            variant='body1'>
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            Lorem  Lorem  Lorem  Lorem  Lorem  Lorem
            </Typography>
            <Box mt={1}>
              <Stack gap={2} direction='row'>
               <LockClockSharp/> Time <Typography
               component='div'
               variant='caption'>
                00.00
               </Typography>
              </Stack>
            </Box>
          </Grid>

        </Grid>
        </Box>
        <Stack mt={3} rowGap={4}>
          <Button endIcon={<CheckBoxRounded/>} sx={{boxShadow:5, px:2, mx:'auto'}}>
             Present
          </Button>
          {/* <Button endIcon={<Forward/>} sx={{boxShadow:5, px:2}}>
             Change Your Schedule
          </Button> */}
          <Button endIcon={<Forward/>} sx={{boxShadow:5, px:2, mx:'auto'}}>
             Change Your Schedule
          </Button>
        </Stack>

       </Box>
    </Box>
    </>
  )
}

export default LiveClass