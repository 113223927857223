import { ArrowBack, Label } from '@mui/icons-material'
import { TimePicker } from '@mui/x-date-pickers'
import { Box, Button, Card, Container, FormControl, IconButton, Input, InputAdornment, InputBase, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs'
import VisuallyHiddenInput from '../../component/common/VisuallyHiddenInput'

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const DashboardOption = ({b}) => {
    const navigate = useNavigate()
    const [buttons, setbuttons] = useState({ 
       promo:[
          {id:1, link:'yourpromo',title:'Your Promo'},
          {id:2, link:'addpromo',title:'Add Promo'}
      ],
      wallet :[
      {id:1,link:'referearn',title:'Refer Earning'},
      {id:2,link:'Coursesearn',title:'Course Earning'},
      {id:2,link:'funds',title:'Fund '}
     ],
       earning:[
          {id:1,link:'refrallenrolled',title:'No of Stdent Enrolled'},
          {id:2,link:'studentrefer',title:'No of Stdent Refer'},
      ],
       
        connection:[
          {id:1,link:'studentid',title:'Student Id'},
          {id:2,link:'monthlydata',title:'Monthly Data'},
          {id:3,link:'yearlydata',title:'Yearly Data'},
          {id:4,link:'lifetimedata',title:'Lifetime Data ' },
       ]})
       const [newSchedule, setNewSchedule] = useState({
        id:1,
        topic:'',
        startMeeting:dayjs(''),
        endMeeting:dayjs('')
        // startTime:'',
        // EndTime:'',
        // Document :'',
        // Assignment:'',
        // Share:''
       });

       const handleChange = (event) => {
        // event.preventDefault()
        let name = event.target.name
       let value=  event.target.value

       setNewSchedule(values=>({...values,
           [name] : value
       }))
      //  console.log(newSchedule)
       };
    const params = useParams()
    const itemId=params?.id;
//    if(itemId === buttons.){

//    }

  return (<>
  <Container maxWidth='sm'>
    <Box m={2} boxShadow={10}>
        <IconButton
        onClick={()=>navigate('/dashboard')}
        >
            <ArrowBack/>
        </IconButton>
    <Card sx={{p:6, bgcolor:'transparent'}}>
        {/* <Typography 
        component='div'
        variant='h2'>
            {itemId}
        </Typography> */}
{itemId in buttons ?
<Stack justifyContent='center'  rowGap={6}>
  {buttons[`${itemId}`].map((child)=><>
  <Stack justifyContent='center'>

  <Box display='flex' justifyContent='center'  >
     <Link to={`/${child.link}`}>
    <Button   key={child.id} sx={{borderRadius:5,boxShadow:5,width:'220px' }}>
        {child.title}
    </Button>
     </Link>
  </Box>
  </Stack>
  </>
        )}
    
    </Stack>
    :<Box>
      {'liveClassShedule' == itemId ?
       <FormControl   fullWidth>
       <InputLabel id="demo-simple-select-label">Select Topic </InputLabel>
       <Select
        
         labelId="demo-simple-select-label"
         name='topic'
         id="demo-simple-select"
         value={newSchedule.topic}
         label="Topic"
         placeholder='Select Topic'
         onChange={handleChange}
         sx={{my:1}}
         >
         <MenuItem value={'Mathematics'}>Mahematics</MenuItem>
         <MenuItem value={'physics'}>Physics</MenuItem>
         <MenuItem value={'chemistry'}>Chemistry</MenuItem>
       </Select>
       {/* <Select
         placeholder='start Meeting'
         name='startMeeting'
         value={newSchedule.startMeeting}
         label="Topic"
         onChange={handleChange}
         sx={{my:1}}
         >
         <MenuItem value={10}>Ten</MenuItem>
         <MenuItem value={20}>Twenty</MenuItem>
         <MenuItem value={30}>Thirty</MenuItem>
       </Select> */}
       
       {/* <DemoContainer  sx={{bgcolor:'lightgrey'}} components={['TimePicker', 'TimePicker']}> */}
        <Stack 
        //  direction='row'
        //  bgcolor={'lightblue'}
       
        justifyContent='space-between'>

       <TimePicker
        
       placeholder='start Meeting'
       name='startMeeting'
       value={newSchedule.startMeeting}
       label="Topic"
       onChange={(e)=>setNewSchedule(values=>({...values,
        ['startMeeting'] : e }) )}
        sx={{mt:1 }}
        />
       
       <TimePicker
         placeholder='start Meeting'
         name='endMeeting'
         value={newSchedule.endMeeting}
         label="End Meeting"
         onChange={(e)=>setNewSchedule(values=>({...values,
            ['endMeeting'] : e }) )}
            sx={{my:1}}
            />
            </Stack>
       {/* </DemoContainer> */}
       {/* </LocalizationProvider> */}
        <Stack my={1} rowGap={1} direction={{md:'column',xs:'column'}} justifyContent='space-evenly'>

        
         <Button
             sx={{boxShadow:5, py:1.5}}
            component="label"
             role={undefined}
              
             tabIndex={-1}
            // endIcon={<CloudUploadIcon />}
          >
            <Stack 
            sx={{width:'100%'}}
            // bgcolor='red'
            justifyContent='space-between'
            direction='row'
            >
            <p>
           Attacheme(pdf) File
            </p>
            <CloudUploadIcon/>
            </Stack>
          <VisuallyHiddenInput type="file" />
            
        </Button>
         <Button
         sx={{boxShadow:5}}
            component="label"
             role={undefined}
             tabIndex={-1}
            // endIcon={<CloudUploadIcon />}
          >
            <Stack 
            py={0.75}
            sx={{width:'100%'}}
            // bgcolor='red'
            justifyContent='space-between'
            direction='row'
            >
            <p>
           Attacheme(pdf) File
            </p>
              <CloudUploadIcon/>
            </Stack>
          <VisuallyHiddenInput type="file" />
            
        </Button>
        </Stack>
        <Button   onClick={()=>navigate('/liveclasses')} sx={{py:1, boxShadow:5, mt:3, mx:'auto'}} type='button'>
            Submit
        </Button>
         
       
     </FormControl>
        
      :''}
    </Box>
    }
</Card>
        </Box>
  </Container>
</>
  )
}

export default DashboardOption