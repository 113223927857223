import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import StackCard from '../component/common/StackCard'
import { HomeMaxTwoTone } from '@mui/icons-material'
import ImgCard from '../component/common/ImgCard'
import { SwiperSlide } from 'swiper/react'
import CarouselNav from '../component/slider/CarosalNav'
import { hero1,hero2, hero4,logo } from '../assets'
import { Helmet } from 'react-helmet'
import Loading from '../component/common/Loading'
const EarnMony = () => {
    const [isLoding, setisLoding] = useState(true)
    useEffect(() => {
      setTimeout(() => {
        setisLoding(false)
      }, 1000);
    
    },[])
    
  if(isLoding){
    return<Loading/>
  }
    const listProduct=[
        {id:'1', title:'React Developer Course', img :hero1,},
        {id:'2', title:'React Developer Course', img :hero2},
        {id:'3', title:'React Developer Course', img :hero2},
        {id:'4', title:'React Developer Course', img :hero4},    
        {id:'5', title:'React Developer Course', img :hero4},    
        {id:'6', title:'React Developer Course', img :hero4},    
        {id:'7', title:'React Developer Course', img :hero4},    
        {id:'8', title:'React Developer Course', img :hero4},    
        {id:'9', title:'React Developer Course', img :hero4},    
      ]
      const course =[
        {id:1, title:'Provide Training', desc:'As an online learning platform, Sudo24 offers training programs for individuals who are interested in becoming online teachers.'},
        {id:1, title:'Sudo24 Learning T-Shirts', desc:'As part of our teacher support and engagement initiatives, Sudo24 provides branded learning t-shirts to our online teachers'},
        {id:1, title:'Teacher Tool Kit', desc:'As part of our teacher support and engagement initiatives, Sudo24 provides branded learning t-shirts to our online teachers'},
        {id:1, title:'Students for Teaching', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Interactive Multimedia Resources', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Collaboration and Discussion Forums::', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Students for Teaching', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Interactive Multimedia Resources', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Collaboration and Discussion Forums::', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Students for Teaching', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Interactive Multimedia Resources', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
        {id:1, title:'Collaboration and Discussion Forums::', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
      ]
      const courses =[
        {id:1, title:'Provide Training', desc:'As an online learning platform, Sudo24 offers training programs for individuals who are interested in becoming online teachers.'},
        {id:1, title:'Sudo24 Learning T-Shirts', desc:'As part of our teacher support and engagement initiatives, Sudo24 provides branded learning t-shirts to our online teachers'},
        {id:1, title:'Teacher Tool Kit', desc:'As part of our teacher support and engagement initiatives, Sudo24 provides branded learning t-shirts to our online teachers'},
        {id:1, title:'Students for Teaching', desc:'Sudo24 ensures a steady flow of students for our online teachers. We actively recruit students who are seeking online education across various subjects and grade levels.'},
       
      ]
  return (
    <div className='  '>
        <Helmet>
            <title>Earn Money</title>
        </Helmet>
     <Grid sx={{mx:2, mt:8}}   container>

<Grid textAlign='' alignContent='center' lg={6}>
      <div className='ml-2 align-middle'>

       <h1 className='text-7xl my-5 font-extrabold'>
          
The Global Education Platform

            </h1>
            <Typography variant='h4'sx={{mb:3}} component='div'>
            Start your business Get your brand going with tools to build, launch, and grow.
            </Typography>
            <Button variant='contained' color='success' sx={{borderRadius:'5%', mb:2}} size='large'>
                Sign Up
            </Button>
            <Typography variant='p' sx={{color:'slategrey'}} component='div'>
            Try Sudo24 free for 3 days, no credit card required. By entering your email, you agree to receive marketing emails from Sudo24
            </Typography>
            {/* {cousrse.map((key, id)=>
             <StackCard key={id} row={key}/>
          )} */}
            </div>

  </Grid>
  <Grid display='flex'  lg={6}>
  <CarouselNav  delay='0.5s' mouse={false}  speed={1000} slide={2}>
              {listProduct.map((product, id) => 
              <Grid lg={4} md={4} sm={6} xs={12} key={id}>
                  <SwiperSlide key={id} className='NavSlide' style={{width:'100vw'}}>
                  <ImgCard  product={product}   />
                  </SwiperSlide>
              </Grid>
              )}
              </CarouselNav>
  </Grid>

</Grid>
<Grid rowGap={3} sx={{mx:1, my:5}} container>
    {course.map((key, id)=>
    <Grid item lg={4}>
        <Box key={id} sx={{
            boxShadow:3,
            borderRadius:5,
            m:2,
            px:3,
            height:'100%',
            pt:1,
            pb:3 ,
            ":hover":{
                boxShadow:15,
                
            }}}>
            <Stack>
                <HomeMaxTwoTone sx={{fontSize:50}}/>
            </Stack>
            <Stack>
                <Typography variant='h5' component='div'>
                    {key.title}
                </Typography>
            </Stack>
            <Stack>
                <Typography variant='p' sx={{color:'slategrey'}} component='div'>
               {key.desc}
                </Typography>
            </Stack>
        </Box>
    </Grid>
            )}

</Grid>
<Grid  sx={{bgcolor:'#000', color:'#fff'}} container>
{courses.map((key, id)=>
    <Grid item lg={6}>
        <Box key={id} sx={{
           
            borderRadius:5,
            mx:3,
            my:2,
            px:3,
            pt:1,
            pb:3 ,
            ":hover":{
                boxShadow:"0px 1px 16px 1px white",
             
                
            }}}>
            <Stack>
                <HomeMaxTwoTone sx={{fontSize:50}}/>
            </Stack>
            <Stack>
                <Typography variant='h5' component='div'>
                    {key.title}
                </Typography>
            </Stack>
            <Stack>
                <Typography variant='p'  component='div'>
               {key.desc}
                </Typography>
            </Stack>
        </Box>
    </Grid>
            )}

</Grid>
<Grid sx={{pt:3, mb:100}} container>
    <Grid lg={12} xs={12} sx={{width:'30%'}} textAlign='center'>
        <Typography textAlign='center' variant='h2' sx={{ fontWeight:800, width:'50vw', mx:'auto', mb:4}} component='div'>
        Start teaching with
         Sudo24 Today
        </Typography>
    </Grid>
    <Grid lg={12} xs={12} textAlign='center'  item>
        <Typography variant='p' sx={{width:'70%', fontSize:20, mx:'auto', mb:2}} component='div'>
        Experience the power of Sudo24 for free and unlock a wide range of tools and services
to initiate, manage, and expand your teaching business. business here
        </Typography>
    </Grid>
    <Grid lg={12} xs={12} textAlign='center' item >
        <Button color='success' sx={{px:5, mx:'auto'}} variant='contained'>
            Sign Up
        </Button>
    </Grid>

</Grid>
   </div>
  )
}

export default EarnMony