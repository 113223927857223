import { Avatar, Box, Button, Card, CardMedia, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { hero1 as img } from '../assets'
import { featureJob, listProduct1 } from '../data/listproduct'
import Highlights from '../section/Home/highlights'
import Courses from '../section/Courses'
import LearningExperience from '../section/LearningExperience'
import FrenchiseCard from '../component/joinwithus/FrenchiseCard'
import { AccountBox, CheckCircleOutline, Forward, Forward30Outlined, PunchClock, Search, Star } from '@mui/icons-material'
import { ourNumbers } from '../data/hiring'
import Story from '../component/hiring/Story'
import Navbar from '../component/common/Navbar'
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'

const Hiring = () => {
  const [isLoding, setisLoding] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setisLoding(false)
    }, 1000);
  
  },[])
  
if(isLoding){
  return<Loading/>
}
  return (
    <>
        <Helmet>
          <title>
            Hiring
          </title>
        </Helmet>
    <Container sx={{mt:5, mb:5}} maxWidth='xl'>
    <Box sx={{ 
      boxShadow:'10',
       display :'block',
       flexDirection:'column'
    }}>
       
       <Story/>
        <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
            title='Unlock Your Caeers'
             component={
               <Grid container>
                <Grid px={1}  sm={6} item >
                    <Box boxShadow={5} sx={{":hover":{
                        boxShadow:9
                    }}}>
                        <Card sx={{backgroundImage:img,position:'relative' }}>
                            <CardMedia
                            sx={{
                                position:'absolute',
                                objectFit:'contain',
                                opacity:0.2,
                                height:'-webkit-fill-available'
                            }}
                            component='img'
                            src={img}
                            />
                            <Box 
                            sx={{
                                px:2.5,
                                zIndex:1000,
                                py:2,
                            }}
                            >

                            <Typography fontWeight={750} component='div' variant='h6'>
                                Learn
                            </Typography>
                            <Typography lineHeight={1} fontWeight={650} mt={1} component='div' variant='caption'>
                                Expand
                            </Typography>
                            <Typography lineHeight={1} component='div' variant='caption'>
                                Learn More
                            </Typography>
                            </Box>
                        </Card>
                    </Box>
                    <Box boxShadow={5} my={2} sx={{":hover":{
                        boxShadow:9
                    }}}>
                        <Card sx={{px:2.5,py:2, bgcolor:'transparent'}}>
                            <Typography fontWeight={750} component='div' variant='h6'>
                                Price
                            </Typography>
                            <Typography lineHeight={1} fontWeight={650} mt={1} component='div' variant='caption'>
                                Refine
                            </Typography>
                            <Typography lineHeight={1} component='div' variant='caption'>
                                Learn More
                            </Typography>
                        </Card>
                    </Box>
                    <Box boxShadow={5} my={2} sx={{":hover":{
                        boxShadow:9
                    }}}>
                        <Card sx={{px:2.5,py:2, bgcolor:'transparent'}}>
                            <Typography fontWeight={750} component='div' variant='h6'>
                                Mentorship
                            </Typography>
                            <Typography lineHeight={1} fontWeight={650} mt={1} component='div' variant='caption'>
                                Guidence
                            </Typography>
                            <Typography lineHeight={1} component='div' variant='caption'>
                                Learn More
                            </Typography>
                        </Card>
                    </Box>
                  
                </Grid>
                <Grid px={1} sm={6} item >
                    <Box boxShadow={5} mb={2} sx={{":hover":{
                        boxShadow:9
                    }}}>
                        <Card sx={{px:2.5,pt:2,pb:8 ,bgcolor:'transparent'}}>
                            <Typography fontWeight={750} component='div' variant='h6'>
                                Jobs
                            </Typography>
                            <Typography lineHeight={1} fontWeight={650} mt={1} component='div' variant='caption'>
                                Explore
                            </Typography>
                            <Typography lineHeight={1} component='div' variant='caption'>
                                Diverse Career
                            </Typography>
                        </Card>
                    </Box>
                    <Box boxShadow={5} my={2} sx={{":hover":{
                        boxShadow:9
                    }}}>
                        <Card sx={{px:2.5,pt:2,pb:8 ,bgcolor:'transparent'}}>
                            <Typography fontWeight={750} component='div' variant='h6'>
                                Jobs
                            </Typography>
                            <Typography lineHeight={1} fontWeight={650} mt={1} component='div' variant='caption'>
                                Explore
                            </Typography>
                            <Typography lineHeight={1} component='div' variant='caption'>
                                Diverse Career
                            </Typography>
                        </Card>
                    </Box>

                    
                </Grid>
               </Grid>
             }
            
           >

            <Typography mt={2} component='div'variant='body2'>
            Explore opportunities from across the globe to learn, showcase skills, gain CV points & get hired by your dream company.
               <br/>
                 Education
            </Typography>
              {/* <Stack my={1} direction='row' gap={2}>
                <Box>
                    <CheckCircleOutline/>
                    50 + courses
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Live Projects
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Assignments
                </Box>

              </Stack> */}
              <Button size='large' sx={{boxShadow:5}}>
                 Explore Courses <Forward30Outlined/>
              </Button>
            {/* {colaborates.map((colaborate , key)=>
           
            <Box key={key}>

           <Typography lineHeight={1.2}  component='div' fontWeight={700} variant='h5'>
                <Star sx={{mr:1.5, color:'yellow'}}/>
                {colaborate.title}
            </Typography>
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={500} variant='subtitle1'>
              
            {colaborate.desc}
            </Typography>
            </Box>
             )} */}
            
           </FrenchiseCard>
        <FrenchiseCard 
           direction={{lg:'row-reverse',xs:'column-reverse'}}
           title='Learn & Level Up Your Skills'
           img={img}
            
           >

            <Typography mt={2} component='div'variant='body2'>
            Select from a wide range of courses to upskill and advance your career!
            </Typography>
              <Stack my={1} direction='row' gap={2}>
                <Box>
                    <CheckCircleOutline/>
                    50 + courses
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Live Projects
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Assignments
                </Box>

              </Stack>
              <Button size='large' sx={{boxShadow:5}}>
                 Explore Courses <Forward30Outlined/>
              </Button>
            {/* {colaborates.map((colaborate , key)=>
           
            <Box key={key}>

           <Typography lineHeight={1.2}  component='div' fontWeight={700} variant='h5'>
                <Star sx={{mr:1.5, color:'yellow'}}/>
                {colaborate.title}
            </Typography>
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={500} variant='subtitle1'>
              
            {colaborate.desc}
            </Typography>
            </Box>
             )} */}
            
           </FrenchiseCard>
        <FrenchiseCard 
           direction={{lg:'row-reverse',xs:'column-reverse'}}
           title='Learn & Level Up Your Skills'
           img={img}
            
           >

            <Typography mt={2} component='div'variant='body2'>
            Select from a wide range of courses to upskill and advance your career!
            </Typography>
              <Stack my={1} direction='row' gap={2}>
                <Box>
                    <CheckCircleOutline/>
                    50 + courses
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Live Projects
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Assignments
                </Box>

              </Stack>
              <Button size='large' sx={{boxShadow:5}}>
                 Explore Courses <Forward30Outlined/>
              </Button>
            {/* {colaborates.map((colaborate , key)=>
           
            <Box key={key}>

           <Typography lineHeight={1.2}  component='div' fontWeight={700} variant='h5'>
                <Star sx={{mr:1.5, color:'yellow'}}/>
                {colaborate.title}
            </Typography>
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={500} variant='subtitle1'>
              
            {colaborate.desc}
            </Typography>
            </Box>
             )} */}
            
           </FrenchiseCard>
        <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title='Learn & Level Up Your Skills'
           img={img}

           >

            <Typography mt={2} component='div'variant='body2'>
            Select from a wide range of courses to upskill and advance your career!
            </Typography>
              <Stack my={1} direction='row' gap={2}>
                <Box>
                    <CheckCircleOutline/>
                    50 + courses
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Live Projects
                </Box>
                <Box>
                    <CheckCircleOutline/>
                    Assignments
                </Box>

              </Stack>
              <Button size='large' sx={{boxShadow:5}}>
                 Explore Courses <Forward30Outlined/>
              </Button>
            {/* {colaborates.map((colaborate , key)=>
           
            <Box key={key}>

           <Typography lineHeight={1.2}  component='div' fontWeight={700} variant='h5'>
                <Star sx={{mr:1.5, color:'yellow'}}/>
                {colaborate.title}
            </Typography>
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={500} variant='subtitle1'>
              
            {colaborate.desc}
            </Typography>
            </Box>
             )} */}
            
           </FrenchiseCard>
        <Highlights heading={'Our Highlights'} listProduct={listProduct1}/>
          
         
          <Courses button1='Apply Now' button2='Job Details' listProduct={featureJob} heading={'Featured Opportunity'}/>
        <FrenchiseCard 
           direction={{lg:'row-reverse',xs:'column-reverse'}}
           title={<span>Browse Job / Internship <br/>That’s Right For You!</span>}
           img={img}

           >

            <Typography my={2} component='div'variant='body2'>
            Find a role that fits your career aspirations.

Find Jobs
Full-Time

            </Typography>
              <Box p={1} >

              <Button startIcon={<Avatar><Search/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5}}>
                Find Job 
              </Button>
              <Button  startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5, m:1}}>
                Full Time
              </Button>
              <Button startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5}}>
                Full Time
              </Button>
              <Button startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5, }}>
                Work From Home
              </Button>
              <Button startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5, mx:1}}>
                On Feild
              </Button>
              
              </Box>
            {/* {colaborates.map((colaborate , key)=>
           
            <Box key={key}>

           <Typography lineHeight={1.2}  component='div' fontWeight={700} variant='h5'>
                <Star sx={{mr:1.5, color:'yellow'}}/>
                {colaborate.title}
            </Typography>
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={500} variant='subtitle1'>
              
            {colaborate.desc}
            </Typography>
            </Box>
             )} */}
            
           </FrenchiseCard>
        <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title={<span>Browse Job / Internship <br/>That’s Right For You!</span>}
           img={img}

           >

            <Typography my={2} component='div'variant='body2'>
            Find a role that fits your career aspirations.

Find Jobs
Full-Time

            </Typography>
              <Box p={1} >

              <Button startIcon={<Avatar><Search/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5}}>
                Find Job 
              </Button>
              <Button  startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5, m:1}}>
                Full Time
              </Button>
              <Button startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5}}>
                Full Time
              </Button>
              <Button startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5, }}>
                Work From Home
              </Button>
              <Button startIcon={<Avatar><PunchClock/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5, mx:1}}>
                On Feild
              </Button>
              
              </Box>
         
           </FrenchiseCard>
          <Box mx={{md:2,xs:0, }} px={{lg:3,md:2,xs:1}} boxShadow={5} py={2}>

         
            <Stack direction='row' justifyContent='space-between' >
                <Box>
                  <Typography component='div' variant='h5' fontWeight={750}>
                  Personalised Mentor Support
                  </Typography>
                  <Typography component='div' variant='body2' fontWeight={750}>
                  Select a mentor from a pool of 2000+ industry experts & get 1-on-1 mentorship!
                  </Typography>
                </Box>
                <Button endIcon={<Forward/>}>
                    View More
                </Button>
            </Stack>
            <Stack direction='row' justifyContent='space-between' >
            <Button variant='contained' endIcon={<Forward/>}>
                   Find Mentor
                </Button>
                <Button sx={{boxShadow:5}} endIcon={<Forward/>}>
                   Become Mentor
                </Button>
            </Stack>
            <Stack direction='row' border='1px solid black' borderRadius={3}justifyContent='space-between' boxShadow={5} mx={{md:2,xs:0, }} px={{md:2,xs:1}} mt={3} >

              <CardMedia
              component='img'
              src={img}
              sx={{
                height:200,
                objectFit:'cover'

              }}/>
                 
            </Stack>
            </Box>
           
        <Highlights heading={'Pick The Right Opportunity'} subtitle2='Explore opportunities that best suits your skills and interests!' listProduct={listProduct1}/>
         

        <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title={<span>Host Your Own <br/> Opportunity</span>}
           img={img}

           >

            <Typography my={2} component='div'variant='body2'>
            Engage with a diverse talent pool or hire the best minds from 8Mn+ users.
            </Typography>
              <Stack gap={1} direction='row'>

              <Button endIcon={<Avatar><Forward30Outlined/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5}}>
                 Go Pro  
              </Button>
              <Button endIcon={<Avatar><Forward30Outlined/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5}}>
                 Know More  
              </Button>
              </Stack>
           </FrenchiseCard>
        <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title={<span>Don't<br/>
           Just Be Good.<br/>
           Be Great. Be Pro.</span>}
           img={img}
           >
            <Typography my={2} component='div'variant='body2'>
            A playground of opportunities awaits.
Are you ready to go pro?
            </Typography>
              <Button endIcon={ <Avatar><Forward30Outlined/></Avatar>} size='large' sx={{boxShadow:5, borderRadius:5}}>
                 Go Pro 
              </Button>
           </FrenchiseCard>
           <Box mt={3} display='block'>
          <Grid rowGap={2} mx={1} display=''  container>
            <Grid item md={4} xs={12}>
                <Box boxShadow={5} sx={{display:'block' , height:'100%', textAlign:'center', mx:1}}>
                    <Typography component='div' variant='h6'>
                        Download 
                    </Typography>
                    <Typography component='div' fontWeight={750} variant='h5'>
                        Sudo24 App
                    </Typography>
                </Box>
            </Grid>
            <Grid display='' item md={8}>
                <Box boxShadow={5} display='block'  sx={{mx:2,p:2}}>

                    <Typography component='div' fontWeight={750} variant='h6'>
                        Refer & Win
                    </Typography>
                    <Typography my={{my:2, my:1.5, xs:0}} component='div' variant='body2'>
                    MacBook, iPhone, Apple Watch, AirPods, Cash Rewards and more!
                    </Typography>
                    <Button endIcon={<Forward30Outlined/>} sx={{boxShadow:8, mt:2}}>
                        Refer Now 
                    </Button>

                </Box>
            </Grid>
          </Grid>
          </Box>
        <Grid container>
          <Stack sx={{
            boxShadow:2,
           py:3,
            m:3,
            ":hover":{
              boxShadow:8
            }
          }} textAlign='center' width='100%' justifyContent='center'>
              <Stack  justifyContent='center'>
              <Typography component='div' fontWeight={800} variant='h3'>
              Our Numbers
              </Typography>
              </Stack>
              
             <Grid sx={{mt:5, }} textAlign='center' container>
            { ourNumbers.map((subscribe, key)=>
               <Grid item md={2} xs={12} sm={4} lg={2} key={key}>
                <Typography component='div' variant='h4'>
                  {subscribe.value}
                </Typography>
                <Typography component='div' variant='subtitle1'>
                  {subscribe.desc}
                </Typography>
               </Grid>

            )

  }           </Grid>
             
          </Stack>

        </Grid>
        </Box>
        </Container>

        </>  )
}

export default Hiring