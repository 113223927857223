const { Navigate } = require("react-router-dom")

  const Authmiddleware =(props)=>{
    if(!localStorage.getItem('authuser')){
        return(
            <Navigate to={{pathname:'/login'}}/>
        )
    }
    return (
        <>
        {props.children}
        </>
    )
  }
  const TeacherMiddleWare =(props)=>{
    if('teacher' !== localStorage.getItem('_role')){
        return(
            <Navigate to={{pathname:'/'}}/>
        )
    }
    return (
        <>
        {props.children}
        </>
    )
  }

  
  export  {Authmiddleware, TeacherMiddleWare};