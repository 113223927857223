import { CalendarMonthOutlined, ChatRounded, CheckCircle, DiamondOutlined, Face2Rounded, Face3Rounded, ListAltTwoTone, LocationOffOutlined, LocationSearchingOutlined, MessageOutlined, Person2Outlined, StackedBarChartOutlined, WindowOutlined } from "@mui/icons-material"

const whyTeach =[
    {icon:<Face3Rounded/>, title:'Find Purpose', desc:'Contribute to someone life by sharing knowledge and experience pure joy of giving!'},
    {icon:<LocationSearchingOutlined/>, title:'Anytime Anywhere', desc:'Connect with your students from wherever you are and whenever you want.'},
    {icon:<DiamondOutlined/>, title:'Earn Well', desc:'A surprisingly high earning potential with predictability and control in your hands!'},
    {icon:<Person2Outlined/>, title:'Awsome Community',desc:'Be a part of a large teaching community, make friends and learn from each other!'},
    {icon:<MessageOutlined/>, title:'Great Connections, Beautiful Stories', desc:'Connect with students from all over the country and create heart-warming learning stories!'},
]

const Expectation =[
    {icon:<LocationOffOutlined/>, title:'Dedicated and Passionate', desc:'Someone who belongs to Teaching and does everything to make it fun and productive for Learners and their Parents'},
    {icon:<CalendarMonthOutlined/>, title:'Consistently Available', desc:'Minimum 3 Hours of Daily Availability on Sudo24 between 4-10 PM IST, consistently'},
    {icon:<CalendarMonthOutlined/>, title:'Creative Content', desc:'Someone who deeps dive in content and bring interesting ways for different types of Learners to learn'},
    {icon:<ListAltTwoTone/>, title:'Loves to follow Processes', desc:'Sudo24 expects our Teachers to follow certain processes to ensure excellent User Experience, we love to work as a team with our Teachers '},
    {icon:<ChatRounded/>, title:'Willing to learn and grow', desc:'Sudo24 organises excellent Trainings and certifications for our Teachers frequently, we love to see our Teachers growing'},
]

const processes =[
    {id:1, icon:<CheckCircle/>, title:'Apply as Teacher',desc:'Apply by filling in a simple form.'},
    {id:1, icon:<StackedBarChartOutlined/>, title:'Initial Screening',desc:'Our experts will screen the best profiles.'},
    {id:1, icon:<WindowOutlined/>, title:'Teaching Demo',desc:'Pick a topic of your choice and give a teaching demo to our experts.'},
    {id:1, icon:<Person2Outlined/>, title:'Teacher On-Boarding &    Training',desc:'Once selected, documentation and profile creation will be done, followed by training and induction webinar.'},
    {id:1, icon:<Face2Rounded/>, title:'First Online Session',desc:'Once you attend the webinar, you will be listed as a teacher and will get your first online session in no time.'},
]

const faq =[
    {id:1,title:'How long does it take to get listed on Sudo24?', desc:'The process of getting listed on Sudo24 is extremely simple and quick. You just need to clear an online assessment and/or provide a teaching demo to our experts. On an average the entire process can be completed within a weeks time.'},
    {id:2,title:'What subjects are offered at Sudo24?', desc:'A student can take up personalized online tuitions in Mathematics, Physics, Chemistry, Biology, English, Hindi, Sanskrit, French, German, Social Studies and Computer Science at Sudo24. Apart from these core curricular subjects, they can also enroll for various competitive exam preparatory courses like - IIT JEE (Foundation and Main), PSA, IMO, NTSE, etc.'},
    {id:3,title:'How much does an average teacher earn on Sudo24?', desc:'On an average, teachers earn more than INR 15000 per month. Assuming a teacher devotes a minimum of 2 hours per day, he/she can easily earn between INR 18000-20000 per month.'},
    {id:4,title:'What if I am not able to teach for the initially committed duration?', desc:'At Sudo24, you teach at your convenient time and/or at a time that is mutually convenient to you and your students. With the essential tools and features, you can adjust your availability to teach on Sudo24 anytime and be visible to a pool of students.'},
    {id:5,title:'What are the basic requisites to conduct online tutoring sessions at Sudo24?', desc:'As far as hardware is concerned, a laptop/desktop with good processing power, minimum of 4 GB RAM, broadband Internet connectivity (atleast 4 mbps) is required along with a digital pen tablet or stylus. Power backup definitely is an add-on in case your area has electricity issues. Rest, our Academic team takes care of all the training you need to kickstart your sessions on Sudo24.'},
    {id:6,title:'Will I be given any training to conduct these online sessions?', desc:'Yes, trainings are integral part of the on-boarding process for every new teacher at Sudo24. Our Teacher Care team is always available for helping teachers with anything and everything they need.'},
    {id:7,title:'Will I receive technical support if I face any issues during my sessions?', desc:'Definitely. We at Sudo24 are extremely sensitive about the wholesome experience of learning and teaching. Hence, our Teacher Care and Tech teams are always available for immediate resolutions to any issues you face.'},
    {id:8,title:'What is the frequency of payouts for Teachers?', desc:'Payouts are done on monthly basis. All payouts are released before 5th of every month to our Teachers.'},
    {id:9,title:'Can I set my own price as a Teacher?', desc:'Definitely. You will have a complete flexibility to set your own teaching price as per the given standards and change the same periodically at Sudo24.'},
    {id:10,title:'Do I need to Pay anything to enroll as a Teacher?', desc:'yes. There is minium amount of registration fee to be paid to Sudo24 for enrolling and getting listed as a Teacher.'},
    {id:11,title:'Do I need to Sign any contract or bonds?', desc:'As we said, Sudo24 is a highly flexible platform. You teach at your convenient time, and hence you do not have to sign any contract or bond with us.'},
    {id:12,title:'What are my responsibilities at Sudo24 as a Teacher?', desc:'We expect all our teachers to act as an end-to-end learning solution providers for the students. In simple words, the teacher understands the learning requirement of the student, creates the learning plan for the student, prepares the relevant content required for the sessions, shares tests and assignments, evaluates them to understand student progress and provides regular feedback on the same to students and parents.'},
    {id:13,title:'I already have the digital Pen and Tablet. Can I use the Same?', desc:'Yes, our Teacher Care team will help you do a sanity check, if your tablet syncs well with our platform, you can use the same.'},
    {id:14,title:'How can I create Content for handling my Sessions?', desc:'We will provide you with an LMS (learning management system) where you can create, curate, share and evaluate content based on your students requirements, understanding and progression pace.'},
]
export {processes, Expectation, whyTeach, faq}