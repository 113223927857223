import { Box, Button, Card, CardMedia, Divider, Grid, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import { SwiperSlide } from 'swiper/react'
import CssCarousal from '../component/slider/CssCarousal'
import { hero1 as img, hero2 as logo } from '../assets'
import { Check, Close, Tune } from '@mui/icons-material'
import { ReactTyped } from 'react-typed'




import CustomTabPanel from '../component/Tabs/CustomTab'

const PaymentPlan = () => {
    const [alignment, setAlignment] = useState('Primary');
 const stackStyle={
    width: '150px',
    textAlign:'center',
    backgroundColor:'red',
    display:'flex',
    justifyContent:'center',
    px:'auto'
 }
//  . Personalized Training.
// 2. Lifetime Validity.
// 3. Recording Equipment
// 4. Free Of Cost Marketing.
// 5. Customized Application with name 
// 5. Student Gath
// *10000/- Primary*
// 1. Personalized Training.
// 2. Free Of Cost Marketing.
// 3. T Shirt with Logo.


// *15000/- Excellence*
// 1. Personalized Training.
// 2. Lifetime Validity.
// 3. Free Of Cost Marketing.
// 4. Student Gathering To Buy Your Class.
// 5. Copyright Protection For Your Content.
// 6. 2 year sheets for Notes Writing.
// 7. T Shirt with Logo.

// *22000/- Premium*
// 1. Personalized Training.
// 2. Lifetime Validity.
// 3. Recording Equipment
// 4. Free Of Cost Marketing.
// 5. Customized Application with name 
// 5. Student Gath
  const options =[
    {id:1 , title:'Personalized Training',  Primary:0,super:'true', premium :'true' },
    {id:1 , title:'Life Time Validity',super:'true', premium :'true' },
    {id:1 , title:'Recording Equipment',   premium :'true' },
    {id:1 , title:'Application',  Primary:'true',super:'true', premium :'true' },
    {id:1 , title:'Website',  Primary:'true',super:'true', premium :'true' },
    {id:1 , title:'Copyright Protected',  premium :'true' },
    {id:1 , title:'2 year sheets for Notes Writing.',  Primary:'true',super:'true', premium :'true' },
    {id:1 , title:'2000/- Stipend',  premium :'true' },
  ]
 const iconStyle={
    mx:'auto',
 }

 const activeToggle={
    backgroundColor:'lightgray'
 }
 const handleChange = (event, newAlignment) => {
   setAlignment(newAlignment);
 };
 const headerStyle ={
    display:'flex',
    height :'50px',
  
 }
  return (
    <div className=''> 
        <Grid sx={{mt:3, mx:{md:3,xs:0}}} container>
            <Box mx='auto' px={{md:2,xs:0}} borderRadius={5} boxShadow={3}>
               <Stack textAlign='center'>
                <Typography variant='h4'  component='div'>
                    <ReactTyped
                    strings={[' Subscribe Now To Start Earning']}
                    loop
                    typeSpeed={50}
                    backSpeed={50}
                    cursorChar='|'
                    showCursor
                    />
                </Typography>
                <Typography variant='h5' sx={{mb:1}} component='div'>
                </Typography>
                </Stack></Box>
                </Grid>
           
     
      
                 
            <Box p={2} mx={2} my={2} borderRadius={5} boxShadow={3}>
                <Grid container >
                    
                <Grid xs={12} md={6}>
                       <Card>
                     
             <CustomTabPanel value={alignment} index={'Primary'}>
              <CardMedia
              component='img'
              src={img}
              alt='image'
              />
             </CustomTabPanel>
             <CustomTabPanel value={alignment} index={'Super'}>
              <CardMedia
              component='img'
              src={logo}
              alt='image'
              />
          
             </CustomTabPanel>
           <CustomTabPanel value={alignment} index={'Premium'}>
              <CardMedia
              component='img'
              src={img}
              alt='image'
              />
           
            </CustomTabPanel>
     
           
      
                       </Card>
                      </Grid>
            <Grid item xs={12} lg={6}>
            <Card  sx={{bgcolor:'transparent', mx:{lg:5,md:3,xs:0}, my:2, textAlign:'center'}} variant='elevation' elevation={0}>
           <Grid   rowGap={2} justifyContent='flex-end' alignItems='baseline'  container>

            <Grid item >
           
           <Stack sx={headerStyle}>
          &nbsp;
          </Stack>
          {options.map((option, key)=>
          <Stack  key={key} fontWeight={600} sx={{textAlign:'left', mb:1}}>
          {option.title}
          </Stack>
              )}
          </Grid>
            <Grid item lg={2} sx={{maxWidth:50}} >
           <Stack sx={headerStyle}>
            <Typography bgcolor='lightgreen' component='div' fontWeight={800} color={alignment == 'Primary' ? 'yellow':''} variant='subtitle1'>

              Primary
            </Typography>
           
          </Stack>
          <Box sx={alignment == 'Primary' ? activeToggle:''}>
          {options.map((option, key)=>



           <Stack sx={{mb:1}} key={key}>
            {
                option.Primary ?   <Check sx={iconStyle}/>: 
                <Close sx={iconStyle}/>
            }
          </Stack>
          )}
          </Box>
         
          </Grid>
            <Grid lg={2} sx={{maxWidth:50}} >
           <Stack sx={headerStyle}>
           <Typography bgcolor='lightgreen' component='div' fontWeight={800} color={alignment == 'Super' ? 'yellow':''} variant='subtitle1'>

              Super
           </Typography>
          </Stack>
          <Box sx={alignment == 'Super' ? activeToggle:''}>
          {options.map((option, key)=>

           <Stack sx={{mb:1}} key={key}>
               {
                   option.super ?   <Check sx={iconStyle}/>: 
                   <Close sx={iconStyle}/>
                }
          
          </Stack>
          )}
          </Box>
          
          </Grid>
            <Grid lg={2} sx={{maxWidth:50}} >
           <Stack sx={headerStyle}>
           <Typography bgcolor='lightgreen' component='div' fontWeight={800} color={alignment == 'Premium' ? 'yellow':''} variant='subtitle1'>

              Premium
           </Typography>
          </Stack>
          <Box sx={alignment == 'Premium' ? activeToggle:''}>
          {options.map((option, key)=>

           <Stack sx={{mb:1}}  key={key}>
          {
              option.premium ?   <Check sx={iconStyle}/>: 
              <Close sx={iconStyle}/>
            }
            
          </Stack>
          )}
          </Box>
           
          </Grid>
          </Grid>
          <Box mt={3} justifyContent='space-between' rowGap={2} display={{md:'flex', xs:'block'}}>

          <Stack sx={{mr:0, mt:0}} gap={3} direction='row'>
          <ToggleButtonGroup
          fullWidth
      color="success"
      value={alignment}
      exclusive
     
      
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton sx={{fontWeight:800}}  value="Primary">
        Primary 
        <br/>
        10,000/-
      </ToggleButton>
      <ToggleButton sx={{fontWeight:800}}  value="Super">
        Super
        <br/>
        15,000/-
        </ToggleButton>
      <ToggleButton sx={{fontWeight:800}} value="Premium">
        Premium
        <br/>
        20,000/-
        </ToggleButton>
    </ToggleButtonGroup>
        
          </Stack>
          <Stack >
            <Button variant='contained' color='success'>
                Continou with {alignment}
            </Button>
          </Stack>
          </Box>
               
            
          

        </Card>
                      </Grid>
                     
                    </Grid>
                    </Box>
                </div>
  )
}

export default PaymentPlan