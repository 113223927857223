import { CalendarMonth, Download, LockClockOutlined, Person, Star, VideoCallOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardMedia, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import CssCarousal from '../slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'

const BlogsCard = ({product, button, button1, button2, courses}) => {
  
  const title =(e)=>{
    let text = e
    
    if(e.length > 11 ){
      text=`${e.substring(0,20)}...`
    }
   return(
    <Typography 
    component='div' 
    variant='h6'
    fontWeight={700}
    >
  
 {text}
    </Typography>
   )
  }

  return (
    <Box
    
    sx={{
      ":hover":{
        boxShadow:20
      },
      
      my:0,
      mx:1,
      height:'100%'
    }}>
    

   
    <Card
  variant="elevation"
   elevation={0}
  sx={{
    
    my:1,
    mx:1,
    height:'100%',
     overflow:'visible',
    display: 'flex',
    bgcolor:'transparent',
    position:'relative',
    flexDirection: { xs: 'column', sm: 'column' },
    alignItems: 'flex-start', 
  }}
>
{product.img ? 
<>
<Grid  container>


<CardMedia
    component="img"
    alt="Contemplative Reptile album cover"
    src={product.img ? product.img : ''}
    sx={{
      position:'relative',
        p:0,
        columnGap:2,
        height:'150px !important',
        width: { xs: '100%', sm: '100%' },
      borderRadius: 2,
      ":hover":{
        transitionTimingFunction:'ease-in-out',
        backgroundColor:'blueviolet'
      }
    }}
  />

</Grid>

  </>
  :''}
    <div className='grid text-left mx-3  w-11/12 '>
      <div>
        <Tooltip title={product.text}>
           {/* {text} */}
       {product.text}
        </Tooltip>
      </div>
       <Box display={'flex'} justifyContent='space-between'>

        <div className=' font-extrabold text-2xl'>{product.title ? title(product.title):''}</div>
        <div>
          {product.price ? <Avatar variant='rounded' sx={{bgcolor:'green', color:'white'}} >
         <p>

         {product.price}
         </p>
          </Avatar>
         :''}
          </div>
       </Box>
       {product.desc ?  
        <div className='text-slate-500 flex gap-3'>
           <><CalendarMonth />
           <span> {product.desc}</span>
           <Box>
              jjj
            </Box>
            </>
        </div>
           :''}
           {product.time ? 
           <Stack direction='row' justifyContent='space-between' >
            <Stack >
              <Typography component='div' variant='subtitle2'>
             2hr ago
              </Typography>
            </Stack>
              {/* <Stack gap={1} direction='row'>
                <Facebook/>
                <Instagram/>
                <YouTube/>
              </Stack> */}
           </Stack>
          :'' }
        {/* {
          courses ? 
        <><Divider sx={{ mt: 3 }} className='mt-5' /><Grid fontSize={15} alignItems='center' sx={{ mx: {md:1.5,xs:0}, my: 1, }} container>
                <Grid item xs={4}>
                  <LockClockOutlined fontSize='25' />
                  3hr 5min
                </Grid>
                <Grid item xs={4}>
                  <VideoCallOutlined fontSize='25' />
                  34 courses
                </Grid>
                <Grid item xs={4}>
                  <Download fontSize='25' />
                  3,45,345
                </Grid>

              </Grid></> :''
        } */}
       { product.text ?<Grid display='flex' alignItems='center' sx={{mx:1.5, mt:1.5}} justifyContent='space-between' container >
         <div>
           <Avatar  >
             <Person/>
           </Avatar>
         </div>
         <div>
         { [1,2,3,4,5].map((key)=>
           <Star className='text-yellow-400'/>
           )
          }
         </div>
        </Grid>:''}
    </div>
  
   
    {button ?
    <Grid container  direction='row' position='absolute' bottom={-20} justifyContent='space-between'>

   
      <Link to={`/blogs/${product.id}`} >
        <Button  sx={{mt:2, boxShadow:2, color:'#000', ":hover":{
          boxShadow:8
        }}} >
        {button1 ? button1 :'Explore'}
        </Button>
          </Link>
        <Button   sx={{mt:2, boxShadow:2, color:'#000', ":hover":{
          boxShadow:8, 
        }}} >
          {button2? button2:'View'}
        </Button>

    </Grid>
:''}
</Card>

  
</Box>
  )
}

export default BlogsCard