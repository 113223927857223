import { Alert, Box, Button, Stack } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useRecordWebcam } from 'react-record-webcam'
const VideoPreview = ({ stream }) => {
    const [time, setTime] = useState(0)
    
    const [Record, setRecord] = useState()
     const {
         activeRecordings,
         createRecording,
         openCamera,
         startRecording,
         stopRecording,
         closeCamera,
         clearAllRecordings,
         download
    
       } = useRecordWebcam()
   

    //    const example = async () => {
    //     try {
    //       const recording = await createRecording();
    //       if (!recording) return;
    //       await openCamera(recording.id);
    //       await startRecording(recording.id);
    //       await new Promise((resolve) => setTimeout(resolve, 3000));
    //       await stopRecording(recording.id);
    //     } catch (error) {
    //         console.error({ error });
    //     }
    // };
    const startCamera = async ()=>{
        const recording = await createRecording();
        try {
            if(! recording )return;
            await openCamera(recording.id)
            
        } catch (error) {
             alert({error})
        }}
    const uploadRecord = async ()=>{
        const recording = await createRecording();
        try {
           
            await download(recording.id)
            console.log(recording)
            
        } catch (error) {
             alert({error})
        }}
    const closeCameraRecord = async ()=>{
        const recording = await createRecording();
        try {
            
            await closeCamera(recording.id)
        } catch (error) {
             alert({error})
        }
    }
     
    const Recording = async ()=>{
        const recording = await createRecording();
       
        try {
            if(!recording ) return ;
            await startRecording(recording.id);
            
            //   await TimerOutlied;
            await new Promise((resolve) => setTimeout(resolve, 61000));
            await stopRecording(recording.id);
           
            await closeCamera(recording.id)
             
        } catch (error) {
          alert(error)
        }
    }
    
  return (
    <div>
  
    
        <Stack rowGap={2} direction={{lg:'row', xs:'column'}}>
            <Button  variant='contained' onClick={startCamera}>Open Camera</Button>
            <Button variant='contained'  onClick={Recording}>
             Start Recording
            </Button>
            <Button variant='contained' onClick={()=>clearAllRecordings()}>
            Delete Recording
            </Button>
            <Button variant='contained' onClick={uploadRecord}>
            Upload video
            </Button>
        </Stack>
        <Box  display='flex' height={450}  >

      {activeRecordings.map(recording => (
          <Box>
        <p>{recording.status}</p>
        <div key={recording.id} className='relative p-1 flex lg:w-96 h-96'>
        { recording.status != 'CLOSED' ?  <video style={{objectFit:'cover'}} ref={recording.webcamRef} autoPlays  /> :''}
        <div className='w-[100%] h-[100%] bg-slate-500'>
       <video className='h-[100%]' style={{objectFit:'cover'}} ref={recording.previewRef}  hidden={!recording.previewRef}  controls />
       </div>
        </div>
        </Box>
      ))}
      </Box>
    
    </div>
  );
}
export default VideoPreview