import React from 'react';
import Chart from 'react-apexcharts';

const TimelineChart = ({ options, data }) => {



  return (
    <>
      {/* <Chart options={options} series={data} type="line" height={350} /> */}
      <Chart
    options={options}
    series={data}
    type="line"
   height={200}
  />
  </>
   
  );
};

export default TimelineChart;
