import { MoreVertSharp, Settings } from '@mui/icons-material'
import { Avatar, Box, Card, CardHeader, CardMedia, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import  {hero2  }from '../../assets'
const AlertCard = () => {
  return (
    <Card sx={{height:'90vh', overflowY:'scroll',overflowX:'auto', position:'relative', scrollbarWidth:'none'}}>
   {/* <CardHeader
   sx={{position:'fixed', bgcolor:'white'}}
   title='Notification'/> */}
     <CardHeader
                                       sx={{pb:1, }}
                                  action={<IconButton aria-label="settings">
                                      <Settings/>
                                  </IconButton>}
                                  title="Notifications"
                                  
                                  />
  {[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,].map((key)=>
   <Stack key={key} px={2} my={1.5} gap={{md:2,small:1, xs:0}} direction='row'>
     <Avatar />
     <Box maxWidth={250}>
     
        <Typography lineHeight={1} component='div'
        variant='body1'>
            StudyIq Ias is live :India's Dispute At WTO Explained | india vs WwTO |
            Critical Analysis | UPSC GS3
            </Typography>
            <Typography component='div' variant='caption'>
                3 weeks ago
                </Typography> 
        

     </Box>
     <CardMedia
     component='img'
     src={hero2}
     sx={{
        width:50,
        height:'fit-content',
        objectFit:'contain'
    }}
     
     />
     <MoreVertSharp/>
   </Stack>)}
    
    </Card>
  )
}

export default AlertCard