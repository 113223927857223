import React from "react";
// import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, Container, TextField } from "@mui/material";
import "../App.css";
import { TypeAnimation } from "react-type-animation";
// import {  } from "";

const SubscriptionCard = () => {
	// Custom Css
	const responsiveNewsletter = {
		fontSize: {
			xs: "15px",
			sm: "15px",
			md: "15px",
			lg: "15px",
		},
	};
	const responsiveLetter = {
		width: {
			xs: "100%",
			sm: "100%",
			md: "350px",
			lg: "350px",
		},
		padding: {
			xs: "12px",
			sm: "12px",
		},
	};

	const responsiveInputButton = {
		flexDirection: {
			xs: "column",
			sm: "column",
			md: "row",
			lg: "row",
		},
	};

	const responsiveText = {
		fontSize: {
			xs: "0.6rem", // Extra-small screens
			sm: "1.2rem", // Small screens
			md: "1.6rem", // Medium screens
			lg: "1.8rem", // Large screens
			xl: "1.8rem", // Extra-large screens
		},
		fontWeight: {
			xs: "800",
			sm: "800",
			md: "700",
			lg: "900",
			xl: "900",
		},
	};
	const cardResponsive = {
		width: {
			xs: "90%",
			sm: "90%",
			md: "90%",
			lg: "65%",
			xl: "65%",
		},
		padding: {
			xs: "10px 10px",
			sm: "10px 10px",
			md: "10px 50px",
			lg: "10px 50px",
			xl: "10px 50px",
		},
	};
	const responsiveInnerText = {
		fontSize: {
			xs: "0.7rem",
			sm: "0.9rem",
			md: "1rem",
			lg: "1.2rem",
		},
	};
	return (
		<>
			{/* <Container> */}
			<Card
				sx={{
					margin: "10px auto",
					padding: "10px 50px",
					position: "relative",
					backgroundColor: "transparent",
					boxShadow:
						"rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 5px 8px 0px, rgba(0, 0, 0, 0.12) 0px 1px 14px 0px;",
					...cardResponsive,
				}}
			>
				<CardContent sx={{ padding: "0px" }}>
					<Box textAlign="center">
						<Typography
							variant="overline"
							fontWeight={600}
							align="center"
							sx={{ ...responsiveText }}
						>
							<TypeAnimation
								sequence={[
									// Same substring at the start will only be typed out once, initially
									"Subscribe to Our Newsletter",
									10,
									"",
									10,
								]}
								wrapper="span"
								speed={40}
								deletionSpeed={200}
								repeat={Infinity}
								style={{
									...responsiveText,
									display: "inline-block", // Ensures it respects the Typography alignment
								}}
							/>
						</Typography>
					</Box>
					<Typography
						color={"grey"}
						variant="subtitle1"
						align="center"
						sx={{ ...responsiveInnerText }}
					>
						You Have Any Question Ask Frankly
					</Typography>
					<img
						className="text-3xl font-extrabold animate-bounce animate-img"
						src="https://sudo24.netlify.app/static/media/question-mark.c8323ba71ddd89521e51.png"
						width={100}
						style={{ position: "absolute", top: "30%", left: "2%" }}
						alt="ask-img"
					/>
					<img
						className="text-3xl font-extrabold animate-bounce animate-img"
						src="https://sudo24.netlify.app/static/media/question-mark1.b36077c4fdf5050c19c9.png"
						width={100}
						style={{ position: "absolute", top: "30%", right: "5%" }}
						alt="ask-img"
					/>

					<Box
						sx={{
							width: "100%",
							// maxWidth:'100%',
							textAlign: "center",
							marginTop: "4rem",
							display: "flex",
							gap: "20px",
							...responsiveInputButton,
						}}
					>
						<TextField
							fullWidth
							label="Enter Your Email Address"
							id="fullWidth"
							sx={{ ...responsiveNewsletter }}
						/>
						<Button
							fullWidth
							variant="contained"
							sx={{
								width: "350px",
								backgroundColor: "green",
								...responsiveNewsletter,
								...responsiveLetter,
							}}
						>
							Subscribe
						</Button>
					</Box>
				</CardContent>
			</Card>
			{/* </Container> */}
		</>
	);
};

export default SubscriptionCard;
