// import { Box } from "@mui/material";
// import { useCallback } from "react";
// import useRazorpay from "react-razorpay";
// import Axios from 'axios'
// export default function Payment() {
//   const paymentHandler = async (e) => {
//     const API_URL = 'http://localhost:8000/'
//     e.preventDefault();
//     const orderUrl = `${API_URL}order`;
//     const response = await Axios.get(orderUrl);
//     const { data } = response;
//     const options = {
//       key: 'rzp_test_8GPB80GDAet98b',
//       name: "Your App Name",
//       description: "Some Description",
//       order_id: data.id,
//       handler: async (response) => {
//         try {
//          const paymentId = response.razorpay_payment_id;
//          const url = `${API_URL}capture/${paymentId}`;
//          const captureResponse = await Axios.post(url, {})
//          console.log(captureResponse.data);
//         } catch (err) {
//           console.log(err);
//         }
//       },
//       theme: {
//         color: "#686CFD",
//       },
//     };
//     const rzp1 = new window.Razorpay(options);
//     rzp1.open();
//     };

 
//   return (
//     <div className="App">
//     <Box m={5} mt={10}>
//     <button className="App-link" onClick={paymentHandler}>
//                     Pay ₹500
//                 </button>
//     </Box>
//     </div>
//   );
// }
import React from 'react'

const payment = () => {
  return (
    <div>payment</div>
  )
}

export default payment