import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Container, Input, Stack, Typography } from '@mui/material'
import React from 'react'
import img from '../../../assets/sudo24.png'
import { Link } from 'react-router-dom'
const Fund = () => {
    const fund =[
        {id:1, title:'Current Month', earning:'0.00'},
       
        {id:3, title:'Repeat Student', earning:'0.00'},
      
    ]
  return (
    <>
    <Container maxWidth='sm'>
            <Box mt={{md:3,xs:0}}>
       <Typography
       component='div'
       variant='subtitle1'>
        <Link to='/dashboard'>
        <ArrowBack/>
        </Link>
        Funds
       </Typography>
       <Box boxShadow={3} textAlign='center'  my={3}>
        
            <Typography
            component='div'
            variant='h4'
            fontWeight={750}>
                Total Funds
            </Typography>
            <Typography
            component='div'
            variant='h4'
            fontWeight={750}>
                $ 00.00
                </Typography>
      
        </Box>
        <Box>
        <Box  mx={1} my={0.5}>
                {fund.map((card )=>
                
             <Stack 
            
             key={card.id}
             borderRadius={2}
             my={1}
             textAlign='center'
             bgcolor='lightgrey'
             fontWeight={800} direction='row' justifyContent='space-between' 
             px={1}>
                <Typography
                component='div'
                variant='h6'>
                   {card.title}
                </Typography>
                <Typography
                textAlign='center'
                component='div'
                color='lightcoral'
                variant='body1'>
                  {card.earning}
                </Typography>
             </Stack>
             )}
             <Stack my={2}>
                <Typography
                component='div'
                variant='caption'>
                    Please Enter Amount
                </Typography>
                <Input/>
             </Stack>
             <Typography 
             component='div'
             textAlign='center'
             variant='h6'>
                Withdraw Modes
             </Typography>
               <Stack mt={1} rowGap={1}>
                <Card>
                    <Stack p={1}
                    textAlign='center'
                    direction='row' 
                    justifyContent='space-between'>
                        <Box width='50px'>

                      <CardMedia
                      component='img'
                      width={10}
                      sx={{objectFit:'cover'}}
                      src={img}/>
                           </Box>
                       <Typography
                       component='div'
                       variant='h6'>
                        UPI
                       </Typography>
                       <Typography
                       component='div'
                       variant='body1'>
                         Add
                       </Typography>
                      
                    </Stack>

                </Card>
                <Card>
                    <Stack p={1} direction='row'
                    justifyContent='space-between'>
                        <Typography
                        component='div'
                        variant='body1'>
                            Select Your Bank
                        </Typography>
                        <Typography
                        component='div'
                        variant='body1'>
                            Add
                            </Typography>
                    </Stack>
                </Card>
             <Button  sx={{boxShadow:5, px:3,mx:'auto', mt:4, mb:2}}
             >
                Withdraw
             </Button>
             <Stack mt={{md:3,xs:0}} direction='row' justifyContent='space-evenly'>
                <Button
                sx={{boxShadow:5}}
                >
                    Fund Setting
                </Button>
                <Button
                sx={{boxShadow:5}}
                >
                    Help
                </Button>
             </Stack>
                    </Stack>
         </Box>
        </Box>
        </Box>
        </Container> 
 
    </>
  )
}

export default Fund