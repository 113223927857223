import { Box, Card, CardContent, CardMedia, Fade, FormControl, Typography } from '@mui/material'
import React, { useState } from 'react'
import {ReactCardFlip, YOUR_BACK_COMPONENT, YOUR_FRONT_CCOMPONENT} from 'react-card-flip'
 import hero1 from '../../assets/hero1.png'
const LifeCard = ({product}) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleCardFlip = () => {
      setIsFlipped(!isFlipped);
    };
  return (
    <div className='mx-5 relative' onMouseEnter={handleCardFlip}  onMouseLeave={handleCardFlip}>
    <Card 
    sx={{
        display:'flex'
}}
    className={isFlipped?' hidden -z-20':''}
    >
  
    <CardMedia 
        className={isFlipped?' hidden -z-20 ':''}
        component='img'
        sx={{
            
            
            objectFit:'cover'
        }}
        src= {hero1}
        />
    
   
      <CardContent 
        className={isFlipped?' -z-10 ':''}
        sx={{
            position:'absolute',
            bottom:10,
            textAlign:'left'
            
        }}
      >

        <Typography variant="h4" sx={{fontWeight:800}} component="div">
        front Name
        </Typography>
        <Typography variant="h5" component="div">
        front Side Side
        </Typography>
      </CardContent>
      <CardContent 
        className={isFlipped?'  ':'-z-10'}
        sx={{
            position:'absolute'
        }}
      >
        <Typography variant="h5" component="div">
        Back Side Side
        </Typography>
      </CardContent>
      
    </Card>
  </div>
 
    
  )
}

export default LifeCard