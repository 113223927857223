import { Card, CardMedia, Container, Grid, Stack } from "@mui/material";
import React from "react";
import "../component/slider/css/Nav.css";
import { hero1, hero2, hero4 } from "../assets";

import ProductCard from "../component/ProductCard";
import { SwiperSlide } from "swiper/react";
import { ImportExportTwoTone } from "@mui/icons-material";
import CarouselNav from "../component/slider/CarosalNav";
import CssCarousal from "../component/slider/CssCarousal";
import Heading from "../component/common/Heading/Heading";
import GridCard from "../component/common/cards/GridCard";

const Specialist = ({ heading, listProduct, children }) => {
	// const listProduct=[
	//     {id:'1', title:'React Developer Course',know:'Know More ...'  ,img :hero1},
	//     {id:'2', title:'React Developer Course', know:'Know More ...'  , img :hero2},
	//     {id:'3', title:'React Developer Course', know:'Know More ...'  ,  img :hero4},
	//     {id:'1', title:'React Developer Course', know:'Know More ...'  ,  img :hero1},
	//     {id:'2', title:'React Developer Course', know:'Know More ...'  , img :hero2},
	//     {id:'3', title:'React Developer Course',know:'Know More ...'  ,   img :hero4},

	//   ]

	return (
		<>
			<Heading heading={heading}></Heading>

			<GridCard>
				<CssCarousal
					breakpoints={{
						400: {
							width: 400,
							slidesPerView: "1",
						},
					}}
					slide={{ xs: 1, md: 3 }}
				>
					{children
						? children
						: listProduct.map((product, id) => (
								<SwiperSlide>
									<Grid lg={12} md={12} sm={12} xs={12} key={id}>
										<ProductCard product={product} />
									</Grid>
								</SwiperSlide>
						  ))}
				</CssCarousal>
			</GridCard>
		</>
	);
};

export default Specialist;
