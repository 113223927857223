import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { Box, Button, ButtonGroup, Input, Popover, Stack, TextField, Typography } from '@mui/material';
import { ArrowDownward, Expand, ExpandMore, ExpandTwoTone } from '@mui/icons-material';
import CustomButton from './CustomButton';
import { setActive } from '@material-tailwind/react/components/Tabs/TabsContext';

export default function CustomizedInputBase({button, buttonStyle}) {
    
 const [active, setactive] = React.useState(false)
 const borderStyle={border:'6px solid #F7D7FF', borderRadius:'10px',mx:'auto'}

  const style={
     border:'4px solid #10632F',
     borderRadius:'10px',
     
  }

  return (
   <Stack sx={{width:'100%',}}>
    <ButtonGroup  sx={active ? borderStyle: style}>
          <CustomButton   button='true'>
               <Box sx={{
                    height:200,
                    maxWidth:150,
                    overflow:'auto',
                    scrollbarColor:'inherit',
                    scrollbarWidth:'none'
               }}>
                    <Stack bgcolor='lightgrey'   px={1}>
                         <InputBase />
                    </Stack>
          <ul >
     <li>
<Typography >The content .</Typography>
     </li>
     <li>
<Typography> the Popover.</Typography>
     </li>
     <li>
<Typography >The content </Typography>
     </li>
     <li>
<Typography >The content </Typography>
     </li>
     <li>
<Typography >The content </Typography>
     </li>
     <li>
<Typography >The content of pop </Typography>
     </li>
     <li>

<Typography >The content </Typography>
     </li>
     <li>

<Typography >The content </Typography>
     </li>
     <li>

<Typography >The content </Typography>
     </li>
 </ul>
 </Box>
          </CustomButton>
         <InputBase
         onClick={()=>setactive(true)}
        sx={{ ml: 1, flex: 1}}
        placeholder="Search Anything"
        inputProps={{ 'aria-label': 'Search Anything' }}
      />
        <Button sx={{...buttonStyle , }} color='secondary' variant='contained' size='smalll' type="button"  aria-label="search">
        <SearchIcon />
       </Button>
    </ButtonGroup>
    </Stack>
  );
}