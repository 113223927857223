import { Button, Card, Container } from '@mui/material'
import React from 'react'
import ContainerCard from '../component/common/ContainerCard'
import logo1 from '../assets/cardsider/question-mark.png'
import logo2 from '../assets/cardsider/question-mark1.png'
import { ReactTyped } from 'react-typed'

const Hire = ({heading, desc, item}) => {
  return (
    <div className='my-6 z-30'>
        <ContainerCard width='md'>

       { heading ?<>
       <div className='relative'>
        <img className='absolute w-28 left-1 bottom-1'  src={logo1} alt='logo'/>
        <img  className='absolute w-28 right-1 top-1' src={logo2} alt='logo'/>

          <h2 className='text-3xl font-extrabold animate-bounce'>
          <ReactTyped
                    strings={[`${heading}`]}
                    loop
                    typeSpeed={150}
                    backSpeed={50}
                    cursorChar='|'
                    showCursor
                    /></h2>
          <p className='pt-4 text-slate-500
          mb-14'>{desc}</p>
          <div className='flex justify-center'>
            {item}
          </div>
          </div>
          </> 
          :
          <>
          <h2 className='text-3xl font-extrabold animate-bounce'>Hire From us</h2>
          <p className='pt-4
          mb-14'>Become a partner companies and be first in line to the amazing talent that graduates from our cohorts</p>
          <Button variant='contained'  color='secondary'>
            Get in Touch
          </Button>
          </>
        }
      
        </ContainerCard>
    </div>
  )
}

export default Hire