import { Button, Card, Divider, Grid } from '@mui/material'
import React from 'react'
import CarouselNav from '../slider/CarosalNav'
import { SwiperSlide } from 'swiper/react'
import MouseOverPopover from './HoverPopover'
import CustomButton from './CustomButton'
import NavbarItem from './NavbarItem'
import { menuItem } from '../../data/Navbar'

const Navbar = () => {

    const buttonStyle={
        backgroundColor:'#F2FDD4',
         color:'#000',
      
          ":hover":{
          backgroundColor:'rgb(126,156,44)'
      }
       }
      
     
      
         const textColor ={
          color:'#F2FDD4',
           ":hover":{
           color:'rgb(126,156,44)'
       }
        }

      

  return (
      <>
    
      <Grid  display='flex' justifyContent='space-between' sx={{backgroundColor:'transparent',overflow:'hidden', py:0.2, mx:{xs:0, md:1, lg:2}, }} > 
        <span>
      <ul className='flex  justify-between  items-center gap-6'>
        <CarouselNav breakpoints={{100:{
          width:400,
          slidesPerView:'2'
         },
        1000:{
          width:1000,
          slidesPerView:'4'
        },
        1300:{
          width:1330,
          slidesPerView:'5'
        }
      }
        
        } 
        
        speed={0} slide={
         6
        }>
        {menuItem.map((menu ,id)=>
        <li key={id}>
          <SwiperSlide  key={id} className='NavSlide' style={{width:'100vw', paddingBottom:'5px', paddingTop:'5px'}}>
          <CustomButton  button={true} title={menu.title}>
              

                  <NavbarItem items={menu.item}/>
              
          </CustomButton>

          {/* <MouseOverPopover title={menu.title} button='b' color='secondary'/>    */}
          </SwiperSlide>
        </li>
        )}
       
        </CarouselNav>
      </ul>
        </span>
        <Divider/>
    </Grid>
      </>
  )
}

export default Navbar