import { Card, Grid } from '@mui/material'
import React, { Children } from 'react'

const GridCard = ({listcard, children, boxShadow}) => {
  return (
      <Grid mx={1.5} boxShadow={boxShadow? boxShadow :0} display='block'  container>
        <Card elevation={0} sx={{display:'flex', mx:1, bgcolor:'transparent'}}>
        {children}
         </Card>
        </Grid>
  )
}

export default GridCard