import { Avatar, Box, Button, Card, Container, Grid } from '@mui/material'
import React, {useEffect, useState} from 'react'
import Courses from '../section/Courses'
import { listCourses, listProduct } from '../data/listproduct'
import { bloglist } from '../data/blog'
import BlogsCard from '../component/blogs/BlogsCard'
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'
const Blogs = ({heading ,button1, button2}) => {
  const [isLoding, setisLoding] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setisLoding(false)
    }, 1000);
  
  },[])
  
if(isLoding){
  return<Loading/>
}

  return (
    <Container sx={{mt:5}} maxWidth='lg'>
      <Helmet>
        <title>
          Blogs
        </title>
      </Helmet>
      <Box sx={{ 
        boxShadow:'10',
         display :'block',
         py:2

      }}>

       
        <Grid  justifyContent='center'  sx={{mt:4}}>
     
     <h1 className=' text-center font-bold text-5xl pb-2 '>{heading ? heading:'Popular Blogs'}</h1>

     <div className='w-/4'>
         <p className='mx-3 mt-1 text-2xl text-gray-400 text-center'>
             {
           '  Live learning with industry experts. Jobs at technology companies.'}
         </p>
     </div>
 </Grid>
 <Grid rowGap={7} className=' py-7 my-5' container>
  <Grid item lg={12}>
    <Grid rowGap={5} container>

         {bloglist.map((product, id) => 
         <Grid lg={4} md={6} sm={6} xs={12} key={id}>
             <BlogsCard button={true} courses={true}  button1={button1}  button2={button2} product={product} />
         </Grid>
         )}
         </Grid>
         </Grid>
         {/* <Grid item lg={4}>
            <Box>
              <Card>
                <Avatar>

                </Avatar>
                <Grid container>
                  <Button>

                  </Button>
                   </Grid>
              </Card>
            </Box>
         </Grid> */}
     </Grid>
      </Box>
      </Container>
    
  )
}

export default Blogs