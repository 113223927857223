import {    Person,  } from '@mui/icons-material'
import {  Box, Button, Card, CardMedia, Container,  FormControl, Grid, InputLabel, MenuItem, Select, Stack,  Typography } from '@mui/material'
import React, { useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import { hero1 as img } from '../assets'

import VisuallyHiddenInput from '../component/common/VisuallyHiddenInput'
import SliderCard from '../component/common/SltderCard'
import { useNavigate } from 'react-router-dom'

const Identityverify = () => {
const navigate = useNavigate()

  const [file, setFile] = useState();
  function handlefile(e) {
      console.log(e.target.files);
      setFile(URL.createObjectURL(e.target.files[0]));
  }

    const [type, setType] = useState('');
    const handleSubmit =()=>{
      navigate('/educationverification')
    
     }
    const handleChange = (event) => {
      setType(event.target.value);
    };

  return (
    <div>
    <Container sx={{mt:5}} maxWidth='lg'>
      <Box sx={{ 
        boxShadow:'10',
         display :'block'
      }}>
        <Grid  direction={{xs:'column-reverse', lg:'row'}}  container >
          <Grid item xs={12} lg={6}>
          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}}  rowGap={3}>
            <Stack sx={{mb:3}}>
                <Typography fontWeight={600} fontSize={30}  component='h1'>

               Select  Your Identitiy Proof
                </Typography>
            </Stack>

        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Identitiy Proof </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={type}
    label="?"
    onChange={handleChange}
    placeholder='Address Proof'
  >
    <MenuItem value='aadharCard'>Aadhar Card</MenuItem>
    <MenuItem value='voter'>Voter Id Card</MenuItem>
    <MenuItem value='passport'>Passport</MenuItem>
    <MenuItem value='others'>Others</MenuItem>
  </Select>
  <Grid sx={{mb:1}} container>
  <Grid display='flex' item xs={12} lg={12}>
             <Button
               component="label"
               role={undefined}
                
            
               tabIndex={-1}
               sx={
                 {
                 mb:'5%',
                 mx:'5%',
                   width:'90%',
                   height:'95%'
                 }}
               
               >
            <Card
            sx={{bgcolor:'gray',
            width:'100%',
            height:'100%'
            }}
            
            >
          {
            file ? <CardMedia
            component='img'
            alt='sbbbbr'
            src={file}
            sx={{
            overflow:'hidden',
              height:'200px',
              objectFit:'cover'
            }}
            />
:        <>
<Typography component='div' sx={{m:1}} variant='subtitle1'>
Front
</Typography>
<Box
display='flex'
justifyContent='center'
>
            <Person
            
             sx={{
             alignSelf:'center',
             mx:'auto',
              width:'150px',
              height:'150px'
            }} 
            />
</Box>
</>
          } 
             
              <VisuallyHiddenInput onChange={handlefile} type='file'/>
            </Card>
             </Button>
            </Grid>
 
 
  </Grid>
  <Stack  rowGap={0} direction='row' display='flex' justifyContent='space-evenly' >
    <Button variant='contained' size='large' color='info'>
    back
    </Button>
  {file  ?<Button  variant='contained'
 
  size='large' onClick={handleSubmit} sx={{
          
          
        }} color='secondary'>
         Next
        </Button>
        :
        <Button disabled variant='contained' size='large' sx={{
        
     
      }} color='secondary'>
        Next
      </Button>
       }   

         </Stack>
</FormControl>
     {/* <Box display='flex' justifyContent='center'>

       <Avatar  sx={{height:100, width:100}}>
        {type? <AccountBoxTwoTone />:<QuestionMark fontSize='100px'/> }
       </Avatar>
          
     </Box> */}

        </Box>

      </Card>
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <SliderCard/>
                    </Grid>
                  </Grid>
      
      </Box>
    </Container>
  </div>
  )
}



export default Identityverify