import { CalendarMonth, Person, Star } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardMedia, Divider, Grid, Stack } from '@mui/material'
import React from 'react'
import socialism from '../../assets/Socialism.mp4'
const CourseCard = ({product}) => {

    function PlayVideo(e){
        let playPromise = e.target.play()
        if (playPromise !== undefined) {
            playPromise.then(_ => {
              // Automatic playback started!
              // Show playing UI.
              // We can now safely pause video...
            //   e.target.pause();
            console.log('paly')
            
        })
        .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            // e.target.pause();
            // console.log('pagggggggy')
    
            });
          }
    }
  return (
    <Card
    variant="elevation"
    elevation={0}
    sx={{
      p:1,
      mx:1,
      gap:2,
       overflow:'visible',
      display: 'flex',
  
      flexDirection: { xs: 'column', sm: 'column' },
      alignItems: 'flex-start', 
      ":hover":{
        boxShadow:20,
        zoom:1.05
      }
     
    }}
  >

<Grid  container>
<CardMedia
          
          sx={{
              // width:150,
              height:300,
              objectFit:'cover'
          }}
          // autoPlay
          controls
         onMouseOver={event=> PlayVideo(event)}
         onMouseOut={e=>e.target.pause()}
          component='video'
          src={socialism}
          >
          </CardMedia>
</Grid>
<div className='grid text-left mx-3  w-11/12 '>
      {/* <div>
        {product.text}
      </div> */}
      <Box display={'flex'}  justifyContent={'space-between'}>
        
        <div className=' font-extrabold text-2xl'>{product.title ? product.title:''}</div>
        <div className=' font-extrabold text-2xl'> <Avatar  >
             <Person/>
           </Avatar></div>
      </Box>
        <div className='text-slate-500 flex gap-3'>
            <CalendarMonth/>
            <span>{product.desc ? product.desc : 'To Be Announced'}</span>
        </div>
        <Divider sx={{mt:3}} className='mt-5'/>
       { product.text ?<Grid display='flex' alignItems='center' sx={{mx:1.5, mt:1.5}} justifyContent='flex-end' container >
        
         <div>
         { [1,2,3,4,5].map((key)=>
           <Star className='text-yellow-400 mb-1'/>
           )
          }
          <span className='font-bold mt-1'>4.0</span>
         </div>
        </Grid>:''}
    </div>
    <div className='relative w-full'>
   
    <div className='flex w-full bottom-[-30px] absolute justify-between'>
        <Button sx={{mt:2,px:{sm:1,xs:0}, boxShadow:5}} >
        {'Explore Me'}
        </Button>
        <Button disableElevation  sx={{mt:2, boxShadow:5}}  >
          {'Add To Cart'}
        </Button>

    </div>

    </div>
  </Card>
  )
}

export default CourseCard