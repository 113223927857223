import { Call, Close, ExpandMore, Facebook, HomeMaxOutlined, Instagram, LinkedIn, MenuOutlined, Notifications, PersonSharp, Phone, ShoppingCartOutlined, Twitter, YouTube } from '@mui/icons-material'
import { Avatar, Box, Button, Card, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {logo} from '../assets'
import CustomizedInputBase from './common/CustomInput'
import MouseOverPopover from './common/HoverPopover'
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame
} from "framer-motion";
import { wrap } from "@motionone/utils";
import CarouselNav from './slider/CarosalNav'
import { SwiperSlide } from 'swiper/react'
import Navbar from './common/Navbar'
import CustomButton from './common/CustomButton'
import RevolutionCard from './joinwithus/RevolutionCard'
import Contact from './common/Contact'
import AlertCard from './common/AlertCard'


const Header = ({event}) => {
  const [menu, setMenu] = useState(false)
  const navigate = useNavigate()
  const buttonStyle={
    backgroundColor:'#10632F',
     color:'white',
     
      ":hover":{
      backgroundColor:'#10632F'
  }
   }
 const contact =[
  {icon:<Avatar sx={{mx:'auto'}}><PersonSharp/></Avatar> ,title:'Talk to our sales Team ', desc:'We will help you find Right product & pricing For your bussiness', button:'Contact Sales',link:'#'},
  {icon:<Avatar sx={{mx:'auto'}}><PersonSharp/></Avatar> ,title:'Product and Account Support', desc:'Our help center is fresh And Always Open For bussiness. if you can`t find answer, we are here to lend a hand', button:'Contact Customer Support', link:'/contactus'},
 ]
 const menuItem=[
  {id:1, title :'Academics' , button:1},
  {id:2, title :'Crash Course' , button:1},
  {id:3, title :'Motivational' , button:1},
  {id:4, title :'Comptitional' , button:1},
  {id:5, title :'Skill Development' , button:1},
 ]
   
 const handlelogin=()=>{
   localStorage.setItem('page', 'login')
   navigate('/usertype')

 }
 const handleregister=()=>{
   localStorage.setItem('page', 'register')
   navigate('/usertype')

 }
  return (
    <>
  
    <Grid container sx={{color:'#ef4139', backgroundImage: `linear-gradient(270deg, #F2FDD4,#F7D7FF )`}}  alignItems='center' className='  pb-1'>
      <Grid display='block' item lg={12} xs={12}>

     
    <Card 
    elevation={
      0
    } 
    sx={{display:'flex', justifyContent:'space-between', backgroundColor:'transparent',mt:1}}  
    >
        <Stack alignItems='center' gap={{md:2,xs:0}} direction='row'>
           <Button size='small'   sx={{ boxShadow:5, px:1,py:0.2, minWidth:{sm:'40px', xs:'30px'}}} onClick={event}  >
          <MenuOutlined sx={{fontSize:{sm:'40px', xs:'30px'}, color:'#000'}} />
           </Button>

      <div className='flex mr-3 align-middle w-14 text-center  '>
       <img src={logo} alt='Logo'className=' '/>
      <h3 className='animate-bounce mt-3 md:flex hidden hover:animate-none  text-3xl font-extrabold '>
      <span style={{color:'#DA5B9c'}}  >
         Sudo 
        </span> 
      <span style={{color:'#10632F'}}>
        24
      </span>
      </h3>
      </div>
      </Stack>
         <Stack display={{lg:'flex', xs:'none'}}>
        <ul className='lg:flex mr-3  hidden gap-5 text-6xl  md:text-3xl font-semibold'>
          <li>
          <Link to='/hiring'>
            <Tooltip title='Hiring '>
        <Box boxShadow={2} sx={{":hover":{
          boxShadow:8
        }}}>

    <Button  sx={{ color:'#000'}}>
       Hiring
    </Button>
        </Box>
        </Tooltip>
        </Link>
          </li>
          <li className='hidden w-96 lg:flex'>
        <CustomizedInputBase buttonStyle={buttonStyle} />
        </li>
          <li className=' hidden lg:block'>
            <Link to='/bussinesswithus'>
            <Tooltip title='Community'>
        <Box boxShadow={2} sx={{":hover":{
          boxShadow:8
        }}}>

    <Button  sx={{ color:'#000',}}>
       Community
    </Button>
        </Box>
        </Tooltip>
        </Link>
          </li>
          <li className=''>
        <Box boxShadow={2} sx={{":hover":{
          boxShadow:8
        }}}>
        <Button sx={{color:'#000'}}   >
           With Us
        </Button>
        </Box>
          </li>
          <li className='' >
          
            <CustomButton  button={true} title={<Phone  />}>
              <Contact contact={contact}/>
              </CustomButton>
          
             </li>
          <li className=''>
            
              <CustomButton button={true} title={

                <Notifications  />
              }>
              <AlertCard/>
              </CustomButton>
          
          </li>
          <li className=''>
            <Link to=''>
             
            
              <Box boxShadow={2}  >
                <Link to='/cart'>

<Button size='small' sx={{px:0}}>

                <ShoppingCartOutlined />
</Button>
                </Link>
             
               
              </Box>
            
              
        
            </Link>
          </li>
          <li>
        


          <CustomButton button='true' title='Hello Sign in'>

          <Box sx={{my:1}}>
         
          <Button onClick={handlelogin} variant='contained' sx={{px:5, mx:2}} color='success'>
            Sign In
          </Button>
        
          <div className='text-sm'>New Customer <Button onClick={handleregister}variant='text' sx={{p:0}}>Register</Button></div>
          </Box>
          </CustomButton>
          </li>  
        </ul>
      </Stack>
      <Stack  gap={1} direction='row'  alignItems='center' mr={{xs:0.5, sm:1, md:0}} display={{lg:'none',xs:'flex'}}>
        <Link to='/bussinesswithus'>
        <Tooltip title='Community'>

    <Button  sx={{boxShadow:5, color:'#000', p:0}}>
       Community
    </Button>
        </Tooltip>
        </Link>
       
      <CustomButton button={true} title={
        <Notifications sx={{m:0,p:0, fontSize:'30px'}}/>
}>  <AlertCard/></CustomButton>
      <CustomButton button={true} title={
          <Call sx={{m:0,p:0, fontSize:'30px'}} />
}> <Contact contact={contact}/></CustomButton>
          <Box sx={{boxShadow:5}}>
            <Link to='cart'>
          <ShoppingCartOutlined sx={{m:0,p:0, fontSize:'30px'}}/>
            </Link>
          </Box>

      <CustomButton button={true} title={
  <PersonSharp sx={{m:0,p:0, fontSize:'30px'}}/>
}>
  
  <Box sx={{my:1}}>
         
         <Button onClick={handlelogin} variant='contained' sx={{px:5, mx:2}} color='success'>
           Sign In
         </Button>
       
         <div className='text-sm'>New Customer <Button onClick={handleregister}variant='text' sx={{p:0}}>Register</Button></div>
         </Box>
</CustomButton>
        
        
   
</Stack>
      </Card>  
      </Grid>  
      <Grid sx={{display:{lg:'none', }}} mt={1} container>
   <CustomizedInputBase buttonStyle={buttonStyle} />
      </Grid>
   

     <div>

        <Navbar/>
     </div>
      
    </Grid>
   


    
    </>
  )
}

export default Header


