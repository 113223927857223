import {
	Avatar,
	Box,
	Button,
	Card,
	CardMedia,
	Chip,
	Divider,
	Grid,
	InputBase,
	Menu,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Navbar from "../component/common/Navbar";

import Story from "../component/hiring/Story";
import { Helmet } from "react-helmet";
import Loading from "../component/common/Loading";

import TabScroll from "./TabScroll";
import AddPost from "../component/community/AddPost";
import Post from "../component/community/Post";
const BussinessWithUs = () => {
	const samplePost = {
		user: {
			name: "John Doe",
			avatarUrl: "https://example.com/avatar.jpg",
		},
		date: "2022-05-15",
		content:
			"Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure nulla nostrum maiores deleniti tempore alias id nihil ipsam! Rem voluptates incidunt excepturi libero eos quibusdam illo quod pariatur! Ipsam, provident!",
		likes: 10,
		comments: [
			{
				user: "Alice",
				text: "Great post!",
			},
			{
				user: "Bob",
				text: "Nice job!",
			},
			{
				user: "Charlie",
				text: "I agree with Alice!",
			},
		],
		shares: 5,
	};

	const moreSamplePosts = {
		user: {
			name: "Jane Smith",
			avatarUrl: "https://example.com/avatar2.jpg",
		},
		date: "2022-05-16",
		content: "Just another day in paradise!",
		likes: 25,

		shares: 8,
	};

	const [selectedCategory, setSelectedCategory] = useState(0);

	const categories = [
		{ id: 0, name: "All" },
		{ id: 1, name: "Python" },
		{ id: 2, name: "Gaming" },
		{ id: 3, name: "Sports" },
		{ id: 4, name: "News" },
		{ id: 5, name: "Technology" },
		{ id: 6, name: "Movies" },
		{ id: 7, name: "HTML" },
		{ id: 8, name: "CSS" },
		{ id: 9, name: "JAVA" },
		{ id: 10, name: "Python" },
		{ id: 11, name: "C++" },
		{ id: 12, name: "Videos" },
		{ id: 13, name: "Watched" },
		{ id: 14, name: "Sudo24" },
		{ id: 15, name: "Recently" },
	];

	const handleCategoryChange = (categoryId) => {
		setSelectedCategory(categoryId);
	};

	const [isLoding, setisLoding] = useState(true);
	useEffect(() => {
		setTimeout(() => {
			setisLoding(false);
		}, 1000);
	}, []);

	if (isLoding) {
		return <Loading />;
	}

	return (
		<div className="flex overflow-hidden">
			<Helmet>
				<title>Bussiness With US</title>
			</Helmet>
			<Grid
				sx={{
					mt: { md: 4, xs: 8 },
					mb: 50,
					mr: 3,
					position: "relative",
					display: "flex",
				}}
				container
			>
				<Story />

				<TabScroll
					categories={categories}
					selectedCategory={selectedCategory}
					onChange={handleCategoryChange}
				/>

				<AddPost />
				<Grid Container>
					<Grid item lg={12}>
						<Post post={samplePost} />
					</Grid>
					<Grid item>
						<Post post={moreSamplePosts} />
						<Post post={moreSamplePosts} />
						<Post post={moreSamplePosts} />
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default BussinessWithUs;
