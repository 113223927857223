import React from 'react'

import { Avatar, Box, Button, Card, Grid, Stack, Typography } from '@mui/material'
import { CalendarViewMonthOutlined, FacebookRounded, Forward,  Instagram, NotificationsActiveOutlined, Phone, QuestionMarkOutlined , Settings, Share, StarBorderOutlined,  UploadFile,   YouTube } from '@mui/icons-material'
import LoadingPage from '../../common/LoadingPage'
import {TeacherMiddleWare,Authmiddleware } from '../../../routes/route'

const TeacherLayout = ({children}) => {
    const profilebutton=[
        {id:1 , title:'Yor Reels', startIcon:<UploadFile/>},
        {id:1 , title:'Rate to Application', startIcon:<StarBorderOutlined/>},
        {id:1 , title:'Setting' ,startIcon:<Settings/>},
        {id:1 , title:'Support' ,startIcon:<QuestionMarkOutlined/>},
        {id:1 , title:'Refer & Earn' ,startIcon:<Share/>},
        {id:1 , title:'Term & Condition' ,startIcon:<CalendarViewMonthOutlined/>},
      ]
    
  return (<>
     <Grid rowGap={2} sx={{backgroundImage:'linear-graidient( red, yelllow)', m:0,p:0, overflowX:'hidden', display:'block',  mb:{lg:0,md:50,xs:10}}} container>    
   
    
     
   <Card  sx={{bgcolor:'transparent',overflow:'auto', mt:{lg:0,xs:8}}}>
     <Grid   container>
      <Grid position={{md:'sticky', xs:'unset'}} mb={{mb:0,xs:4}}  item xs={12} md={8}>
        
        <Box   
        height={{md:'100vh', xs:'100%'}}
        scrollbarWidth='none'
        sx={{scrollbarWidth:'none'}}
         overflow='auto'>
          <LoadingPage>
           
             {children}
            
          </LoadingPage>
        </Box>
       
      </Grid>

      <Grid xs={12}  position='' right={0} item md={4}>
        <Box position='relative'>

      
          <Card  sx={{p:2, mx:1, mb:1,overflow:'auto',bgcolor:'transparent' }}>
              <Box textAlign='center'>
                <Stack direction='row' justifyContent='space-between'>
                  <Phone/>

            <Avatar sx={{mx:'auto',height:100, width:100,
           }}/>

            
            <NotificationsActiveOutlined 
             sx={{fontSize:30}}
             />
             </Stack>
             <Typography 
              component='div'
              variant='h6'>
              Farhat Nawaz
             </Typography>
             <Stack 
             direction='row'
             justifyContent='space-between'>
              <Box>
                  <Typography 
                   component='div'
                   variant='subtitle1'>
                      1025
                  </Typography>
                  <Typography 
                   component='div'
                   variant='subtitle1'>
                      Followers
                  </Typography>
              </Box>
              <Box>
                  <Typography
                   component='div'
                   variant='subtitle1'>
                      792
                  </Typography>
                  <Typography
                   component='div'
                   variant='subtitle1'>
                      followings
                  </Typography>
              </Box>
             </Stack>
             <Stack
             direction='row'
             gap={2}
             justifyContent='space-evenly'>
              <FacebookRounded/>
              <Instagram/>
              <YouTube />
             </Stack>
              </Box>
              <Box sx={{mx:1,my:2}}>
                  {profilebutton.map((button)=>
                <Button 
                fullWidth
                sx={{boxShadow:5}}
                >
                  <Stack sx={{width:'100%'}} 
                  display='flex' 
                  alignItems='center'
                  direction='row' justifyContent='space-between'>
                   <Box>
                    {button.startIcon}
                  {button.title}
                   </Box>
                   <Forward/>
                  </Stack>
                </Button>
                )}
              </Box>
          </Card>
          </Box>
      </Grid>
     </Grid>
   </Card>
  </Grid>
    </>
  )
}

export default TeacherLayout