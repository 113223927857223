import React, { useState, useEffect } from "react";
import { Grid, Stack, Typography, CircularProgress, Box } from "@mui/material";
import CountUp from "react-countup";
import useIntersectionObserver from "./useIntersectionObserver";

const AnimatedStats = () => {
	const stats = [
		{ label: "Tutors", value: 400 },
		{ label: "Courses", value: 300 },
		{ label: "Students", value: 10000 },
		{ label: "Video Lectures", value: 20000 },
	];

	return (
		<Grid container justifyContent="center">
			<Stack
				sx={{
					boxShadow: 2,
					py: 3,
					m: 3,
					":hover": {
						boxShadow: 8,
					},
				}}
				textAlign="center"
				width="100%"
				justifyContent="center"
			>
				<Stack justifyContent="center">
					<Typography component="div" variant="h4">
						India's Most Loved Platform
					</Typography>
				</Stack>
				<Stack justifyContent="center">
					<Typography component="div" variant="subtitle2">
						With SUDO24, Begin your journey with success
					</Typography>
				</Stack>
				<Grid sx={{ mt: 5 }} textAlign="center" container>
					{stats.map((stat, key) => (
						<Grid item md={3} xs={6} lg={3} key={key}>
							<ProgressWithCountUp endValue={stat.value} label={stat.label} />
						</Grid>
					))}
				</Grid>
			</Stack>
		</Grid>
	);
};

const ProgressWithCountUp = ({ endValue, label }) => {
	const [progress, setProgress] = useState(0);
	const [startCounting, setStartCounting] = useState(false);
	const [ref, isVisible] = useIntersectionObserver({
		threshold: 0.5,
	});

	useEffect(() => {
		if (isVisible) {
			setStartCounting(true);
			const duration = 5; // Duration in seconds
			const start = Date.now();

			const step = () => {
				const now = Date.now();
				const elapsed = now - start;
				const progressValue = Math.min(
					(elapsed / (duration * 1000)) * 100,
					100
				);
				setProgress(progressValue);

				if (elapsed < duration * 1000) {
					requestAnimationFrame(step);
				}
			};

			requestAnimationFrame(step);
		}
	}, [isVisible, endValue]);

	return (
		<Box ref={ref} textAlign="center">
			<Box
				position="relative"
				display="inline-flex"
				justifyContent="center"
				alignItems="center"
			>
				<CircularProgress
					variant="determinate"
					value={progress}
					size={80}
					thickness={4}
					sx={{ color: "green" }}
				/>
				<Box
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography component="div" variant="h6">
						{startCounting && <CountUp start={0} end={endValue} duration={5} />}
					</Typography>
				</Box>
			</Box>
			<Typography component="div" variant="subtitle1" sx={{ mt: 1 }}>
				{label}
			</Typography>
		</Box>
	);
};

export default AnimatedStats;
