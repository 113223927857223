import { Box, Card, Grid, InputBase, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

const NavbarItem = ({ items }) => {
	const [list, setlist] = useState(items);
	return (
		<Box
			height={250}
			overflow="auto"
			sx={{ px: 1, pb: 2, scrollbarWidth: "none" }}
		>
			<Stack mt={1} position="sticky" bgcolor="lightgray">
				<InputBase sx={{ m: 0.5, px: 0.5, bgcolor: "white" }} />
			</Stack>
			<Grid px={2} maxWidth={150} container>
				{list.map((list, key) => (
					<Grid key={key} width={150} item xs={12}>
						<Typography mx={1} component="div" variant="body2">
							{list.title}
						</Typography>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default NavbarItem;
