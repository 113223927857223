import { Facebook, FactCheck, Google, Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import img from './../assets/hero1.png'

const Register = () => {
  const [Password, setPassword] = useState('')
  const [CPassword, setCPassword] = useState('')
  const [Active, setActive] = useState(false)
  const [Activec, setActivec] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
   
    <Container sx={{mt:5}} maxWidth='lg'>
      <Box sx={{ 
        boxShadow:'10',
       
      }}>
        <Grid container direction={{md:'row',xs:'column-reverse'}} >
          <Grid xs={12} item md={6}>
          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}}>
          <Typography sx={{mb:5}} component='h2'>
            Sign Up
          </Typography>
          <TextField
           sx={{width:'100%',
          mb:2}}
           required
           id="outlined-required"
           label="Name"
           defaultValue="Enter Your Name"
           />
          <TextField
          
           sx={{width:'100%',
          mb:2}}
           required
           id="outlined-required"
           label="Email "
           defaultValue="Enter Your Email"
           />
          <TextField
           sx={{width:'100%',
          mb:2}}
           required
           id="outlined-required"
           label="Mobile No"
           defaultValue="Enter Your Mobile No"
           />
           <Box display='flex' justifyContent={'space-between'} gap={3}>
            <Box sx={{width:'45%'}}>


            <FormControl sx={{ width: '25ch' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

         {Active? <Typography color={'red'} fontSize={10} component={'p'}>
          Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character
          </Typography>:''}
             </Box>
             <Box  sx={{width:'45%'}}>

          <TextField
          
          required
          id="outlined-required"
          label="Confirm Password"
          defaultValue=" Confirm Password "
          onChange={()=>setActive(true)}
       
          />
           {Activec? <Typography color={'red'} fontSize={10} component={'p'}>
          Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character
          </Typography>:''}
        </Box>
          </Box>
        <Stack>
        
        </Stack>
        
        <Button variant='contained' size='large' sx={{
          width:'100%',
          mt:2
        }} color='error'>
          Register
        </Button>
        <Box display='flex' justifyContent='space-between'>
         <Button variant='text'>
           Have an Account
         </Button>
         {/* <Button variant='text'>
           Forgot Username
         </Button> */}

        </Box>
        <Divider>OR</Divider>
        <Stack sx={{mt:1}} rowGap={1.5}>
          <Button startIcon={<Facebook/>} variant='contained'>
           login via Truecaller
          </Button>
          <Button color='error' startIcon={<Google/>} variant='contained'>
           login via Google
          </Button>
          <Button color='info' startIcon={<Facebook/>} variant='contained'>
           login via Facebook
          </Button>
        </Stack>

        </Box>

      </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <CssCarousal>
                      {
[0,1,3].map((key)=>
<SwiperSlide key={key}>
<CardMedia
  component="img"
  alt="Contemplative Reptile album cover"
  src={ img}
  sx={{
    position:'relative',
      p:0,
      my:2,
      height:'100%',
      width: { xs: '100%', sm: '100%' },
    borderRadius: 2,
    ":hover":{
      transitionTimingFunction:'ease-in-out',
      backgroundColor:'blueviolet'
    }
  }}
/>
</SwiperSlide>
)}

                      </CssCarousal>
                    </Grid>
                  </Grid>
      <Card>

      </Card>
      </Box>
    </Container>
 
  )
}

export default Register