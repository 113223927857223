import logo from './logo.svg';
import './App.css';
import Header from './component/Header';
import Home from './Pages/Home';
import  {useState, useEffect} from 'react'
import Footer from './component/Footer';
import { BrowserRouter, Route, Routes, useRoutes } from 'react-router-dom';
import About from './Pages/About';
import { admin, publicRoutes, student, teacher } from './routes';
import Layout from './component/layout/Public/Layout';
import { useSelector } from 'react-redux';
import TeacherLayout from './component/layout/Teacher/TeacherLayout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Button, Card, CardMedia, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from '@mui/material'
import Loading from './component/common/Loading'
import { TeacherMiddleWare } from './routes/route';

function App() {
  const [isLoding, setisLoding] = useState(true)
    useEffect(() => {
      setTimeout(() => {
        setisLoding(false)
      }, 1000);
    
    },[])
    
  if(isLoding){
    return<Loading/>
  }
  return (
    <div className="App ">
    <LocalizationProvider  dateAdapter={AdapterDayjs}>
    <BrowserRouter>
      <Layout>
    <Routes>
      {publicRoutes.map((key, id)=>
      <Route 
      key={id}
      path={key.path}
      element={
        key.children
      }/>
      )}
      {teacher.map((key, id)=>
      <Route 
      key={id}
      path={key.path}
       element={
        <TeacherMiddleWare>

          <TeacherLayout>

          {key.children}
          </TeacherLayout>
        </TeacherMiddleWare>
       
      }/>
      )}
      {student.map((key, id)=>
      <Route 
      key={id}
      path={key.path}
       element={
          key.component
      }/>
      )}
      {admin.map((key, id)=>
      <Route 
      key={id}
      path={key.path}
      element={
          key.component
      }/>
      )}
      
    </Routes>
      </Layout>
   
    </BrowserRouter>
    </LocalizationProvider>
    </div>
  );
}

export default App;
