import React, { Children, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay, EffectCards} from 'swiper/modules';


export default function CssCarousal({children, pagination, navigation, slide, breakpoints,effect, vertical, delay}) {
  return (
    <>
      <Swiper
      direction={vertical ? 'vertical':'horizontal'}
       effect={effect ? effect :' '}
        cssMode={true}
        navigation={navigation ? navigation : false}
        pagination={pagination ? pagination : false}
        breakpoints={
          breakpoints  ? breakpoints:false
        }
         
        
        mousewheel={true}
        autoplay={{
            pauseOnMouseEnter:true,
            
        }}
        loop={true}
         
        slidesPerView={slide ? slide :1}
        grabCursor={true}
        keyboard={true}
      
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay, EffectCards]}
        className="mySwiper"
      >
        {children}
        
      </Swiper>
    </>
  );
}
