import { Box, Button, Card, CardMedia, FormControl, Grid, InputBase, InputLabel, MenuItem, Pagination, Paper, Select, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CarouselNav from '../component/slider/CarosalNav'
import EffectCarousel from '../component/slider/FadeCarousal'
import ProductCard from '../component/ProductCard'
import { hero1, hero2, hero4, Socialism as socialism, logo } from '../assets'
import LifeCard from '../component/common/LifeCard'
import { SwiperSlide } from 'swiper/react'
import StackCard from '../component/common/StackCard'
import ImgCard from '../component/common/ImgCard'
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'
// import socialism from '../'


const Career = ({button1, button2}) => {

     const [job, setJob] = useState('')
    const [type, setType] = useState('');
    const [department, setDepartment] = useState('');

    const handleChange = (event) => {
      setType(event.target.value);
    };
    const handleJob = (event) => {
      setJob(event.target.value);
    };
    const handledepartment = (event) => {
      setDepartment(event.target.value);
    };
    function PlayVideo(e){
        let playPromise = e.target.play()
        if (playPromise !== undefined) {
            playPromise.then(_ => {
              // Automatic playback started!
              // Show playing UI.
              // We can now safely pause video...
            //   e.target.pause();
            console.log('paly')
            
        })
        .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            // e.target.pause();
            // console.log('pagggggggy')
    
            });
          }
    }
    const listProduct=[
        {id:'1', title:'React Developer Course', img :hero1,},
        {id:'2', title:'React Developer Course', img :hero2},
        {id:'3', title:'React Developer Course', img :hero2},
        {id:'4', title:'React Developer Course', img :hero4},    
        {id:'5', title:'React Developer Course', img :hero4},    
        {id:'6', title:'React Developer Course', img :hero4},    
        {id:'7', title:'React Developer Course', img :hero4},    
        {id:'8', title:'React Developer Course', img :hero4},    
        {id:'9', title:'React Developer Course', img :hero4},    
      ]
    const listProduct1=[
        {id:'1', title:'React Developer Course', img :hero1,},
        {id:'2', title:'React Developer Course', img :hero2},
        {id:'3', title:'React Developer Course', img :hero2},
        {id:'1', title:'React Developer Course', img :hero1,},
        {id:'2', title:'React Developer Course', img :hero2},
        {id:'3', title:'React Developer Course', img :logo},
        {id:'1', title:'React Developer Course', img :hero1,},
        {id:'2', title:'React Developer Course', img :hero2},
        {id:'3', title:'React Developer Course', img :logo},
         
      ]

      const cousrse =[
        {id:1, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},
        {id:2, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},
        {id:3, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},
        {id:4, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:logo},
    
      ]
      const [isLoding, setisLoding] = useState(true)
      useEffect(() => {
        setTimeout(() => {
          setisLoding(false)
        }, 1000);
      
      },[])
      
    if(isLoding){
      return<Loading/>
    }

  return (
    <Grid sx={{backgroundImage:'linear-graidient( red, yelllow)', m:0,p:0, overflowX:'hidden', display:'block', mb:{lg:80,md:50,xs:10}}} container>  
    <Helmet>
      <title>Career</title>
      </Helmet>  
   
       <div className='relative my-6 flex'>
   
   
   <div className='left-[5vw] z-10 top-[10vh] w-96 absolute h-[60vh] overflow-hidden  '>
        <h1 className='text-3xl text-white mb-14 font-bold '>
         Teaching in the internet Age Means We Teach <span className='text-green-700'>Tommorrow's </span>
       <span className='text-pink-500'>skill Today</span>
        </h1>
        {/* <Typography color={'whitesmoke'} component={'p'}>
          Elearn is  a global training provider 
          Elearn is  a global training provider 
          Elearn is  a global training provider 
        </Typography> */}
        {/* <Paper
         component="form"
         sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
       >
        
         <InputBase
           sx={{ ml: 1, flex: 1 }}
           placeholder="Enter You Email Id"
           type='email'
           inputProps={{ 'aria-label': 'Enter You Email Id' }}
         />
         <Button color='success'   variant='contained'  type="" sx={{ p: '10px' }} aria-label="Email">
           Send message
         </Button>
         
       </Paper> */}
         
   </div>
     
   <div className=' h-[40vh] overflow-x-hidden  flex'>
    <CarouselNav />
   </div>

   {/* <div className='mx- right-[5vw] top-[15vh] absolute h-[60vh] overflow-hidden  '>
    <EffectCarousel/>
   </div> */}
   
   
   
       </div>
       
       <Grid container justifyContent='center'  sx={{mt:4}}>
          <h1 className='w-full text-center font-bold text-5xl pb-2 '>Join Our Team</h1>
          <div className='w-11/12'>
              <p className=' mt-1 font-thin text-lg text-gray-400 text-center'>
              At SUDO24, we persevere to live up to our reputation as the world's most valuable and loved Ed-tech company. We believe in revolutionizing education, building a generation of active learners and in that mission, we learn, we grow, we have immense fun and live to express what is dear to us - make everyone fall in love with learning!
              </p>
          </div>
          <div className='w-3/4'>
              <p className=' mt-5 text-xl font-bold  text-center'>
              Browse through our departments to find your fit
              </p>
          </div>
      </Grid>
      <Box display={{md:'flex', xs:'block'}} sx={{mt:8,}} justifyContent='space-evenly'>
      <FormControl sx={{width:{md:400, xs:'100%'}, mx:'auto'}}>
  <InputLabel id="demo-simple-select-label">Location </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={type}
    label="?"
    sx={{mb:1}}
    placeholder='I am ?'
    onChange={handleChange}
  >
    <MenuItem value='Teacher'>London</MenuItem>
    <MenuItem value='student'>Noida</MenuItem>
    <MenuItem value='student'>Delhi</MenuItem>
  
  </Select>
  </FormControl>
      <FormControl sx={{width:{md:400, xs:'100%'}, mx:'auto'}}>
  <InputLabel id="demo-simple-select-label">View Job </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={job}
    label="?"
    sx={{mb:1}}
    placeholder='I am ?'
    onChange={handleJob}
  >
    <MenuItem value='Teacher'>Developer</MenuItem>
    <MenuItem value='student'>Tester</MenuItem>
    <MenuItem value='student'>QA Analyst</MenuItem>
  
  </Select>
  </FormControl>
      <FormControl sx={{width:{md:400, xs:'100%'}, mx:'auto'}} >
  <InputLabel id="demo-simple-select-label">departments </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={department}
    label="?"
    sx={{mb:1}}
    placeholder='I am ?'
    onChange={handledepartment}
  >
    <MenuItem value='Teacher'>Software Development</MenuItem>
    <MenuItem value='student'>Sales</MenuItem>
    <MenuItem value='student'>Bussiness Development</MenuItem>
  
  </Select>
  </FormControl>
     
      </Box>
      <Grid rowGap={7} className=' py-7 my-5' container>
              {listProduct.map((product, id) => 
              <Grid lg={3} md={4} sm={6} xs={12} key={id}>
                  <ProductCard button={true}   button1={'View Job'}  button2={'Apply'} product={product} />
              </Grid>
              )}
          </Grid>
          <Box display={'flex'} justifyContent='center' >
            <Pagination count={5}/>
          </Box>
          <Grid container justifyContent='center'  sx={{mt:4}}>
          <h1 className='w-full text-center font-extrabold text-3xl pb-2 '> Our Team</h1>
         
          
            </Grid>
           
          <Grid textAlign='center'  className=' py-7 my-5' container>
           
          <CarouselNav breakpoints={{1300:{
            outerWidth:'100%',
            slidesPerView:'5'
          },
            500:{
           width:600,
           slidesPerView:'2'
          },
          250:{
            width:250,
            slidesPerView:1
          }}} pagination={true}  speed={2000}  slide={3}>
              {listProduct1.map((product, id) => 
              <Grid lg={4} md={4} sm={6} xs={12} key={id}>
                  <SwiperSlide key={id} className='NavSlide' style={{width:'100vw'}}>

                  <LifeCard  product={listProduct1}   />
                  </SwiperSlide>
              </Grid>
              )}
              </CarouselNav>
          </Grid>
            <Card sx={{mx:3, bgcolor:'transparent'}} elevation={10}>
          <Grid sx={{mx:0, px:0, py:2}}   container>

          <Grid textAlign='' alignContent='center' lg={6}>
                <div className='ml-2 align-middle'>

                 <h1 className='text-4xl my-5 font-extrabold'>
                     Premium   <span className='text-pink-600'> Learning </span>Experience
                      </h1>
                      {cousrse.map((key, id)=>
                       <StackCard key={id} row={key}/>
                    )}
                      </div>

            </Grid>
            <Grid xs={12} lg={6}>
              <Box sx={{
                display:'block'
              }}>
                <Card sx={{display:'flex',
              mx:1}}>
                <Grid container>

                <CardMedia
          
          sx={{
            
            objectFit:'fill',
            minHeight:'425px'
          }}
          // autoPlay
          controls
          onMouseOver={event=> PlayVideo(event)}
          onMouseOut={e=>e.target.pause()}
          component='video'
          src={socialism}
          >
          </CardMedia>
            </Grid>

                </Card>
                </Box>
            </Grid>

          </Grid>
            </Card>

            <Grid container justifyContent='center'  sx={{mt:4}}>
          <h1 className='w-full text-center font-extrabold text-3xl pb-2 '> Our Team Awards</h1>
         
          
            </Grid>
           
          <Grid textAlign='center'  className=' py-7 my-5' container>
           
          <CarouselNav breakpoints={{500:{
           width:600,
           slidesPerView:'2'
          },
          250:{
            width:250,
            slidesPerView:1
          }
          }} delay='0.5s' mouse={false}  speed={1000} slide={8}>
              {listProduct.map((product, id) => 
              <Grid lg={4} md={4} sm={6} xs={12} key={id}>
                  <SwiperSlide key={id} className='NavSlide' style={{width:'100vw'}}>

                  <ImgCard  product={product}   />
                  </SwiperSlide>
              </Grid>
              )}
              </CarouselNav>
          </Grid>
            <Grid container justifyContent='center'  sx={{mt:4}}>
          <h1 className='w-full text-center font-extrabold text-3xl pb-2 '> Our Mission</h1>
          <div className='w-11/12'>
              <p className=' mt-1  text-sm text-center'>
              SUDO24 Employee work towards building a community of active learners, who from their early years have a strong understanding of concepts. Being a companion for every learner and anywhere in the world is what drives us to deliver, excel, and grow.

              </p>
          </div>
          
            </Grid>
       </Grid>
  )
}

export default Career