import React from 'react'
import CarouselNav from '../component/slider/CarosalNav'
import { Box, Button, Card, CardMedia, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { Check, CodeOutlined, Download, LiveTv, MobileFriendlyOutlined, PagesOutlined } from '@mui/icons-material'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import { hero1 as img, } from '../assets'
import reel1 from '../assets/reels/reel1.mp4'
import FrenchiseCard from '../component/joinwithus/FrenchiseCard'
import Faq from '../component/common/Faq'
import { Helmet } from 'react-helmet'
// const geItemDetail =(id)=>course.filter((item)=>item.id === id)[0]

const CoursesItem = ({course}) => {
    const params = useParams()
    const itemId=params?.id;
    // const item = !!itemId && geItemDetail(itemId)
       
   const willLearn = [ 'Build 16 web development projects for your portfolio, ready to apply for junior developer jobs.',
'Learn the latest technologies, including Javascript, React, Node and even Web3 development.',
'After the course you will be able to build ANY website you want.',
'Build fully-fledged websites and web apps for your startup or business.',
'Work as a freelance web developer.',
'Master frontend development with React',
'Master backend development with Node',
'Learn professional developer best practices.',]


const includeCourse =[

    {icon:<LiveTv/>,text:'61 hours on-demand video'},
    {icon:<Download/>,text:'194 downloadable resources'},
    {icon:<CodeOutlined/>,text:'7 coding exercises'},
    {icon:<MobileFriendlyOutlined/>,text:'Access on mobile and TV'},
    {icon:<PagesOutlined/>,text:'65 articles'},
    {icon:<LiveTv/>,text:'Certificate of completion'},
]

 const companeis =[
    {id:1, src:img},
    {id:1, src:img},
    {id:1, src:img},
    {id:1, src:img},
    {id:1, src:img},
    {id:1, src:img},
    {id:1, src:img},
    {id:1, src:img},
    {id:1, src:img},
 ]

  const content =[
    {id:1, title:'Frontend Developer'},
    {id:2, title:'Introduction to HTML'},
    {id:3, title:'Intermediate HTML'},
    {id:4, title:'Multi-Page Website'},
    {id:5, title:'Introduction to CSS'},
    {id:6, title:'Css Properteis'},
    {id:7, title:'Advanced Css'},
    {id:8, title:'Flexbox Css'},
    {id:9, title:'Bootstrap Css'},
    {id:10, title:'Node.js'},
    {id:11, title:'EJS'},
    {id:12, title:'Git, Github and Version Control'},
    {id:13, title:'Build Your API'},
    {id:14, title:'Build Your API'},
  ]

  return (
    <Grid sx={{ backgroundImage: 'linear-graidient( red, yelllow)', m: 0, p: 0, overflowX: 'clip', display: 'block', mb: { lg: 80, md: 50, xs: 10 } }} container>
        <Helmet>
            <title>
                Courses
            </title>
        </Helmet>

    <div className='relative my-6 flex'>
    
    
        <div className='left-[5vw] z-10 top-[10vh] w-96 absolute h-[60vh] overflow-hidden  '>
            <h1 className='text-3xl text-white mb-14 font-bold '>
                {itemId}<span className='text-green-700'>Tommorrow's </span>
                <span className='text-pink-500'>skill Today</span>
            </h1>
         
        
        </div>
    
        <div className=' h-[40vh] overflow-x-hidden  flex'>
            <CarouselNav />
        </div>
    
    </div>
    <Container maxWidth='lg'>
      <Grid container>
    <Grid item xs={12} px={1} md={9} lg={8}>

    <Card  sx={{py:2,px:{sm:2, xs:0}, bgcolor:'transparent'}}>
        <Typography component='div'
        variant='h5' textAlign={{sm:'left',xs:'center'}} mb={2} fontWeight={700}>
            What you'll learn
        </Typography>
        <Grid rowGap={1} container>
            {willLearn.map((learn)=>
            <Grid gap={2} item sm={6} xs={12}>
              <Typography component='div'
              variant='caption'>
              <Check />
              {learn}
              </Typography>
            </Grid>
                )}
        </Grid>
    </Card>
    <Box sx={{py:2,px:{sm:2,xs:0},bgcolor:'transparent'}}>
        <Typography component='div'
        variant='h5' mb={2} fontWeight={700}>
           This Course includes
        </Typography>
        <Grid rowGap={1} container>
            {includeCourse.map((learn)=>
            <Grid gap={2} item sm={6} xs={12}>
              <Typography component='div'
              variant='caption'>
                <Stack direction='row' gap={2}>
              <Box >
                {learn.icon}
              </Box>
              {learn.text}
                </Stack>
              </Typography>
            </Grid>
                )}
        </Grid>
       
    </Box>
    <Card sx={{py:3,px:2, my:2, bgcolor:'transparent' }}>
      <Typography 
      component='div'
      variant='h6'
      fontWeight={650}>
     Top companies offer this course to their employees
     </Typography>  
      <Typography 
      component='div'
      variant='caption'
      mb={3}
      fontWeight={600}>
    This course was selected for our collection of top-rated courses trusted by businesses worldwide. Learn more
     </Typography>  
     <CssCarousal breakpoints={{100:{
        width:100,
        slidePerView:1
     }}}>
        {companeis.flatMap((company)=>
        <SwiperSlide>
            <CardMedia
            component='img'
            src={company.src}
            sx={{objectFit:'contain'}}/>
            </SwiperSlide>
            )}
        </CssCarousal> 
    </Card>
    <Card sx={{py:2,px:0,bgcolor:'transparent' }}>
        <Grid container>
            <Grid py={3} px={1} rowGap={2} sm={6} xs={12}>
        <Typography component='div'
        variant='h5' mb={2} fontWeight={700}>
            What you'll learn
        </Typography>
        <Typography component='div'
        variant='caption'  mb={2}>
        This course includes our updated coding exercises so you can practice your skills as you learn.
        </Typography>
             <Button >
                See a Demo
             </Button>
            </Grid>
          
            <Grid item sm={6} xs={12}>
             <CardMedia
             component='img'
             sx={{objectFit:'cover'}}
             src={img}
             />
            </Grid>
              
        </Grid>
    </Card>
    <Box sx={{py:2,px:0}}>
        <Typography component='div'
        variant='h5' mb={2} fontWeight={700}>
           Contents
        </Typography>
     <Faq  accorditions={content} />  
       
    </Box> 
    <Box sx={{py:2,px:0}}>
        <Typography component='div'
        variant='h5' mb={2} fontWeight={700}>
           This Course includes
        </Typography>
        <Grid container>
           <ul>
            <li>

        <Typography component='div'
        variant='caption' mb={2} fontWeight={700}>
           This Course includes
        </Typography>
            </li>
            </ul> 

        </Grid>
       
    </Box>
    </Grid>
    <Grid rowGap={2} item xs={0} md={3} px={1} lg={3}>
        <Card sx={{bgcolor:'transparent'}} >
            <CardMedia 
            component='video'
            src={reel1}
            sx={{
                height:150,
                objectFit:'contain',
                
            }}
            />
            <Divider/>
            <Box rowGap={1}  sx={{px:1, pt:.5, pb:3}}>

            
            <Typography lineHeight={1} 
            component='div'
            fontWeight={700}
            variant='h6'>
                Subscribe to Udemy’s top courses
            </Typography>
            <Typography
            my={2}
            component='div'
            variant='body1'
            >
                Get this course, plus 11,000+ of our top-rated courses, with Personal Plan. Learn more
            </Typography>
            <Button fullWidth sx={{boxShadow:5, }}>
                Start Subscription
            </Button>
            <Typography textAlign='center' 
            component='div'
            variant='caption'>
                Starting at 850 per month
                <br/>
                Cancel anytime
            </Typography>
            <Divider sx={{my:1}} >or</Divider>
            </Box>
        </Card>
    </Grid>
    </Grid>
    </Container>
    </Grid>
  )
}

export default CoursesItem