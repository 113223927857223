import { Box, Card, CardMedia, Grid } from '@mui/material'
import React from 'react'
import CssCarousal from '../slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import img from '../../assets/hero1.png'

const SliderCard = ({effect}) => {
  return (
    <Box sx={{my:'auto'}}>

                        <Card  sx={{
                            p:1,
    my:1,
    mx:1,
    gap:2,
     overflow:'visible',
    display: 'flex',
    bgcolor:'transparent',

    flexDirection: { xs: 'column', sm: 'column' },
    alignItems: 'flex-start', 
   
  }}>
                            <Grid  container>

     
                      <CssCarousal effect={effect}>
                      {
                          [0,1,3].map((key)=>
<SwiperSlide key={key}>
<CardMedia
  component="img"
  alt="Contemplative Reptile album cover"
  src={ img}
  sx={{
    position:'relative',
      p:0,
      my:2,
      display:'flex',
      width: { xs: '100%', sm: '100%' },
      borderRadius: 2,
      ":hover":{
          transitionTimingFunction:'ease-in-out',
          backgroundColor:'blueviolet'
        }
  }}
/>
</SwiperSlide>
)}

                      </CssCarousal>
    </Grid>
</Card>
                         </Box>
  )
}

export default SliderCard