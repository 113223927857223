import { Facebook, FactCheck, Google } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Container, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import SliderCard from '../component/common/SltderCard'
const Login = () => {
  const navigate = useNavigate()
  const handleClick =()=>{
    navigate('/prefrences')
  }
  return (
 
      <Container sx={{mt:5}} maxWidth='lg'>
        <Box sx={{ 
          boxShadow:'10',
           
        }}>
          <Grid container  direction={{md:'row', xs:'column-reverse'}} >
            <Grid item xs={12} md={6}>
            <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
          <Box sx={{m:5}}>
            <Typography sx={{mb:5}} component='h2'>
              Sign In
            </Typography>
            <TextField
             sx={{width:'100%',
            mb:2}}
             required
             id="outlined-required"
             label="Email or username"
             defaultValue="Email"
             />
            <TextField
             sx={{
              width:'100%',
              mb:2
            }}
               required
                id="outlined-required"
             label="Password"
          defaultValue="Password"
          />
          <Button onClick={handleClick} variant='contained' size='large' sx={{
            width:'100%'
          }} color='secondary'>
            Sign In
          </Button>
          <Box display='flex' justifyContent='space-between'>
           <Button variant='text'>
             Forgot Password
           </Button>
           <Button variant='text'>
             Forgot Username
           </Button>

          </Box>
          <Divider>OR</Divider>
          <Stack sx={{mt:1}} rowGap={1.5}>
            <Button startIcon={<Facebook/>} variant='contained'>
             login via Truecaller
            </Button>
            <Button color='error' startIcon={<Google/>} variant='contained'>
             login via Google
            </Button>
            <Button color='info' startIcon={<Facebook/>} variant='contained'>
             login via Facebook
            </Button>
          </Stack>

          </Box>

        </Card>
                      </Grid>
                      <Grid xs={12} md={6}>
                        <SliderCard/>
                      </Grid>
                    </Grid>
        <Card>

        </Card>
        </Box>
      </Container>
  
  )
}

export default Login