import { useState } from "react";
import {
	Card,
	CardContent,
	Typography,
	TextField,
	Button,
	Grid,
	IconButton,
	Box,
	Avatar,
} from "@mui/material";
import { styled } from "@mui/system";
import PhotoIcon from "@mui/icons-material/Photo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoodIcon from "@mui/icons-material/SentimentSatisfied";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const CustomCard = styled(Card)(({ theme }) => ({
	marginTop: "5px",
	width: "100%",
	margin: "auto",
	boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
	borderRadius: theme.spacing(1),
	backgroundColor: "transparent",
	padding: theme.spacing(1),
}));

const AddPost = ({ onSubmit }) => {
	const [postText, setPostText] = useState("");
	const [selectedImage, setSelectedImage] = useState(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handlePostTextChange = (event) => {
		setPostText(event.target.value);
	};

	const handleImageUpload = (event) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedImage(URL.createObjectURL(event.target.files[0]));
		}
	};

	const handleSubmit = () => {
		if (postText.trim() !== "") {
			onSubmit(postText);
			setPostText("");
			setSelectedImage(null);
		}
	};

	return (
		<CustomCard>
			<CardContent>
				<Typography variant="h6" gutterBottom>
					Create a Post
				</Typography>
				<Grid container spacing={1}>
					<Grid item xs={2} sm={1} lg={1}>
						{isMobile ? (
							<Avatar
								alt="Circular Image"
								src="https://images.unsplash.com/photo-1473496169904-658ba7c44d8a?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c3VtbWVyfGVufDB8fDB8fHww"
								sx={{
									bgcolor: "secondary.main",
									marginBottom: isMobile ? theme.spacing(2) : 0,
								}}
							/>
						) : (
							<Avatar
								alt="Circular Image"
								src="https://images.unsplash.com/photo-1473496169904-658ba7c44d8a?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c3VtbWVyfGVufDB8fDB8fHww"
								sx={{
									height: "80px",
									width: "80px",
									bgcolor: "secondary.main",
									marginBottom: isMobile ? theme.spacing(2) : 0,
								}}
							/>
						)}
					</Grid>
					<Grid item xs={10} sm={6} lg={8}>
						<TextField
							label="What's on your mind"
							multiline
							fullWidth
							rows={2}
							variant="outlined"
							value={postText}
							onChange={handlePostTextChange}
							placeholder="Write your post here..."
							autoFocus
						/>
					</Grid>
					{isMobile ? (
						<>
							<Grid item xs={12}>
								<Grid container spacing={1}>
									<Grid item>
										<input
											accept="image/*"
											style={{ display: "none" }}
											id="upload-image"
											type="file"
											onChange={handleImageUpload}
										/>
										<label htmlFor="upload-image">
											<IconButton component="span" color="primary">
												<PhotoIcon />
											</IconButton>
										</label>
									</Grid>
									<Grid item>
										<IconButton color="primary">
											<LocationOnIcon />
										</IconButton>
									</Grid>
									<Grid item>
										<IconButton color="primary">
											<MoodIcon />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Button
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									disabled={!postText.trim()}
									fullWidth
								>
									Post
								</Button>
							</Grid>
						</>
					) : (
						<Grid item xs={4} lg={2} container spacing={1} alignItems="center">
							<Grid item>
								<input
									accept="image/*"
									style={{ display: "none" }}
									id="upload-image"
									type="file"
									onChange={handleImageUpload}
								/>
								<label htmlFor="upload-image">
									<IconButton component="span" color="primary">
										<PhotoIcon />
									</IconButton>
								</label>
							</Grid>
							<Grid item>
								<IconButton color="primary">
									<LocationOnIcon />
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton color="primary">
									<MoodIcon />
								</IconButton>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									disabled={!postText.trim()}
								>
									Post
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
				{selectedImage && (
					<Box mt={2}>
						<img
							src={selectedImage}
							alt="Selected"
							style={{ width: "100px", borderRadius: "8px" }}
						/>
					</Box>
				)}
			</CardContent>
		</CustomCard>
	);
};

export default AddPost;
