import { PlayArrow } from '@mui/icons-material'
import { Card, CardMedia } from '@mui/material'
import React from 'react'

const ReelCard = ({src}) => {
function PlayVideo(e){
    let playPromise = e.target.play()
    if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
          // Show playing UI.
          // We can now safely pause video...
        //   e.target.pause();
        console.log('paly')
        
    })
    .catch(error => {
        // Auto-play was prevented
        // Show paused UI.
        // e.target.pause();
        // console.log('pagggggggy')

        });
      }
}
  return (
    <div>
        <Card className='mx-3  rounded-lg'>
        
            <CardMedia
          
            sx={{
                height:300,
                width:250,
                objectFit:'cover'
            }}

            
           onMouseOver={event=> PlayVideo(event)}
           onMouseOut={e=>e.target.pause()}
            component='video'
            src={src}
            >
            </CardMedia>
            
        </Card>
        <div className='mx-3 mt-3 font-semibold text-slate-600 text-sm'>
          Reel is going to be viral in secends
        </div>
    </div>
  )
}

export default ReelCard