import React, { Children, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

import "./css/fade.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import required modules
import { Autoplay, EffectCards } from "swiper/modules";
import { Button, Card, CardMedia, Typography } from "@mui/material";
import hero1 from "../../assets/hero1.png";
import hero2 from "../../assets/hero2.png";
import hero4 from "../../assets/hero4.png";
import { a1, a2, a3, a4, a5 } from "../../assets";

export default function EffectCarousel({ swiperitems }) {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const swipers = [
		{ id: "1", title: "React Developer", img: a1 },
		{ id: "2", title: "React Developer", img: a2 },

		{ id: "3", title: "React Developer", img: a3 },
		{ id: "4", title: "React Developer", img: a4 },
		{ id: "5", title: "React Developer", img: a5 },
	];

	const Layout = ({ childen }) => (
		<Card>
			<CardMedia component="image" src={childen.img}></CardMedia>
			<Typography component="p">{Children.title}</Typography>
		</Card>
	);

	return (
		<>
			<Swiper
				effect={"cards"}
				loop={true}
				autoplay={{
					pauseOnMouseEnter: true,
					delay: 2000,
				}}
				grabCursor={true}
				modules={[EffectCards, Autoplay]}
				className="mySwipers"
			>
				{/* {swiperitems.map((swiper, id)=>
        <SwiperSlide key={id} className='Slide' >{swiper.item}</SwiperSlide>
          )} */}
				{swipers.map((swiper, id) => (
					<SwiperSlide
						key={id}
						className="Slide"
						style={{ backgroundColor: "transparent" }}
					>
						<Card sx={{ position: "relative" }}>
							{/* <CardMedia
    component='img'
    sx={{
      width:'240px',
      height:'320px'

    }}
    src={swiper.img}
    >
      */}
							<img
								src={swiper.img}
								style={{
									width: "240px",
									height: isMobile ? "150px" : "300px",
								}}
								alt="card i"
							/>
							{/* </CardMedia> */}
							{/* <Typography component='p'>
      {swiper.title}
         </Typography> */}
						</Card>
						<div className="w-full absolute mt-2">
							<Button
								sx={{
									boxShadow: 2,
									color: "#000",
									":hover": {
										boxShadow: 20,
									},
								}}
							>
								Click Here
							</Button>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
}
