import React, { useEffect, useState } from 'react'
import CarouselNav from '../../component/slider/CarosalNav'
import { Avatar, Box, Button, Card, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { CalendarViewMonthOutlined, FacebookRounded, Forward, HandymanTwoTone, Instagram, LiveTvOutlined, Person4Rounded, QuestionMarkOutlined, QuestionMarkRounded, Settings, Share, StarBorderOutlined, Twitter, UploadFile, UploadTwoTone, VideoLabelRounded, WalletOutlined } from '@mui/icons-material'
import BoxButton from '../../component/common/BoxButton'
import CssCarousal from '../../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import vedio from '../../assets/Socialism.mp4'
import Loading from '../../component/common/Loading'
import { Helmet } from 'react-helmet'

const Dashboard = () => {
  
    const uploadPromo=[
        {id:1, link:'yourpromo',title:'Your Promo'},
        {id:2, link:'addpromo',title:'Add Promo'}
    ]
   const wallet =[
    {id:1,link:'',title:'Refer Earning'},
    {id:2,link:'',title:'Course Earning'},
    {id:2,link:'',title:'Fund '}
   ]
    const earning=[
        {id:1,link:'',title:'No of Stdent Enrolled'},
        {id:2,link:'',title:'No of Stdent Refer'},
    ]
   
     const connection=[
        {id:1,link:'',title:'Student Id'},
        {id:2,link:'',title:'Monthly Data'},
        {id:3,link:'',title:'Yearly Data'},
        {id:4,link:'',title:'Lifetime Data ' },
     ]

    const dashboardbutton =[
        {id:'promo', icon:<UploadTwoTone/>,title:'updload Promo', color:'success', child:uploadPromo},
        {id:'wallet', icon:<WalletOutlined/>,title:'My Wallet',color:'info',child:wallet},
        {id:'earning', icon:<HandymanTwoTone/>,title:'My Earning',color:'primary',child:earning},
        {id:'/recordVideo', icon:<VideoLabelRounded/>,title:'Record Video',color:'warning', child:earning},
        {id:'liveClassShedule', icon:<LiveTvOutlined/>,title:'Live Class Schedule', color:'secondary', child:connection},
        {id:'connection', icon:<Person4Rounded/>,title:'My Connection',color:'error', child:connection},
    ]
     
   
 

  return (
   <>
   <Helmet>
    <title>
      Dashboard
    </title>
   </Helmet>
   <Card sx={{height:'25vh',mb:2}}>
              <Grid container>

              <CarouselNav/>
              </Grid>
          </Card>
          <Grid mb={2} rowGap={4} container>
              {dashboardbutton.map((button)=>
         <Grid item xs={6} md={4}>
         <BoxButton button={button}>
         </BoxButton>
          </Grid>
        )}
          </Grid>
          <Card sx={{mx:{lg:1,md:0, xs:0,height:{md:'40vh',xs:'fit'},display:'flex', justifyContent:'center', alignItems:'center'}}}><Box>
           <Grid container>
            {/* <CssCarousal>

          {[0,0,0,].map((key)=>
          <SwiperSlide> */}
            <CardMedia
            src={vedio}
            component='video'
            controls
            />
          {/* </SwiperSlide>
          )}
          
            </CssCarousal> */}

           </Grid>
           
          </Box>
          </Card>
   </>
  )
}

export default Dashboard