import { HealthAndSafetySharp } from '@mui/icons-material'
import { Button, Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const RevolutionCard = ({object, elevation}) => {
  const navigate = useNavigate()

  const handleClick =(object)=>{
    navigate(object.link)
  }
  return (
   
    <Card   elevation={elevation} sx={{mx:2,px:3, py:1,  minHeight:{md:'180px', xs:'fit', textAlign:'center'}}}>
    <Stack  >
    {object.icon?<Typography  gap={1} lineHeight={0.5} component='span' variant='button'>
        { object.icon }
    </Typography>:''}
    </Stack>
   {object.title ? <Typography  lineHeight={1} my={1} fontWeight={750} component='div'variant='h4'>
    { object.title}
    </Typography>:''}
    {object.desc ?<Typography lineHeight={1.2} fontSize={20} component='div'variant='subtitle1'>
    { object.desc}
    </Typography>:''}
    {
      object.button ?
      <Link to={object.link} >
      <Button  fullWidth sx={{boxShadow:5}}>
        {object.button}
      </Button>
      </Link>
      :''
    }
    </Card>

  )
}

export default RevolutionCard