import { Facebook, FactCheck, Google } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Container, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import Courses from '../section/Courses'
import { hero1,hero2,hero4 } from '../assets'
import CourseCard from '../component/common/CourseCard'
import { Helmet } from 'react-helmet'
import { listCourses } from '../data/findteache'
import Loading from '../component/common/Loading'



const FindTeacher = () => {
  const [isLoding, setisLoding] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setisLoding(false)
    }, 1000);
  
  },[])
  
if(isLoding){
  return<Loading/>
}
   
  return (
    <div>
        <Helmet>
            <title>Find Course</title>
        </Helmet>
    <Container sx={{mt:8, mb:50,}} maxWidth='xl'>
      <Box sx={{ 
        boxShadow:'10',
        py:3
      }}>
        
        <Grid textAlign='center' container justifyContent='center'  sx={{mt:4}}>
          <h1 className=' font-bold text-5xl pb-2 '>Popular Online Courses</h1>
          <div className='w-3/4'>
              <p className='mx-3 mt-1 text-2xl text-gray-400 text-center'>
                  Live learning with industry experts. Jobs at technology companies.
              </p>
          </div>
      </Grid>
      <Grid rowGap={7} className=' py-7 my-5' container>
              {listCourses.map((product, id) => 
              <Grid lg={4} md={4} sm={6} xs={12} key={id}>
                 <CourseCard product={product}/>
              </Grid>
              )}
          </Grid>
    
      </Box>
    </Container>
  </div>
  )
}

export default FindTeacher