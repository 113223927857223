import { Grid, Stack } from "@mui/material";
import React from "react";
import ProductCard from "../component/ProductCard";
import Heading from "../component/common/Heading/Heading";

const Courses = ({ listProduct, button1, button2, heading }) => {
	return (
		<>
			{" "}
			<Heading
				heading="Popular Online Courses"
				subtitle2="Live learning with industry experts. Jobs at technology companies. "
			/>
			<Grid justifyContent="center" sx={{ mt: 4 }}>
				{/* <h1 className=" text-center font-bold text-5xl pb-2 ">
					{heading ? heading : ""}
				</h1>

				<div className="w-/4">
					<p className="mx-3 mt-1 text-2xl text-gray-400">
						{
							"  "
						}
					</p>
				</div>     */}
			</Grid>
			<Grid rowGap={7} className=" py-7 my-5" container>
				{listProduct.map((product, id) => (
					<Grid lg={4} md={6} sm={6} xs={12} key={id}>
						<ProductCard
							button={true}
							courses={true}
							button1={button1}
							button2={button2}
							product={product}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default Courses;
