import {    Close, Person,  } from '@mui/icons-material'
import {  Box, Button, Card, CardMedia, Container,  FormControl, Grid, IconButton, Input, InputBase, InputLabel, MenuItem, Select, Stack,  TextField,  Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import { hero1 as img } from '../assets'

import VisuallyHiddenInput from '../component/common/VisuallyHiddenInput'
import { useNavigate } from 'react-router-dom'
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'

const EducationVerify = () => {

    const [HighSchool, setHighSchool] = useState()
    const [Intermediate, setIntermediate] = useState()
    const [UG, setUg] = useState()
    const [PG, setPG] = useState()
       const navigate = useNavigate()
    const [file, setFile] = useState(1);
    const [isLoding, setisLoding] = useState(true)
    useEffect(() => {
      setTimeout(() => {
        setisLoding(false)
      }, 1000);
    
    },[])
    
  if(isLoding){
    return<Loading/>
  }
    function handlefile(e) {
       console.log(e.target.files[0].name)
      setFile(URL.createObjectURL(e.target.files[0]));
  }

    // const [type, setType] = useState('');

    // const handleChange = (event) => {
    //   setType(event.target.value);
    // };

    const handleNext = ()=>{
         navigate('/videoverification')
    }

  return (
    <div>
      <Helmet>
        <title>
          Education Verification
        </title>
      </Helmet>
    <Container sx={{mt:5}} maxWidth='lg'>
      <Box sx={{ 
        boxShadow:'10',
         display :'flex'
      }}>
        <Grid container >
          <Grid item lg={6}>
          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}}  rowGap={3}>
            <Stack sx={{mb:3}}>
                <Typography fontWeight={600} fontSize={30}  component='h1'>
               Upload  Your Education Proof
                </Typography>
            </Stack>

        <FormControl  fullWidth>
  {/* <InputLabel id="demo-simple-select-label">Identitiy Proof </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={type}
    label="?"
    onChange={handleChange}
    placeholder='Address Proof'
  >
    <MenuItem value='HighSchool'>High School</MenuItem>
    <MenuItem value='Intermediate'>Intermediate</MenuItem>
    <MenuItem value='Ug'>UG</MenuItem>
    <MenuItem value='PG'>PG</MenuItem>
    <MenuItem value='others'>Others</MenuItem>
    
  </Select> */}
  <Stack rowGap={2}>
  <Stack  direction='row'>

  <Stack  direction='row' justifyContent='space-between' sx={{
      mx:2,
      boxShadow:5,
      borderRadius:5,
      height:40,
      width:'100%'
    }}
    >
     {HighSchool ? <>
     <Typography sx={{ my: 'auto', ml: 2 }} component='div' variant='subtitle1'>
                                                      {HighSchool[0].name}
                                                  </Typography>
                                                  <IconButton onClick={()=>setHighSchool(null)}>
                                                      <Close />
                                                      </IconButton></>
     :
     <Typography sx={{my:'auto', ml:2}} component='div' variant='subtitle1'>
        High School MarkSheet
        </Typography>}

  </Stack>
  <Box  display='flex' justifyContent='flex-end'>
  <Button
               component="label"
               role={undefined}
                
             variant='contained'
               tabIndex={-1}
             
               
               >
        Browse
      <VisuallyHiddenInput onChange={(e)=>setHighSchool(e.target.files)} type='file'/>
    </Button>
  </Box>
      </Stack>
  <Stack direction='row'>

  <Stack direction='row' justifyContent='space-between' sx={{
      mx:2,
      boxShadow:5,
      borderRadius:5,
      height:40,
      width:'100%'
    }}
    >
     {Intermediate ? <><Typography sx={{ my: 'auto', ml: 2 }} component='div' variant='subtitle1'>
                                                      {Intermediate[0].name}
                                                  </Typography><IconButton onClick={() => setIntermediate(null)}>
                                                          <Close />
                                                      </IconButton></>
     :
     <Typography sx={{my:'auto', ml:2}} component='div' variant='subtitle1'>
        Intermediate 
        </Typography>}

  </Stack>
  <Box  display='flex' justifyContent='flex-end'>
  <Button
               component="label"
               role={undefined}
                
             variant='contained'
               tabIndex={-1}
             
               
               >
        Browse
      <VisuallyHiddenInput onChange={(e)=>setIntermediate(e.target.files)} type='file'/>
    </Button>
  </Box>
      </Stack>
      {/* Ug Marksheet */}
  <Stack direction='row'>

  <Stack  direction='row' justifyContent='space-between' sx={{
      mx:2,
      boxShadow:5,
      borderRadius:5,
      height:40,
      width:'100%'
    }}
    >
     {UG ? <><Typography sx={{ my: 'auto', ml: 2 }} component='div' variant='subtitle1'>
                                                      {UG[0].name}
                                                  </Typography><IconButton onClick={() => setUg(null)}>
                                                          <Close />
                                                      </IconButton></>
     :
     <Typography sx={{my:'auto', ml:2}} component='div' variant='subtitle1'>
        UG Degree
        </Typography>}

  </Stack>
  <Box  display='flex' justifyContent='flex-end'>
  <Button
               component="label"
               role={undefined}
                
             variant='contained'
               tabIndex={-1}
             
               
               >
        Browse
      <VisuallyHiddenInput onChange={(e)=>setUg(e.target.files)} type='file'/>
    </Button>
  </Box>
      </Stack>
      {/* Pg Degree Upload */}
  <Stack direction='row'>

  <Stack  direction='row' justifyContent='space-between' sx={{
      mx:2,
      boxShadow:5,
      borderRadius:5,
      height:40,
      width:'100%'
    }}
    >
     {PG ? <><Typography sx={{ my: 'auto', ml: 2 }} component='div' variant='subtitle1'>
                                                      {PG[0].name}
                                                  </Typography><IconButton onClick={() => setPG(null)}>
                                                          <Close />
                                                      </IconButton></>
     :
     <Typography sx={{my:'auto', ml:2}} component='div' variant='subtitle1'>
        UG Degree
        </Typography>}

  </Stack>
  <Box  display='flex' justifyContent='flex-end'>
  <Button
               component="label"
               role={undefined}
                
             variant='contained'
               tabIndex={-1}
             
               
               >
        Browse
      <VisuallyHiddenInput onChange={(e)=>setPG(e.target.files)} type='file'/>
    </Button>
  </Box>
      </Stack>
      </Stack>
  
  <Grid sx={{my:1}} container>
   
         
 
 
  </Grid>
  <Stack  rowGap={0} direction='row' display='flex' justifyContent='space-evenly' >
    <Button variant='contained' size='large' color='info'>
    back
    </Button>
  {file  ?<Button onClick={handleNext}  variant='contained'
 
  size='large' sx={{
          
          
        }} color='secondary'>
         Next
        </Button>
        :
        <Button disabled variant='contained' size='large' sx={{
        
     
      }} color='secondary'>
        Next
      </Button>
       }   

         </Stack>
</FormControl>
     {/* <Box display='flex' justifyContent='center'>

       <Avatar  sx={{height:100, width:100}}>
        {type? <AccountBoxTwoTone />:<QuestionMark fontSize='100px'/> }
       </Avatar>
          
     </Box> */}

        </Box>

      </Card>
                    </Grid>
                    <Grid lg={6}>
                      <CssCarousal>
                      {
[0,1,3].map((key)=>
<SwiperSlide key={key}>
<CardMedia
  component="img"
  alt="Contemplative Reptile album cover"
  src={ img}
  sx={{
    position:'relative',
      p:0,
      my:2,
      width: { xs: '100%', sm: '100%' },
    borderRadius: 2,
    ":hover":{
      transitionTimingFunction:'ease-in-out',
      backgroundColor:'blueviolet'
    }
  }}
/>
</SwiperSlide>
)}

                      </CssCarousal>
                    </Grid>
                  </Grid>
      <Card>

      </Card>
      </Box>
    </Container>
  </div>
  )
}




export default EducationVerify