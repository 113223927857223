import React, { lazy } from "react";
import holon from "./holon_iq.png";
import gsv from "./gsv_cup.webp";
import hero1 from "./hero1.png";
import hero2 from "./hero2.png";
import hero4 from "./hero4.png";
import hero5 from "./hero5.jpeg";
import hero6 from "./hero6.jpeg";
import hero7 from "./hero7.jpeg";
import hero8 from "./hero8.jpeg";
import hero9 from "./hero9.jpeg";
import hero10 from "./hero10.jpeg";
import hero11 from "./hero11.jpeg";
import hero12 from "./hero12.jpeg";
import hero13 from "./hero13.jpg";
import hero14 from "./hero14.jpg";
import hero15 from "./hero15.jpg";
import studentImg from "./image_student.png";
import next1 from "./next1.png";
import next2 from "./next2.png";
import next3 from "./next3.png";
import logo from "./sudo24.png";
import Banner2 from "./banner/Banner2.webp";
import Banner3 from "./banner/Banner3.webp";
import reel1 from "./reels/reel1.mp4";
import reel2 from "./reels/reel2.mp4";
import a1 from "./photos/a1.jpeg";
import a2 from "./photos/a2.jpeg";
import a3 from "./photos/a3.jpeg";
import a4 from "./photos/a4.jpeg";
import a5 from "./photos/a5.jpeg";
const Socialism = lazy(() => import("./Socialism.mp4"));
const app = lazy(() => import("./app.mp4"));
export {
	app,
	a1,
	a2,
	a3,
	a4,
	a5,
	hero1,
	hero2,
	hero4,
	hero5,
	hero6,
	hero7,
	hero8,
	hero9,
	hero10,
	hero11,
	hero12,
	hero13,
	hero14,
	hero15,
	studentImg,
	next1,
	next2,
	next3,
	logo,
	Banner2,
	Banner3,
	reel1,
	reel2,
	Socialism,
	gsv,
	holon,
};
