import { createSlice } from "@reduxjs/toolkit";

const Cart =createSlice({
    name:'Car',
    initialState:[
    { id:1,
        title:'ksksj'
       }
    ],
   
    reducers:{
    addToCart: (state, action) =>{
         const newItem = {
            id:action.payload.id
        };

        state.push(newItem)
        },

    deleteFromCart: (state, action) =>{
         const index =state.findIndex((todo)=>todo.id ===action.id)
        state.splice(index, 1)
        }
    }
    
})
export const {addToCart , deleteFromCart} =Cart.actions
export default Cart.reducer

