import { Card, CardMedia, Stack } from '@mui/material'
import React from 'react'

const StackCard = ({row}) => {
  return (
    <Stack  gap={3} className='my-4 text-left' direction='row'  >
    <Card      
variant="elevation"
elevation={5}
  sx={{
  width: '100px',
  ":hover":{
   transitionTimingFunction:'ease-in-out',
   // backgroundColor:'blueviolet',
   animation:'ease-in',
 zoom:1.1
 }
   }}
>
<CardMedia
component="img"



alt="Contemplative Reptile album cover"
src={row.src}
sx={{
objectFit:'contain',


}}
/>              
     </Card>
        <Stack>
           <h1 className='text-lg font-bold'> {row.title}</h1>
           <p  className=' text-slate-800'> {row.Desc}</p>

        </Stack>
     </Stack>              
  )
}

export default StackCard