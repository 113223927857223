

const ourNumbers = [
    {value:'8M', desc:'Active Users'}, 
    {value:'22M', desc:'Active jobs'}, 
    {value:'13M', desc:'Active freshers'}, 
    {value:'133M', desc:'Active Job Seekers'}, 
    {value:'13', desc:'Active Locations'}, 
    {value:'13', desc:'Brand Units'}, 
    ]

    export {ourNumbers}