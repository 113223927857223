import { UploadSharp } from '@mui/icons-material'
import { Box, Button, Card, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import VisuallyHiddenInput from '../../component/common/VisuallyHiddenInput'

const AddPromo = () => {
  return (
    <>
      <Box  >
        <Container maxWidth='sm'>

        
         <Card sx={{p:2,mt:5, textAlign:'center'}}>
            <Typography 
            component='div'
            variant='subtitle1'>
                You Can Upload Video
            </Typography>
            <Typography
            component='div'
            variant='caption'>
             A Wonderful evenly
             A Wonderful evenly
             A Wonderful evenly
             A Wonderful evenly
            </Typography>
            <Stack justifyContent='center'>
            <Button
  component="label"
  role={undefined}
  variant="contained"
  tabIndex={-1}
  startIcon={<UploadSharp />}
>
  Upload Video
  <VisuallyHiddenInput type="file" />
</Button>
</Stack>
         </Card>
         </Container>
      </Box>
    </>
  )
}

export default AddPromo