
import { StarRounded } from "@mui/icons-material";
import About from "../Pages/About";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import TeacherRegistration from "../Pages/TeacherRegistration";
import UserType from "../Pages/UserType";
import FindTeacher from "../Pages/FindTeacher";
import Career from "../Pages/Career";
import EarnMony from "../Pages/EarnMony";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import RefundPolicy from "../Pages/RefundPolicy";
import TermCondition from "../Pages/TermCondition";
import ContactUs from "../Pages/ContactUs";
import BussinessWithUs from "../Pages/BussinessWithUs";
import PaymentPlan from "../Pages/PaymentPlan";
import TeacherLogin from "../Pages/TeacherLogin";
import Prefrences from "../Pages/Prefrences";
import AddressVerification from "../Pages/AddressVerification";
import Identityverify from "../Pages/Identityverify";
import EducationVerify from "../Pages/EducationVerify";
import VideoVerification from "../Pages/VideoVerification";
import JoinwithUs from "../Pages/JoinwithUs";
import Cart from "../Pages/Cart";
import Hiring from "../Pages/Hiring";
import BecomeaTeacher from "../Pages/BecomeaTeacher";
import BecomeStudent from "../Pages/BecomeStudent";
import CoursesItem from "../Pages/CoursesItem";
import Blogs from "../Pages/Blogs";
import Dashboard from "../Pages/Teacher/Dashboard";
import Profile from "../Pages/Profile";
import DashboardOption from "../Pages/Teacher/DashboardOption";
import LiveClass from "../Pages/Teacher/LiveClass";
import ReferEarn from "../Pages/Teacher/ReferEarn";
import YourPromo from "../Pages/Teacher/YourPromo";
import RecordVideo from "../Pages/Teacher/RecordVedio/RecordVedio";
import AddPromo from "../Pages/Teacher/AddPromo";
import CoursesEarning from "../Pages/Teacher/CoursesEarning";
import StudentEnrolled from "../Pages/Teacher/StudentEnrolled";
import StudentRefer from "../Pages/Teacher/StudentRefer";
import StudentIssue from "../Pages/Teacher/yourConnections/StudentIssue";
import Fund from "../Pages/Teacher/wallet/Fund";
import MonthlyData from "../Pages/Teacher/yourConnections/MonthlyData";
import YearlyData from "../Pages/Teacher/yourConnections/YearlyData";
import LifeTimeData from "../Pages/Teacher/yourConnections/LifeTimeData";
import Payment from "../Pages/payment";


const publicRoutes =[
    {path:'about', children :<About/>},
    {path:'/find', children :<FindTeacher/>},
    {path:'/', children :<Home/>},
    {path:'/earnmoney', children :<EarnMony/>},
    {path:'/joinwithus', children :<JoinwithUs/>},
    {path:'/policy', children :<PrivacyPolicy/>},
    {path:'/refund', children :<RefundPolicy/>},
    {path:'/term', children :<TermCondition/>},
    {path:'/contactus', children :<ContactUs/>},
    {path:'/bussinesswithus', children :<BussinessWithUs/>},
    {path:'/addressverify', children :<AddressVerification/>},
    {path:'/Identityverify', children :<Identityverify/>},
    {path:'/educationverification', children :<EducationVerify/>},
    {path:'/videoverification', children :<VideoVerification/>},
    {path:'/becomeateacher', children :<BecomeaTeacher/>},
    {path:'/becomeastudent', children :<BecomeStudent/>},
    {path:'/blogs', children :<Blogs/>},
    
    {path:'/payment', children :<Payment/>},
    {path:'/hiring', children :<Hiring/>},
    {path:'/career', children :<Career/>},
    {path:'/usertype', children :<UserType/>},
    {path:'/prefrences', children :<Prefrences/>},
    {path:'/login', children :<Login/>},
    {path:'/register', children :<Register/>},
    {path:'/studentRegister', children :<StarRounded/>},
    {path:`/courses/:id`, children :<CoursesItem />},
    {path:'/teacherregistration', children :<TeacherRegistration/>},
    {path:'/teacherlogin', children :<TeacherLogin/>},
    {path:'/plan', children :<PaymentPlan/>},
    {path:'/cart', children :<Cart/>},
]
const auth =[
    
]

const teacher =[
    {path:'/dashboard', children :<Dashboard/>},
    {path:'/dashboard/:id', children :<DashboardOption/>},
    {path:'/profile', children :<Profile/>},
    
    ///promo
        {path:'/yourpromo', children :<YourPromo/>},
        {path:'/addpromo', children :<AddPromo/>},



    {path:'/recordVideo', children :<RecordVideo/>},
    {path:'/referearn', children :<ReferEarn/>},
    {path:'/refrallenrolled', children :<StudentEnrolled/>},
    {path:'/studentrefer', children :<StudentRefer/>},
    {path:'/funds', children :<Fund/>},
    {path:'/Coursesearn', children :<CoursesEarning/>},
    {path:'/liveclasses', children :<LiveClass/>},
    {path:'/cart', children :<Cart/>},

    //connections
    {path:'/monthlydata', children :<MonthlyData/>},
    {path:'/yearlydata', children :<YearlyData/>},
    {path:'/lifetimedata', children :<LifeTimeData/>},
    {path:'/studentid', children :<StudentIssue/>},
    
]
const student =[

]
const admin =[

]

export {publicRoutes, teacher, student, admin, auth}