import {    Person,  } from '@mui/icons-material'
import {  Box, Button, Card, CardMedia, Container,  FormControl, Grid, InputLabel, MenuItem, Select, Stack,  Typography } from '@mui/material'
import React, { useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import img from '../assets/hero1.png'

import VisuallyHiddenInput from '../component/common/VisuallyHiddenInput'
import VideoRecorder from '../component/common/VideoRecorder'
import SliderCard from '../component/common/SltderCard'
import { useNavigate } from 'react-router-dom'

const VideoVerification = () => {
// kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk

  const [file, setFile] = useState();
  const [type, setType] = useState('');
//navigation
  const  navigate = useNavigate()
   
  function handlefile(e) {
      // console.log(e.target.files);
      setFile(URL.createObjectURL(e.target.files[0]));
  }


    const handleChange = (event) => {
      setType(event.target.value);
    };
   const [recordOption, setRecordOption] = useState("video");
    const toggleRecordOption = (type) => {
        return () => {
            setRecordOption(type);
        };
    };
    const handleSubmit =()=>{
      navigate('/plan')
    
     }

    
  return (
    <div>
    <Container sx={{mt:5}} maxWidth='xl'>
      <Box sx={{ 
        boxShadow:'10',
         display :'block'
      }}>
        <Grid  display='flex' direction={{lg:'row', xs:'column-reverse'}} container >
          <Grid display='block' xs={12} item lg={6}>
            <Box>

          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}} rowGap={3}>
           

        <FormControl  fullWidth> 
  <Grid alignItems='center' container>
    
   <VideoRecorder/>
  </Grid>
  <Stack  rowGap={0} direction='row' display='flex' justifyContent='space-evenly' >
    <Button variant='contained' size='large' color='info'>
    back
    </Button>
  {file  ?<Button  variant='contained'
 
  size='large' sx={{
          
          
        }} color='secondary'>
         Next
        </Button>
        :
        <Button onClick={handleSubmit} variant='contained' size='large' sx={{
        
     
      }} color='secondary'>
        Next
      </Button>
       }   

         </Stack>
</FormControl>
     

        </Box>

      </Card>
                            </Box>
                    </Grid>
                    <Grid xs={12} lg={6}>
                        <SliderCard/>
                    </Grid>
                 
                  </Grid>
      <Card>

      </Card>
      </Box>
    </Container>
  </div>
  )
}





export default VideoVerification