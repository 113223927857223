import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function Faq({accorditions}) {
    
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
        {accorditions.map((accordition, id)=>
      <Accordion key={id}   expanded={expanded === accordition.id} onChange={handleChange(accordition.id)} sx={{bgcolor:'transparent'}}>
        <AccordionSummary
        
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {accordition.title}
        </AccordionSummary>
        <AccordionDetails>
         {accordition.desc ? accordition.desc :' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse <br/> malesuada lacus ex, sit amet blandit leo lobortis eget.'}
        </AccordionDetails>
      </Accordion>
      )}
    
    </div>
  );
}
