 const acedemicsitems =[
    {id:1,title:'Class 1'},
    {id:2,title:'Class 2'},
    {id:3,title:'Class 3'},
    {id:4,title:'Class 4'},
    {id:5,title:'Class 5'},
    {id:6,title:'Class 6'},
    {id:7,title:'Class 7'},
    {id:8,title:'Class 8'},
    {id:9,title:'Class 9'},
    {id:10,title:'Class 10'},
    {id:12,title:'Class 12'},
    {id:13,title:'Class 13'},
    {id:14,title:'Class 14'},
    {id:15,title:'Class 15'},
 ]
 const crashCourseItem =[
    {id:1, title:'Physics Crash Cours'},
    {id:1, title:'Chemistry Crash Cours'},
    {id:1, title:'Bio Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
    {id:1, title:'Maths Crash Cours'},
 ]
const motivationalItem=[

]




const menuItem=[
    {id:1, title :'Academics' , button:1, item:acedemicsitems},
    {id:2, title :'Crash Course' , button:1, item: crashCourseItem},
    {id:3, title :'Motivational' , button:1, item:motivationalItem},
    {id:4, title :'Comptitional' , button:1, item:acedemicsitems},
    {id:5, title :'Skill Development' , button:1, item:acedemicsitems},
    {id:3, title :'Motivational' , button:1, item:acedemicsitems},
    {id:4, title :'Comptitional' , button:1, item:acedemicsitems},
    {id:5, title :'Skill Development' , button:1, item:acedemicsitems},
    {id:3, title :'Motivational' , button:1, item:acedemicsitems},
    {id:4, title :'Comptitional' , button:1, item:acedemicsitems},
    {id:5, title :'Skill Development' , button:1, item:acedemicsitems},
   ]
     export {menuItem}