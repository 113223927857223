import {
	Facebook,
	Instagram,
	LinkedIn,
	Twitter,
	WhatsApp,
	YardOutlined,
	YouTube,
} from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardActions,
	Container,
	Divider,
	Grid,
	InputBase,
	Paper,
	Stack,
} from "@mui/material";
import React from "react";
import { logo } from "../assets";
import { Typography } from "@mui/material";

// import CarouselNav from './slider/CarosalNav'
import Hire from "../section/Hire";
// import EffectCarousel from './slider/FadeCarousal'
import { Link } from "react-router-dom";
import Carousel from "./slider/Carousel";
import FadeCarousel from "./slider/FadeCarousal";
import SubscriptionCard from "./SubscriptionCard";

const Footer = () => {
	// const item= <>
	//      <Paper
	//     component="form"
	//     sx={{ p: '0px 0x', display: 'flex', alignItems: 'center' }}
	//   >

	//     <InputBase
	//       sx={{ ml: 1, flex: 1 , width:400}}
	//       placeholder="Enter You Email Id"
	//       type='email'
	//       inputProps={{ 'aria-label': 'Enter You Email Id' }}
	//     />
	//     <Button color='secondary'   variant='contained'  type="" sx={{ p: '10px' }} aria-label="Email">
	//       Send message
	//     </Button>

	//   </Paper>

	//     </>
	// Write Code by Taimur
	const responsiveFontSize = {
		fontSize: {
			xs: "15px", // Extra-small screens (0px - 600px)
			sm: "20px", // Small screens (600px - 900px)
			md: "25px", // Medium screens (900px - 1200px)
			lg: "30px", // Large screens (1200px - 1536px)
			xl: "36px", // Extra-large screens (1536px and above)
		},
	};
	const forMargin = {
		marginTop: {
			xs: "0",
			sm: "0",
			md: "4.9rem",
			lg: "4.9rem",
		},
	};
	const forPosition = {
		top: {
			xs: "1%",
			sm: "1%",
			md: "1%",
			xs: "5%",
		},
	};
	// const miniCarousel = {
	//   display:{
	//     xs:'none',
	//     sm:'none',
	//     md:'none',
	//     lg:'block'
	//   }
	// }
	// End

	return (
		// <>

		//  <Grid direction='column'  display='block' height='auto'   container className='   bottom-0 justify-evenly ' >
		//   <div className='absolute top-[10vh] z-40 animate-bounce hover:animate-none w-full  '>

		// <Hire  heading='Subscribe To our NewsLetter' desc='You Have Any Question Ask Frankly ' item ={item}/>
		//   </div>
		//   <Card  sx={{position:'absolute', maxHeight:400,display:'flex  ', bottom:0}}>

		//   <CarouselNav/>

		//   <div className=' absolute flex mt-5 bottom-0 min-h-64 w-full  text-white z-10 '>
		//  <Container maxWidth='xl'>
		//   <Box display='block' width='100%'>

		//     <div className='text-3xl text-white mx-2 '>
		//       <div><img className='object-contain w-16' src={logo}/></div>
		//     </div>

		//   <Grid display={{md:'flex'}}  fontSize={{lg:14,xs:10}} textAlign={{lg:'left', xs:'center'}} container className='font-normal hidden  text-lg md:text-xm  justify-between'>

		//   <Grid item lg={1} xs={3}  className=' md:w-1/5  '>
		//     <h2 className='lg:mb-5  font-bold'>Address</h2>
		//     <ul className=''>
		//       <li className='mb-3'><b>Phone:</b> +91 7390895898</li>
		//       <li><b>Address:</b>&nbsp; H169, H Block, Sector 63, Noida, Uttar Pradesh 201301</li>
		//     </ul>

		//     <div>
		//         <div className='text-md font-bold ml-5 mt-2'>Follow Us On Social Media</div>
		//         <div className='flex'>

		//         <div className='flex gap-3 mt-3'>
		//        <FacebookSharp className='text-white' style={{color:'#39569c'}}/>
		//        <Instagram style={{background:'linear-gradient(#405DE6,#5851DB, #833AB4,#C13584,#FD1D1D)',
		//   WebkitBackgroundClip: "text",
		//   WebkitTextFillColor: "transparent"}}/>
		//        <Twitter style={{color:'#1DA1F2'}}/>
		//        <LinkedIn style={{color:'#0762C8'}}/>
		//        <YouTube style={{color:'#FF0000'}}/>
		//        <WhatsApp style={{color:'#25D366'}}/>
		//        </div> </div>
		//       </div>
		//   </Grid>

		//   <Grid item lg={1} xs={3} textAlign={{lg:'left', xs:'center'}} >
		//     <h2 className='lg:mb-5  font-bold '>Get Started</h2>
		//     <ul className=''>
		//       <li><Link to='/'>Home</Link></li>
		//       <li><Link to='/about'>About Us</Link></li>
		//       <li><Link to='/blogs'>Feed/ Blog</Link></li>
		//       <li><Link to='/earnmoney'>Earn Money</Link></li>
		//     </ul>
		//   </Grid>
		//   <Grid item  lg={1} xs={3} justifyContent='flex-start' >
		//     <h2 className='Lg:mb-5  font-bold '>About</h2>
		//     <ul className=''>
		//       <li><Link to='/career'>Career</Link></li>
		//       <li><Link to='/contactus'>Contact Us</Link></li>
		//       <li><Link to='/policy'>Privacy Policy</Link></li>
		//       <li><Link to='term'>Terms & Conditions</Link> </li>
		//       <li><Link to='/refund'>Refund Policy</Link></li>
		//     </ul>
		//   </Grid>
		//   <Grid item   lg={2} xs={3} alignContent='flex-start'  >
		//     <h2 className='lg:mb-5  font-bold '>Features</h2>
		//     <ul className=''>
		//       <li><Link to='/bussinesswithus'>Overview</Link></li>
		//       <li>Design</li>
		//       <li>Code</li>
		//       <li>Colaborate</li>
		//     </ul>
		//   </Grid>

		//   <Grid xs={0} lg={3}  item className='overflow-hidden hidden lg:flex'>
		//     <Box overflow='hidden'>

		//   <EffectCarousel     />
		//     </Box>
		//   </Grid>

		//   </Grid>
		//   {/* <div className=' flex-row-reverse  flex gap-2'>
		//      <ul className='flex gap-2 text-sm'>
		//       <li><FacebookOutlined/></li>
		//       <li><Instagram/></li>
		//       <li><Twitter/></li>
		//       <li><LinkedIn/></li>
		//      </ul>
		//   </div>   */}
		//   {/* <Divider sx={{color:'#fff'}}/> */}
		//     <div className='flex justify-center'>
		//     <div>@, 2024, All Right Reserved. Designed By Sudo24.com</div>
		//     {/* <div>All Right Reserved</div> */}
		//   </div>
		//   </Box>

		//   </Container>
		//   </div>
		//   </Card>
		//  </Grid>
		// </>
		// Write Code by Taimur
		<>
			<SubscriptionCard />
			<footer style={{ position: "relative" }}>
				<Carousel />
				<div
					style={{
						position: "absolute",
						top: "5%",
						// left: 0,
						width: "100%",
						// height: '100%',
						// backgroundColor: 'rgba(0, 0, 0, 0.5)',
						display: "flex",
						alignItems: "center",
						// padding: '20px 0'
					}}
					sx={{ ...forPosition }}
				>
					<Container>
						<Grid container spacing={4}>
							<Grid item xs={6} sm={6} md={3} lg={3}>
								<img src={logo} width={100} />
								<Typography
									variant="h1"
									sx={{ ...responsiveFontSize }}
									color={"#fff"}
									lineHeight={"2.5"}
									fontWeight={600}
								>
									Address
								</Typography>
								<Typography variant="subtitle1" color={"#fff"}>
									H169, H Block, Sector 63, Noida, Uttar Pradesh 201301
								</Typography>
								{/* <Typography
									variant="h1"
									sx={{ ...responsiveFontSize }}
									fontWeight={600}
									fontSize={"25px"}
									color={"#fff"}
								>
									Contact
								</Typography> */}
								{/* <Typography>
									<a
										color="white"
										href="tel:7390895898"
										sx={{ ...responsiveFontSize }}
										fontWeight="600"
									>
										Mobile No
									</a>
								</Typography> */}
								<div
									style={{ display: "flex", gap: "10px", marginTop: "2rem" }}
								>
									<Link to="https://www.facebook.com/profile.php?id=100087011524163&mibextid=ZbWKwL">
										<Facebook
											sx={{ ...responsiveFontSize }}
											className="text-white"
											style={{ color: "#39569c" }}
										/>
									</Link>
									<Link to="https://www.instagram.com/sud_o24?igsh=ZjVubDRwODNldDcw">
										<Instagram
											sx={{ ...responsiveFontSize }}
											style={{
												background:
													"linear-gradient(#405DE6,#5851DB, #833AB4,#C13584,#FD1D1D)",
												WebkitBackgroundClip: "text",
												WebkitTextFillColor: "transparent",
											}}
										/>
									</Link>
									<Link to="">
										<Twitter
											sx={{ ...responsiveFontSize }}
											style={{ color: "#1DA1F2" }}
										/>
									</Link>
									<Link to="https://www.linkedin.com/company/sudo24/">
										<LinkedIn
											sx={{ ...responsiveFontSize }}
											style={{ color: "#0762C8" }}
										/>
									</Link>
									<Link to="https://youtube.com/@sudo24-?si=hZqkDmC7GMtObn_X">
										<YouTube
											sx={{ ...responsiveFontSize }}
											style={{ color: "#FF0000" }}
										/>
									</Link>
									<Link to="">
										<WhatsApp
											sx={{ ...responsiveFontSize }}
											style={{ color: "#25D366" }}
										/>
									</Link>
								</div>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3}>
								<Typography
									variant="h1"
									marginTop="4.9rem"
									sx={{ ...responsiveFontSize }}
									lineHeight={"2.5"}
									fontWeight={600}
									color={"#fff"}
								>
									Get Started
								</Typography>
								<ul
									style={{
										listStyle: "none",
										paddingLeft: "0",
										lineHeight: "1.8",
									}}
								>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize.xs }}
											to="/"
										>
											Home
										</Link>
									</li>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize.xs }}
											to="/about"
										>
											About Us
										</Link>
									</li>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize.xs }}
											to="/blogs"
										>
											Feed/ Blog
										</Link>
									</li>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize.xs }}
											to="/earnmoney"
										>
											Earn Money
										</Link>
									</li>
								</ul>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3}>
								<Typography
									variant="h1"
									marginTop="4.9rem"
									sx={{ ...responsiveFontSize, ...forMargin }}
									lineHeight={"2.5"}
									fontWeight={600}
									color={"#fff"}
								>
									About Us
								</Typography>
								<ul
									className=""
									style={{
										listStyle: "none",
										paddingLeft: "0",
										lineHeight: "1.8",
									}}
								>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize }}
											to="/career"
										>
											Career
										</Link>
									</li>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize }}
											to="/contactus"
										>
											Contact Us
										</Link>
									</li>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize }}
											to="/policy"
										>
											Privacy Policy
										</Link>
									</li>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize }}
											to="term"
										>
											Terms & Conditions
										</Link>{" "}
									</li>
									<li>
										<Link
											style={{ textDecoration: "none", color: "#fff" }}
											sx={{ ...responsiveFontSize }}
											to="/refund"
										>
											Refund Policy
										</Link>
									</li>
								</ul>
							</Grid>
							<Grid item xs={6} sm={6} md={3} lg={3}>
								<FadeCarousel marginTop="2rem" />
							</Grid>
						</Grid>
						<Typography
							style={{ textAlign: "center", color: "#fff", marginTop: "4rem" }}
						>
							&copy; 2024, All Right Reserved. Designed By Sudo24
						</Typography>
					</Container>
				</div>
			</footer>
		</>
		// End
	);
};

export default Footer;
