import { combineReducers, configureStore } from "@reduxjs/toolkit";
import  Cart  from "./reducer";

 const rootReducer = combineReducers({
    Cart:Cart,
    Cart1:Cart,
 })

const store =  configureStore({
  reducer: { cart : rootReducer,

},

}) 

export {store}