import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Container, Input, Stack, Typography } from '@mui/material'
import React from 'react'
import img from '../../../assets/hero1.png'
import LineChart from '../../../component/Chart/LineChart'



const YearlyData = () => {

    const earn =[
        {id:1, title:'Current Month', earning:'0.00'},
        {id:2, title:'Total Enrolled', earning:'0.00'},
        {id:3, title:'Repeat Student', earning:'0.00'},
        {id:4, title:'Last Student', earning:'0.00'},
    ]
  return (
    <>
    <Box mt={{md:3,xs:0}}>
    <Typography
    position='sticky'
    component='div'
    variant='subtitle1'>
     <ArrowBack/>
     Monthly Data
    </Typography>
    <Box sx={{mt:3}}>
    {/* <LineChart chartData={chartData} /> */}
     <LineChart/>
     <LineChart/>
     <LineChart/>
     <LineChart/>
     <LineChart/>
    </Box>

    </Box>
    </>
  )
}

export default YearlyData