import { Box, Card, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CarouselNav from '../component/slider/CarosalNav'
import ContainerCard from '../component/common/ContainerCard'
import { CalendarMonthOutlined, ChatRounded, CheckCircle, DiamondOutlined, Face2Outlined, Face2Rounded, Face3Rounded, ListAltTwoTone, LocationOffOutlined, LocationSearchingOutlined, MessageOutlined, Person2Outlined, StackedBarChartOutlined, WindowOutlined, WindowSharp } from '@mui/icons-material'
import WhyTeachSection from '../component/BecomeTeacher/WhyTeachSection'
import Specialist from '../section/Specialist'
import { listData, listProduct } from '../data/listproduct'
import Faq from '../component/common/Faq'
import { Expectation, processes, whyTeach , faq} from '../data/becometeacher'
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'

const BecomeaTeacher = () => {

   
    const [isLoding, setisLoding] = useState(true)
    useEffect(() => {
      setTimeout(() => {
        setisLoding(false)
      }, 1000);
    
    },[])
    
  if(isLoding){
    return<Loading/>
  }
  return (
    <>
    <Helmet>
      <title>Become Teacher</title>
    </Helmet>
    <Grid sx={{ backgroundImage: 'linear-graidient( red, yelllow)', m: 0, p: 0, overflowX: 'clip', display: 'block', mb: { lg: 80, md: 50, xs: 10 } }} container>

          <div className='relative my-6 flex'>


              <div className='left-[5vw] z-10 top-[10vh] w-96 absolute h-[60vh] overflow-hidden  '>
                  <h1 className='text-3xl text-white mb-14 font-bold '>
                      Teaching in the internet Age Means We Teach <span className='text-green-700'>Tommorrow's </span>
                      <span className='text-pink-500'>skill Today</span>
                  </h1>
               
              
              </div>

              <div className=' h-[40vh] overflow-x-hidden  flex'>
                  <CarouselNav />
              </div>

          </div>



      <ContainerCard>
            <WhyTeachSection title='Why Teach with Sudo24?' items={whyTeach}/>
          </ContainerCard>
          <Card elevation={0} sx={{bgcolor:'transparent', py:3, my:5}} >
            <Typography 
            fontWeight={600}
             variant='h5' 
             mb={3}
            textAlign='center' component='div'>
            Earning Potential
            </Typography>
            <Stack direction={{sm:'row',xs:'column' }} justifyContent='center' alignItems='center' rowGap={2} gap={5}>
               <Box>
                <Typography component='div'
                variant='subtitle1'>
                    From Minimum
                </Typography>
                <Typography component='div'
                variant='h3'>
                    25,000
                </Typography>
                <Typography component='div'
                variant='subtitle1'>
                    per month @ 4h/day
                </Typography>
               </Box>
               <Typography 
               component='div'
               variant='h4' children='<'>
              
               </Typography>
               <Box>
                <Typography component='div'
                variant='subtitle1'>
                    From Minimum
                </Typography>
                <Typography component='div'
                variant='h3'>
                    25,000
                </Typography>
                <Typography component='div'
                variant='subtitle1'>
                    per month @ 4h/day
                </Typography>
               </Box>
            </Stack>
          </Card>
      <ContainerCard>
            <WhyTeachSection title='Expectations from a Teacher on Sudo24' items={Expectation}/>
          </ContainerCard>
        
          <Specialist heading={'Our Specilist'} listProduct={listProduct}/>


      <ContainerCard>
            <WhyTeachSection items={processes}/>
          </ContainerCard>
          <Specialist heading={'What Students Say'} listProduct={listData}/>
          <Container  maxWidth='lg'>
            <Typography m={2} component='div'
            textAlign='center'
            fontWeight={700}
            variant='h4'>
                FAQ'S
            </Typography>
            <Faq  accorditions={faq}/>
          </Container>
      </Grid>
      
          </>
  )
}

export default BecomeaTeacher