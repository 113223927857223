import { Grid, IconButton, Typography } from '@mui/material'
import React from 'react'

const WhyTeachSection = ({title, items}) => {
  return (
    <Grid container>
  { title? <Grid item md={4} xs={12}>
        <Typography color='red' component='div' 
        fontWeight={700}
        variant='h4'>
          {title}
        </Typography>
    </Grid>:''}
   {items.map((item, key)=>
    <Grid item md={4} xs={12}>
        <IconButton>
            {item.icon}
        </IconButton>
        <Typography component='div'
        fontWeight={550} 
        variant='h6'>
          {item.title}
        </Typography>
        <Typography component='div'
        variant='body2'>
          {item.desc} 
        </Typography>
    </Grid>)}
 </Grid>
  )
}

export default WhyTeachSection