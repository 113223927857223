import { ArrowBack, MenuBook, MoreVertRounded, VerticalAlignTop, VerticalShadesRounded } from '@mui/icons-material'
import { Box, Card, Checkbox, Grid, InputBase, Menu, Stack, Switch } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const YourPromo = () => {
    const card=[
        0,1,1,1,1,1,1,1,11,1,11,1,1,11,1,11,1,1,1,1,1,11,1,1,11,1,1,1,1,1
    ]
  return (
    <Box my={{md:2,xs:1}} py={1} mx={1} boxShadow={5}>
        <Stack direction='row'  justifyContent='space-between'>
            <Link to='/dashboard'>
         <ArrowBack/> 
    </Link>       
             <Box bgcolor='lightgrey' borderRadius={1}>
                <InputBase sx={{bgcolor:'white',m:0.5,borderRadius:1}}/>
             </Box>
             <MoreVertRounded/>
        </Stack>
        <Box>

     <Grid my={3} rowGap={2} container>
        {card.map((card, key)=>

        <Grid  px={1} xs={6} item lg={4}>
            <Card >
            <Box position='relative' height={'100px'} width={'100%'}>
            <Checkbox 
             sx={{position:'absolute', right:'0px', top:'0px'}} 
             defaultChecked />
                {/* <Check /> */}
            </Box>
            </Card>
        </Grid>
            )}
      
      
     </Grid>
        </Box>
    </Box>
  )
}

export default YourPromo