import { Card, CardMedia } from "@mui/material";
import React from "react";
import hero1 from "../../assets/hero1.png";

const ImgCard = ({ src }) => {
	return (
		<>
			<Card
				elevation={0}
				sx={{
					display: "contents",
					mx: 2,
					height: "100px",
					width: "100px",
					bgcolor: "transparent",
				}}
			>
				<CardMedia
					component="img"
					sx={{
						objectFit: "fill",
					}}
					src={src}
				/>
			</Card>
		</>
	);
};

export default ImgCard;
