import { AccountBalance, AccountBalanceRounded, Facebook, FactCheck, Google, Person } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Container, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import img from '../assets/hero1.png'
import VisuallyHiddenInput from '../component/common/VisuallyHiddenInput'
import { useNavigate } from 'react-router-dom'

const TeacherRegistration = () => {
  const navigate = useNavigate()
    const [Password, setPassword] = useState('')
    const [CPassword, setCPassword] = useState('')
    const [Active, setActive] = useState(false)
    const [Activec, setActivec] = useState(false)

    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

 const handleRegister =()=>{
  navigate('/addressverify')

 }

  return (
    <div>
    <Container sx={{mt:5}} maxWidth='lg'>
      <Box sx={{ 
        boxShadow:'10',
         
      }}>
        <Grid direction={{md:'row', xs:'column-reverse'}} container >
          <Grid item xs={12} md={6}>
          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}}>
          <Typography sx={{mb:5}} component='h2'>
            Sign Up For Teacher
          </Typography>
          <Grid container>
            <Grid item lg={7}>
              
            
          <TextField
           sx={{width:'100%',
          mb:2}}
           required
           id="outlined-required"
           label="Name"
           defaultValue="Enter Your Name"
           />
          <TextField
          
           sx={{width:'100%',
          mb:2}}
           required
           id="outlined-required"
           label="Email "
           defaultValue="Enter Your Email"
           />
          <TextField
           sx={{width:'100%',
          mb:2}}
           required
           id="outlined-required"
           label="Mobile No"
           defaultValue="Enter Your Mobile No"
           />
           </Grid>
           <Grid display='flex' item lg={5}>
             <Button
               component="label"
               role={undefined}
               
            
               tabIndex={-1}
               sx={
                 {
                 mb:'10%',
                 mx:'5%',
                   width:'90%',
                   height:'90%'
                 }}
               
               >
            <Card
            >
          {
            file ? <CardMedia
            component='img'
            alt='sbbbbr'
            src={file}
            sx={{
              height:'100%',
              objectFit:'cover'
            }}
            />
:
            <Person
             sx={{
              width:'150px',
              height:'150px'
            }} 
            />
          } 
             
              <VisuallyHiddenInput onChange={handleChange} type='file'/>
            </Card>
             </Button>
            </Grid>
          </Grid>
           <Box display='flex' justifyContent={'space-between'} sx={{mb:2}} >

          <TextField
          sx={{width:'45%'}}
          
             required
             id="outlined-required"
             label="DOB"
             defaultValue=""
            placeholder=''
             type='date'
             />
          <TextField
            sx={{width:'45%'}}
             required
             id="outlined-required"
             label="Father"
             
             defaultValue=" "
           
             
             />
           </Box>
           <Stack sx={{mb:2}}>
           <TextField
           required
            rows={3}
            placeholder='Addresss'
            label='Address'
            >

           </TextField>
           </Stack>
           <Box display='flex' justifyContent={'space-between'} sx={{mb:2}} >

<TextField
sx={{width:'45%'}}

   required
   id="outlined-required"
   label="Country"
   defaultValue=""
  placeholder=''
  
   />
<TextField
sx={{width:'45%'}}

   required
   id="outlined-required"
   label="State"
   defaultValue=""
  placeholder=''
  
   />

 </Box>
 <Box display='flex' justifyContent={'space-between'} sx={{mb:2}} >

<TextField
sx={{width:'45%'}}

   required
   id="outlined-required"
   label="District"
   defaultValue=""
  placeholder=''
  
   />

<TextField
sx={{width:'45%'}}

   required
   id="outlined-required"
   label="Zipcode"
   defaultValue=""
  placeholder=''
  
   />

 </Box>
           <Box display='flex' justifyContent={'space-between'} gap={3}>
            <Box sx={{width:'45%'}}>


          <TextField
         
             required
             id="outlined-required"
             label="Password"
             defaultValue="Enter Your Password "
             onChange={()=>setActive(true)}
            
             />
         {Active? <Typography color={'red'} fontSize={10} component={'p'}>
          Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character
          </Typography>:''}
             </Box>
             <Box  sx={{width:'45%'}}>

          <TextField
          
          required
          id="outlined-required"
          label="Confirm Password"
          defaultValue=" Confirm Password "
          onChange={()=>setActive(true)}
       
          />
           {Activec? <Typography color={'red'} fontSize={10} component={'p'}>
          Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character
          </Typography>:''}
        </Box>
          </Box>
        <Stack>
        
        </Stack>
        
        <Button onClick={handleRegister} variant='contained' size='large' sx={{
          width:'100%',
          mt:2
        }} color='error'>
          Register
        </Button>
        <Box display='flex' justifyContent='space-between'>
         <Button variant='text'>
           Have an Account
         </Button>
         {/* <Button variant='text'>
           Forgot Username
         </Button> */}

        </Box>
        {/* <Divider>OR</Divider>
        <Stack sx={{mt:1}} rowGap={1.5}>
          <Button startIcon={<Facebook/>} variant='contained'>
           login via Truecaller
          </Button>
          <Button color='error' startIcon={<Google/>} variant='contained'>
           login via Google
          </Button>
          <Button color='info' startIcon={<Facebook/>} variant='contained'>
           login via Facebook
          </Button>
        </Stack> */}

        </Box>

      </Card>
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <CssCarousal>
                      {
[0,1,3].map((key)=>
<SwiperSlide key={key}>
<CardMedia
  component="img"
  alt="Contemplative Reptile album cover"
  src={ img}
  sx={{
    position:'relative',
      p:0,
      my:2,
      height:'100%',
      width: { xs: '100%', sm: '100%' },
    borderRadius: 2,
    ":hover":{
      transitionTimingFunction:'ease-in-out',
      backgroundColor:'blueviolet'
    }
  }}
/>
</SwiperSlide>
)}

                      </CssCarousal>
                    </Grid>
                  </Grid>
      <Card>

      </Card>
      </Box>
    </Container>
  </div>
  )
}

export default TeacherRegistration