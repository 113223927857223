import { HomeOutlined } from "@mui/icons-material"
const iconStyle={
    fontWeight:800
}
const topics =[
    {id:1, icon:<HomeOutlined sx={iconStyle} />, title:'How To'},
    {id:1, icon:<HomeOutlined sx={iconStyle}/>, title:'How To'},
    {id:1, icon:<HomeOutlined sx={iconStyle}/>, title:'How To'},
    {id:1, icon:<HomeOutlined sx={iconStyle}/>, title:'How To'},
    {id:1, icon:<HomeOutlined sx={iconStyle}/>, title:'How To'},
    {id:1, icon:<HomeOutlined sx={iconStyle}/>, title:'How To'},
   
   
]
const accorditionse =[
  {id:1 , title :'Accordition 1'},
  {id:2 , title :'Accordition 2'},
  {id:3 , title :'Accordition 3'},
  {id:4 , title :'Accordition 4'},
  {id:5 , title :'Accordition 5'},
  {id:6 , title :'Accordition 6'},
]

export {topics, accorditionse}