import { Box, Grid, Link, Stack, Typography } from '@mui/material'
import React from 'react'

const RefundPolicy = () => {
  return (
    
    <Grid sx={{mt:10, mb:50, mx:3}} container>
        <Box p={2} borderRadius={5} boxShadow={3}>
           <Stack>
            <Typography textAlign={{sm:'left', xs:'center'}} variant='h4' sx={{mb:1}} component='div'>
           Refund Policy
            </Typography>
            <Typography variant='p' component='div'>
                 
            We understand that sometimes things don't work out, and we want to make sure that you're happy with your purchase. If you're not satisfied with your sudo24 subscription, you can request a refund within 30 days of purchase.
            <br/>

            <br/>
To request a refund, please contact our customer support team at info@sudo24.com. We'll need your order number and the reason for your request.
            <br/>
            <br/>

Once we've received your request, we'll review it and get back to you within 2 business days. If your request is approved, we'll issue a refund to the original payment method.

                </Typography>
                </Stack>
           <Stack>
            <Typography variant='h4' sx={{mb:1}} textAlign={{sm:'left', xs:'center'}}  component='div'>
           
            Exceptions
            </Typography>
            <Typography variant='p' component='div'>

* There are a few exceptions to our refund policy:
Subscriptions that have been used for more than 30 days are not eligible for a refund.
<br/>


* Subscriptions that have been cancelled by the customer are not eligible for a refund.
<br/>


* Subscriptions that have been purchased through a third-party retailer are not eligible for a refund..
<br/>
<br/>
                </Typography>
                </Stack>
           <Stack>
            <Typography textAlign={{sm:'left', xs:'center'}}  variant='h4' sx={{mb:1}} component='div'>
           
            Contact Us
           
            </Typography>
            <Typography variant='p' component='div'>

If you have any questions about our refund policy, please contact our customer support team at <Link>info@sudo24.com </Link>we’re here to help!
                </Typography>
                </Stack>
                </Box>
                </Grid>
             
  )
}

export default RefundPolicy