import {
	Card,
	CardContent,
	CardHeader,
	CardActions,
	Avatar,
	IconButton,
	Typography,
	Divider,
	Badge,
	Paper,
	Box,
} from "@mui/material";
import {
	ThumbUp,
	ThumbDown,
	ChatBubbleOutline,
	Share,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const Post = ({ post }) => {
	const { user, content, likes, comments, shares } = post;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<>
			<Box
				style={{
					display: "flex",
					marginTop: "16px",
					backgroundColor: "transparent",
				}}
				sx={{ justifyContent: isMobile ? "center" : "left" }}
			>
				<Card
					style={{
						width: "70%",
						minWidth: "300px",
						position: "relative",
						backgroundColor: "transparent",
					}}
				>
					{/* Header - User Info */}
					<CardHeader
						avatar={<Avatar alt={user.name} src={user.avatarUrl} />}
						title={user.name}
						subheader={`Posted on ${post.date}`}
					/>

					{/* Post Content */}
					<CardContent>
						<Typography variant="body1">{content}</Typography>
					</CardContent>

					{/* Divider */}
					<Divider />

					{/* Actions - Like, Comment, Share */}
					<CardActions disableSpacing>
						<IconButton aria-label="like">
							<Badge badgeContent={likes} color="primary">
								<ThumbUp />
							</Badge>
						</IconButton>
						<IconButton aria-label="dislike">
							<ThumbDown />
						</IconButton>
						<IconButton aria-label="comment">
							<Badge badgeContent="4" color="primary">
								<ChatBubbleOutline />
							</Badge>
						</IconButton>
						<IconButton aria-label="share">
							<Badge badgeContent={shares} color="primary">
								<Share />
							</Badge>
						</IconButton>
					</CardActions>

					{/* Divider */}
					<Divider />
				</Card>

				{/* Sidebar - Top Communities */}
				{!isMobile && comments && (
					<Paper
						style={{
							position: "sticky",
							marginLeft: "10px",
							padding: "10px",
							width: "30%",
							backgroundColor: "transparent",
						}}
					>
						<Typography variant="h6" gutterBottom>
							Top Comments
						</Typography>
						{comments.slice(0, 5).map((comment, index) => (
							<Typography key={index} variant="body2" gutterBottom>
								<strong>{comment.user}:</strong> {comment.text}
							</Typography>
						))}
					</Paper>
				)}
			</Box>
		</>
	);
};

export default Post;
