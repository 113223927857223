import React, { useState } from "react";
import img from "../logo.svg";
import {
	Avatar,
	Box,
	Button,
	Card,
	CardMedia,
	Divider,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import {
	CalendarMonth,
	Download,
	Facebook,
	FastForwardRounded,
	FastRewindRounded,
	Instagram,
	LockClockOutlined,
	PauseRounded,
	Person,
	PlayArrowRounded,
	VideoCallOutlined,
	YouTube,
} from "@mui/icons-material";
import CssCarousal from "./slider/CssCarousal";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";

const ProductCard = ({ product, button, button1, button2, courses }) => {
	const [paused, setPaused] = useState(false);

	//  const [buttonActive, setbuttonActive] = useState(true)
	const title = (e) => {
		let text = e;

		if (e.length > 11) {
			text = `${e.substring(0, 20)}...`;
		}
		return (
			<Tooltip title={e}>
				<Typography component="div" variant="h6">
					{text}
				</Typography>
			</Tooltip>
		);
	};

	//  setbuttonActive(button)
	const img = [0, 1];
	return (
		<Box
			sx={{
				":hover": {
					boxShadow: 20,
				},

				my: 0,
				mx: 1,
				height: "100%",
			}}
		>
			<Card
				variant="elevation"
				elevation={0}
				sx={{
					my: 1,
					mx: 1,
					height: "100%",
					padding: 0.2,
					overflow: "visible",
					display: "flex",
					bgcolor: "transparent",
					position: "relative",
					flexDirection: { xs: "column", sm: "column" },
					alignItems: "flex-start",
				}}
			>
				{product.img ? (
					<>
						<Grid container>
							<CssCarousal>
								{img.map((key) => (
									<SwiperSlide key={key}>
										<CardMedia
											component="img"
											alt="Contemplative Reptile album cover"
											src={product.img ? product.img : img}
											sx={{
												position: "relative",
												p: 0,
												columnGap: 2,
												height: "150px !important",
												width: { xs: "100%", sm: "100%" },
												borderRadius: 2,
												":hover": {
													transitionTimingFunction: "ease-in-out",
													backgroundColor: "blueviolet",
												},
											}}
										/>
									</SwiperSlide>
								))}
							</CssCarousal>
						</Grid>
					</>
				) : (
					""
				)}
				<div className="grid text-left mx-3  w-11/12 ">
					<div>{product.text}</div>
					<Box display={"flex"} justifyContent="space-between">
						<div className=" font-extrabold text-2xl">
							{product.title ? title(product.title) : ""}
						</div>
						<div>
							{product.price ? (
								<Avatar
									variant="rounded"
									sx={{ bgcolor: "green", color: "white" }}
								>
									<p>{product.price}</p>
								</Avatar>
							) : (
								""
							)}
						</div>
					</Box>
					{product.desc ? (
						<div className="text-slate-500 flex gap-3">
							<>
								<CalendarMonth />
								<span> {product.desc}</span>
								<Box>jjj</Box>
							</>
						</div>
					) : (
						""
					)}
					{product.know ? (
						<Stack direction="row" justifyContent="space-between">
							<Stack>
								<Typography component="div" variant="subtitle2">
									Know more
								</Typography>
							</Stack>
							<Stack gap={1} direction="row">
								<Facebook />
								<Instagram />
								<YouTube />
							</Stack>
						</Stack>
					) : (
						""
					)}
					{courses ? (
						<>
							<Divider sx={{ mt: 3 }} className="mt-5" />
							<Grid
								fontSize={15}
								alignItems="center"
								sx={{ mx: { md: 1.5, xs: 0 }, my: 1 }}
								container
							>
								<Grid item xs={4}>
									<LockClockOutlined fontSize="25" />
									3hr 5min
								</Grid>
								<Grid item xs={4}>
									<VideoCallOutlined fontSize="25" />
									34 courses
								</Grid>
								<Grid item xs={4}>
									<Download fontSize="25" />
									3,45,345
								</Grid>
							</Grid>
						</>
					) : (
						""
					)}
					{product.text ? (
						<Grid
							display="flex"
							alignItems="center"
							sx={{ mx: 1.5, mt: 1.5 }}
							justifyContent="space-between"
							container
						>
							<div>
								<Avatar>
									<Person />
								</Avatar>
							</div>
							<Box display="flex" alignItems="center" sx={{ my: 1.5 }}>
								{[...Array(5)].map((_, index) => (
									<StarIcon
										key={index}
										sx={{
											color: index < product.rating ? "#ffb400" : "#e0e0e0",
										}}
									/>
								))}
							</Box>
						</Grid>
					) : (
						""
					)}
				</div>

				{button ? (
					<Grid
						container
						direction="row"
						position="absolute"
						bottom={-20}
						justifyContent="space-between"
					>
						<Link to={`/courses/${product.id}`}>
							<Button
								sx={{
									mt: 2,
									boxShadow: 2,
									color: "#000",
									":hover": {
										boxShadow: 8,
									},
								}}
							>
								{button1 ? button1 : "Explore Course"}
							</Button>
						</Link>
						<Link to={`/find`}>
							<Button
								sx={{
									mt: 2,
									boxShadow: 2,
									color: "#000",
									":hover": {
										boxShadow: 8,
									},
								}}
							>
								{button2 ? button2 : "Find Teachers"}
							</Button>
						</Link>
					</Grid>
				) : (
					""
				)}
			</Card>
		</Box>
	);
};

export default ProductCard;
