import { Title } from '@mui/icons-material'
import { Box, Card, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'

const FrenchiseCard = ({img, title, children, direction, component}) => {
  return (
    <Grid px={2} py={2}  color='green' rowGap={1} boxShadow={{lg:0 , xs:4}} direction={direction} mt={{md:4,xs:0}} container>
            <Grid item textAlign='left'  justifyContent={{xs:'center',lg:'left'}} xs={12} md={6}>
            <Typography textAlign={{lg:'left', xs:'center'}} mb={{lg:1.5, xs:2}}  mr={{lg:3,xs:0}} component='div' fontWeight={800} variant='h3'>
                {title}   
            </Typography>
            {children}
            </Grid>
            <Grid display={{xs:'contents', md:'block'}} item  xs={12} md={6}>
           
             
              <Card elevation={0} sx={{bgcolor:'transparent',mx:'auto',height:'100%', width:'inherit'}}>
           {img? <CardMedia
            component='img'
            src={img}
            alt='image'
            sx={{objectFit:'cover'}}
            />:component}
            </Card>
          
            </Grid>
        </Grid>
  )
}

export default FrenchiseCard