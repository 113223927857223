import React, { useEffect, useState } from 'react'
import CarouselNav from '../component/slider/CarosalNav'
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { Forward10Sharp } from '@mui/icons-material'
import BecomeStudentCard from '../component/BeomeStudent/BecomeStudentCard'
import { Exams, studyTools } from '../data/becomeStudents'
import { Helmet } from 'react-helmet'
import Loading from '../component/common/Loading'
const BecomeStudent = () => {
  
  const [isLoding, setisLoding] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setisLoding(false)
    }, 1000);
  
  },[])
  
if(isLoding){
  return<Loading/>
}


  return (
    <>
    <Helmet>
      <title>
        Become Student
      </title>
    </Helmet>
     <Grid sx={{ backgroundImage: 'linear-graidient( red, yelllow)', m: 0, p: 0, overflowX: 'clip', display: 'block', mb: { lg: 80, md: 50, xs: 10 } }} container>

<div className='relative my-6 flex'>


    <div className='left-[5vw] z-10 top-[10vh] w-96 absolute h-[60vh] overflow-hidden  '>
        <h1 className='text-3xl text-white mb-14 font-bold '>
            Teaching in the internet Age Means We Teach <span className='text-green-700'>Tommorrow's </span>
            <span className='text-pink-500'>skill Today</span>
        </h1>
     
    
    </div>

    <div className=' h-[40vh] overflow-x-hidden  flex'>
        <CarouselNav />
    </div>

</div>
     
     <Card elevation={0}  sx={{bgcolor:'transparent'}}>
          <Stack textAlign='center' my={2} direction={{sm:'row', xs:'column'}} justifyContent='space-evenly'>
             <Typography
              component='div' 
              fontWeight={700}
              variant='h5'
              >
             Expert learning support
             </Typography>
             <Box display={{sm:'flex',xs:'block'}} rowGap={5} gap={2}>
               <Typography component='div' 
               fontWeight={650}
               variant='h6'>
                starting at
                <br/>
                ₹49.00/month
               </Typography>
               <Box>

               <Button 
               sx={{boxShadow:5, py:1, my:'auto'}}
               >
                SIGN UP
               </Button>
                 </Box>
             </Box>
            </Stack>
     </Card>
     <Box my={4} py={3} boxShadow={0}>
     <Typography 
     textAlign='center'
     component='div'
     variant='h3'
     fontWeight={800}>
      Exam prep built for finals
     </Typography>
     <Typography 
     textAlign='center'
     component='div'
     fontWeight={700}
     variant='body1'>
        24/7 study help for upcoming tests & writing assignments
     </Typography>
      <Grid container>
      {Exams.map((exam , key)=>
      <Grid key={key} item md={4}>
      <BecomeStudentCard data={exam}/>
      </Grid>
      )}
      </Grid>
     </Box>
     <Stack my={6} rowGap={2} textAlign='center'>
      <Typography component='div'
      variant='h6'>
        Begin your journey from as low as ₹49/month, with the freedom to cancel anytime

      </Typography>
      <Box>

      <Button  sx={{boxShadow:5,px:5}}>
         Sign UP
      </Button>
      </Box>
      <Typography mt={{md:6, xs:4}} component='div'
      color='orangered'
      fontWeight={700}
      variant='h4'
      >
      94% of Sudo24 students say they get better grades when they use Sudo24 to understand their coursework

      </Typography>

     </Stack>
     <Box sx={{textAlign:'center'}}>
      <Typography component='div'
      variant='h3'>
        Study tools for your tough courses
      </Typography>
      <Grid container>

        {studyTools.map((studyTool, key)=>
        <Grid item key={key} lg={3} sm={6}xs={12}>
          <Box>

        <BecomeStudentCard data={studyTool}/>
          </Box>
        </Grid>
        )}
      </Grid>
     </Box>
     </Grid>
    </>
  )
}

export default BecomeStudent