// This code is used to trigger Counter animation in AnimatedStats

import { useState, useEffect, useRef } from "react";

const useIntersectionObserver = (options) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef();

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			setIsVisible(entry.isIntersecting);
		}, options);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, [options]);

	return [ref, isVisible];
};

export default useIntersectionObserver;
