import { AccountBoxTwoTone, Facebook, FactCheck, Google, QuestionMark } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardMedia, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import img from '../assets/hero1.png'
import {  useNavigate } from 'react-router-dom'

const UserType = () => {
   const [disable, setdisable] = useState(true)
    const [type, setType] = useState('');
    const [page, setPage] = useState()
  const navigate = useNavigate()
    
    
      const handleClick= ()=>{
        page =='login'?
       type =='student'?
          navigate('/login') 
       :
         navigate('/prefrences') 
       
        :navigate('/prefrences')

      }
    const handleChange = (event) => {
      setType(event.target.value);
      setPage(localStorage.getItem('page'))
      localStorage.setItem('_role', event.target.value)  
    };
  return (
    
    <Container sx={{mt:5}} maxWidth='lg'>
      <Box sx={{ 
        boxShadow:'10',
         
      }}>
        <Grid direction={{md:'row', xs:'column-reverse'}} container >
          <Grid item xs={12} md={6}>
          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}}  rowGap={3}>
            <Stack sx={{mb:3}}>
                <Typography fontWeight={600} fontSize={30}  component='h1'>

               Select  Role
                </Typography>
            </Stack>

        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">I am ? </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={type}
    label="?"
    placeholder='I am ?'
    onChange={handleChange}
  >
    <MenuItem value='teacher'>I am a Teacher</MenuItem>
    <MenuItem value='student'>I am Student</MenuItem>
  
  </Select>
  <Box display='flex' justifyContent='center' >

  {type ?<Button  variant='contained'
  onClick={handleClick}
  size='large' sx={{
          
          my:5
        }} color='secondary'>
          Proceed
        </Button>
        :
        <Button disabled variant='contained' size='large' sx={{
        
        my:5
      }} color='secondary'>
        Proceed
      </Button>
       }   

         </Box>
</FormControl>
     <Box display='flex' justifyContent='center'>

       <Avatar  sx={{height:100, width:100}}>
        {type? <AccountBoxTwoTone />:<QuestionMark fontSize='100px'/> }
       </Avatar>
          
     </Box>

        </Box>

      </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <CssCarousal>
                      {
[0,1,3].map((key)=>
<SwiperSlide key={key}>
<CardMedia
  component="img"
  alt="Contemplative Reptile album cover"
  src={ img}
  sx={{
    position:'relative',
      p:0,
      my:2,
      width: { xs: '100%', sm: '100%' },
    borderRadius: 2,
    ":hover":{
      transitionTimingFunction:'ease-in-out',
      backgroundColor:'blueviolet'
    }
  }}
/>
</SwiperSlide>
)}

                      </CssCarousal>
                    </Grid>
                  </Grid>
      <Card>

      </Card>
      </Box>
    </Container>
 
  )
}

export default UserType