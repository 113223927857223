import { Box, Card, CardMedia, Container, Grid, IconButton, ImageListItem, Stack, Typography } from '@mui/material'
import React from 'react'
import { hero1 as img } from '../assets'
import { ArrowDownward, ArrowForward, CallMade, CircleOutlined, HealthAndSafetySharp, Home, LeakRemoveOutlined, OpenInBrowserOutlined, PhoneForwardedTwoTone, Star } from '@mui/icons-material'
import Faq from '../component/common/Faq'
import ImgCard from '../component/common/ImgCard'
import Heading from '../component/common/Heading/Heading'
import FrenchiseCard from '../component/joinwithus/FrenchiseCard'
import RevolutionCard from '../component/joinwithus/RevolutionCard'
import SliderCard from '../component/common/SltderCard'
import CssCarousal from '../component/slider/CssCarousal'
import { Swiper, SwiperSlide } from 'swiper/react'
import CarouselNav from '../component/slider/CarosalNav'
import VerticalCarousel from '../component/slider/VerticalCarousel'
const accordion =[
    {id:1, title:'ssffsf'},
    {id:2, title:'ssffsf'},
    {id:3, title:'ssffsf'},
    {id:4, title:'ssffsf'},
    {id:5, title:'ssffsf'},
    {id:6, title:'ssffsf'},
]



const slides = [
    <div>Slide 1</div>,
    <div>Slide 2</div>,
    <div>Slide 3</div>,
  ];
const EarnGrid =
   
     <Grid container>
        <Grid xs={6} justifyContent='space-between' item lg={6}>
            <Stack gap={7}>

            <Box py={2 } mx={3} borderRadius={3} boxShadow={8}>
              <Typography textAlign='center' component='div' variant='h4'>
                Plan
              </Typography>
            </Box>
            <Box py={2 } mx={3} borderRadius={3} boxShadow={8}>
              <Typography textAlign='center' component='div' variant='h4'>
                Adapt 
               </Typography>
            </Box>
            <Box py={2 } mx={3} borderRadius={3} boxShadow={8}>
              <Typography textAlign='center' component='div' variant='h4'>
                Improve 
               </Typography>
            </Box>
          
            </Stack>
        </Grid>
        <Grid display='flex' xs={6} item lg={6}>
         <Box  display='block'>

            <Card sx={{maxHeight:'250px', height:'100%', width:'100%', display:'flex', justifyContent:'center'}}>
                
                <Grid  sx={{bgcolor:'red', height:'100px', width:'300px'}} container>
                    
                       {/* <VerticalCarousel slides={slides}>
                       {slides.map((slide, index) => (
                       <SwiperSlide key={index}>
                        <Box  display="flex" alignItems="center" justifyContent="center">
                        {slide}
                              </Box>   
                          </SwiperSlide>
                               ))}
     
                       </VerticalCarousel> */}

                    {/* <CssCarousal vertical   slide={3}>
                        <SwiperSlide >
                         <ArrowForward/>
                         1
                        </SwiperSlide>
                        <SwiperSlide >
                         <ArrowForward/>
                         2
                        </SwiperSlide>
                        <SwiperSlide >
                         <ArrowForward/>
                         3
                        </SwiperSlide>
                        <SwiperSlide >
                         <ArrowForward/>
                         3
                        </SwiperSlide>
                        <SwiperSlide >
                         <ArrowForward/>
                         3
                        </SwiperSlide>
                         
                        
                     
                       
                    </CssCarousal> */}
                   
                </Grid>
            </Card>
         </Box>

         
            
         

        </Grid>
     </Grid>


const JoinwithUs = () => {
  
  const colaborates=[
    {title:'Schedule a meeting', desc:'Become a Franchise Partner and embark on an exciting journey'},
    {title:'Partnership Opportunities Dialogue', desc:'Engage in insightful discussions about partnership opportunities'},
    {title:'Shaping Education', desc:'Together, we can shape the future of education in your state'}
  ]
  const earningModal=[
    {title:'plan', desc:'Increase in number of products'},
    {title:'Adapt', desc:'A transparent and rewarding earning model for all stakeholders'},
    {title:' Improve', desc:'Profit from course sales while impacting students lives'}
  ]
    const Revolution =[
        {id:1,icon :<HealthAndSafetySharp/>, title:'Transformative Opportunities', desc:'Enroll. Empower.  Sell . Impact'},
        {id:1,icon:<HealthAndSafetySharp/>, title:' Earning Potential', desc:'Profit. Success. Financial Growth.'},
        {id:1,icon :<HealthAndSafetySharp/>, title:' Reshaping Education', desc:'Innovate. Empower.Revolutionize.'},
    ]
    const contact =[
        {id:1,icon :<Home sx={{color:'green'}}/>, title:'Location', desc:'H- 666, Noida, India, Uttar Pradesh'},
        {id:1,icon:<PhoneForwardedTwoTone  color='success'/>, title:'  Call', desc:'+91 8750753590'},
        {id:1,icon :<OpenInBrowserOutlined color='success'/>, title:'  Website', desc:'sudo24.com'},
    ]
    const levels =[
        {title:'Level1', subtitle1:'Quality'},
        {title:'Level2', subtitle1:'Experience'},
        {title:'Level3', subtitle1:'Affordable'}
    ]
  return (
    <Container sx={{mt:5, mb:5}} maxWidth='lg'>
    <Box sx={{ 
      boxShadow:'10',
       display :'flex',
       flexDirection:'column'
    }}>
       
      <Grid  mx={2} direction={{lg:'row',xs:'column-reverse'}} container >
      
        

        <Grid xs={12} display='flex' mt={{lg:0, xs:2}} justifyContent={{xs:'center'}} item lg={6}>
           

          
            <Box display='flex' flexDirection='column' justifyContent='center' >

            <Box sx={{display:'block', }}>
                {/* <Card> */}
               <Typography borderRadius={5} px={3}mt={1} bgcolor='#fff' color='green' component='span' variant='h2'>
                SUDO 24
                </Typography> 
          
            </Box>
               <Typography mx={2} mt={1} color='green' component='div' variant='body1'>
               Meta Version Of Digital
               <br/>
                 Education
                </Typography> 
            </Box>
        
            </Grid>
        <Grid xs={12} item lg={6}>
             <CardMedia 
             component='img'
             src={img}
             />
           
            </Grid>
        
    
        </Grid>
          
        <Grid alignItems='center' mt={2}  rowGap={1} justifyContent='center' textAlign='center' container>
            <Typography color='green' component='div' fontWeight={800} variant='h2'>
            Be a Part of the<br/>
          Revolution
            </Typography>
            <Typography color='green' width={{xs:'90%',md:'70%'}} component='div' variant='body1'>
            Joining Sudo24 empowers you to make a real difference in the education sector. By enrolling teachers, you contribute to building a strong network of educators, while selling courses allows you to share your expertise and empower students nationwide
            </Typography>
            
        </Grid>
        <Grid rowGap={2} mt={1} container>
            {Revolution.map((object, key)=>
            <Grid xs={12} key={key} justifyContent='center'textAlign='center' item md={4}>
              <RevolutionCard object={object}/>
            </Grid>
                )}
           
        </Grid>
           <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title='Embrace the Sudo24 Franchisee Program'
           img={img}

           >
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Become a Franchise Partner and embark on an exciting journey
            </Typography>
            <Typography  lineHeight={1.2} fontSize={22} my={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Experience the thrill of transforming lives through education
            </Typography>
            <Typography lineHeight={1.2} fontSize={22} mt={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Experience the thrill of transforming lives through education
            </Typography>
           </FrenchiseCard>
           <FrenchiseCard 
           direction={{lg:'row-reverse',xs:'column-reverse'}}
           title='Lets Collaborate and grow together!'
           img={img}

           >
            {colaborates.map((colaborate , key)=>
           
            <Box key={key}>

           <Typography lineHeight={1.2}  component='div' fontWeight={700} variant='h5'>
                <Star sx={{mr:1.5, color:'yellow'}}/>
                {colaborate.title}
            </Typography>
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={500} variant='subtitle1'>
              
            {colaborate.desc}
            </Typography>
            </Box>
             )}
            
           </FrenchiseCard>
           <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title='Embrace the Sudo24 Franchisee Program'
           img={img}

           >
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Become a Franchise Partner and embark on an exciting journey
            </Typography>
            <Typography  lineHeight={1.2} fontSize={22} my={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Experience the thrill of transforming lives through education
            </Typography>
            <Typography lineHeight={1.2} fontSize={22} mt={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Experience the thrill of transforming lives through education
            </Typography>
            <Stack textAlign='center' gap={4} direction={{sm:'row', xs:'column'}}>
                <Card elevation={0} sx={{bgcolor:'transparent'}}>
                    <CardMedia
                    component='img'
                    src={img}
                    sx={{objectFit:'cover'}}
                    />
                    <Typography component='div'
                    variant='h6'>
                        Increase in Teachers
                    </Typography>
                </Card>
                <Card elevation={0} sx={{bgcolor:'transparent'}}>
                    <CardMedia
                    component='img'
                    src={img}
                    sx={{objectFit:'cover'}}
                    />
                    <Typography component='div'
                    variant='h6'>
                        Increase in Students
                    </Typography>
                </Card>
            </Stack>
           </FrenchiseCard>
           <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title='Unlock Your Path to Success'
           img={img}

           >
           <Typography  lineHeight={1.2} fontSize={22} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Become a Franchise Partner and embark on an exciting journey
            </Typography>
            <Typography  lineHeight={1.2} fontSize={22} my={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Experience the thrill of transforming lives through education
            </Typography>
            <Typography lineHeight={1.2} fontSize={22} mt={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
            Experience the thrill of transforming lives through education
            </Typography>
           
           </FrenchiseCard>
        
     
        <Grid alignItems='center' rowGap={4} justifyContent='center' textAlign='center' container>
            <Typography component='div' fontWeight={800} variant='h2'>
            Explore a Universe of Learning
            </Typography>
            <Typography width={{xs:'90%',md:'70%'}} component='div' variant='subtitle1'>
            Joining Sudo24 empowers you to make a real difference in the education sector. By enrolling teachers, you contribute to building a strong network of educators, while selling courses allows you to share your expertise and empower students nationwide
            </Typography>
            
        </Grid>
            
        <Grid alignItems='center' mb={3}  rowGap={1} justifyContent='center' textAlign='center' container>
            <Typography color='green' mb={2} component='div' fontWeight={800} variant='h2'>
            Satisfaction Factors

          
            </Typography>
             <Stack textAlign='center' rowGap={3} gap={5} direction={{md:'row',xs:'column'}}>
                {levels.map((level , key)=>
               
                <Card key={key} elevation={2} sx={{bgcolor:'transparent'}}>
                    <CardMedia
                    component='img'
                    src={img}
                    sx={{objectFit:'cover'}}
                    />
                    <Typography fontWeight={750} component='div'
                    variant='h5'>
                       {level.title}
                    </Typography>
                    <Typography component='div'
                    variant='subtitle1'>
                      {level.subtitle1}
                    </Typography>
                </Card>
                 )}
            </Stack>
            
            
        </Grid>
        <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title='Expected Growth'
           img={img}

           >
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
                Increase in number of products
            </Typography>
            <Typography  lineHeight={1.2} fontSize={22} my={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
                Increase in profit

            </Typography>
            <Typography lineHeight={1.2} fontSize={22} mt={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
                Customer satisfaction and reviews.
            </Typography>
           </FrenchiseCard>
           <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title='Embrace the Earning Model'
           component={EarnGrid}

           >
            {earningModal.map((colaborate , key)=>
           
            <Box key={key}>

           <Typography lineHeight={1.2}  component='div' fontWeight={700} variant='h5'>
                <CircleOutlined sx={{mr:1.5, color:'yellow'}}/>
                {colaborate.title}
            </Typography>
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={500} variant='subtitle1'>
              
            {colaborate.desc}
            </Typography>
            </Box>
             )}
            
           </FrenchiseCard>
           <FrenchiseCard 
           direction={{lg:'row',xs:'column-reverse'}}
           title='Join the SUDO24 Movement '
           img={img}

           >
           <Typography lineHeight={1.2} fontSize={22} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
                Reflect on the transformative power of education

            </Typography>
            <Typography  lineHeight={1.2} fontSize={22} my={1} component='div' fontWeight={700} variant='subtitle1'>
                <CircleOutlined sx={{mr:1.5}}/>
                Empower learners, educators, and your state

            </Typography>
           
           </FrenchiseCard>
        <Grid alignItems='center' rowGap={4} justifyContent='center' textAlign='center' container>
            <Typography mx={{lg:4,md:2, xs:0}} mt={2} component='div' fontWeight={800} variant='h3'>
            FREQUENTLY ASKED
             QUESTIONS ABOUT SUDO24
            </Typography>
            <Faq accorditions={accordion}/>
            </Grid>
        
            <Grid my={2} rowGap={2} container>
                <Box mb={2} display='block'>
                <Typography textAlign='center' color='green' component='div' fontWeight={800} variant='h4'>
                WHY WAIT ? CONTACT US NOW AND GET ALL YOUR QUESTIONS ANSWERED !
            </Typography> 
                </Box>
            {contact.map((object, key)=>
            <Grid key={key} justifyContent='center'textAlign='center' item md={4} xs={12}>
              <RevolutionCard object={object}/>
            </Grid>
                )}
           
        </Grid>
           {/* <Box display='block' overflow='hidden'>
             <CallMade
             sx={{
                animation:'ease-in',
                animationDuration:'10s',
                animationRange:'in'
             }}></CallMade>
           </Box> */}
        </Box>
        </Container>
        
  )
}

export default JoinwithUs