import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Header from "../../Header";
import Footer from "../../Footer";
import {
	Button,
	Card,
	Fab,
	Grid,
	Slide,
	Stack,
	useMediaQuery,
	useScrollTrigger,
} from "@mui/material";
import Navbar from "../../common/Navbar";
import MHeader from "../../MobileUi/Header";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import CustomizedInputBase from "../../common/CustomInput";
import {
	FeedOutlined,
	Home,
	ShoppingCartOutlined,
	YouTube,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const Layout = (props) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	const sidebarOption = [
		{ title: "Home" },
		{ title: "Dashboard", link: "/dashboard" },
		{ title: "Reel" },
		{ title: " Frenchise" },
		{ title: "Feed" },
	];
	const handleDrawerClose = () => {
		setOpen(false);
	};

	const drawerWidth = 180;

	const openedMixin = (theme) => ({
		// width: `calc(${theme.spacing(6)} + 1px)`,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		[theme.breakpoints.up("md")]: {
			width: drawerWidth,
			// overflowX: 'hidden',
			backgroundColor: "transparent",
		},

		backgroundColor: "transparent",
		// overflowX:'visible',
		filter: "blure(80px)",
		backgroundColor: "rgba(255, 255, 255 ,0.2)",
		// backgroundColor:'lightcoral'
		border: "0px",
	});

	const closedMixin = (theme) => ({
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// overflowX: 'hidden',
		border: "0px",
		backgroundColor: "transparent",
		width: `calc(${theme.spacing(0)} + 1px)`,
		[theme.breakpoints.up("sm")]: {
			width: `calc(${theme.spacing(6)} + 1px)`,
		},
	});
	const DrawerHeader = styled("div")(({ theme }) => ({
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		paddingTop: theme.spacing(3),
		[theme.breakpoints.down("md")]: {
			paddingTop: theme.spacing(8),
		},
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	}));

	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== "open",
	})(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			// marginLeft: drawerWidth,
			// width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	const Drawer = styled(MuiDrawer, {
		shouldForwardProp: (prop) => prop !== "open",
	})(({ theme, open }) => ({
		// width: drawerWidth,
		flexShrink: 0,
		border: "none",

		whiteSpace: "nowrap",
		backgroundColor: "transparent",
		// boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			"& .MuiDrawer-paper": openedMixin(theme),
		}),
		...(!open && {
			...closedMixin(theme),
			"& .MuiDrawer-paper": closedMixin(theme),
		}),
	}));

	const StyledFab = styled(Fab)({
		position: "absolute",
		zIndex: 1,
		top: -30,
		left: 0,
		right: 0,
		margin: "0 auto",
	});

	function HideOnScrool(props) {
		const { children, window } = props;

		const trigger = useScrollTrigger({
			target: window ? window() : undefined,
		});

		return (
			<>
				<Slide appear={true} direction="down" in={!trigger}>
					{children ? children : ""}
				</Slide>
			</>
		);
	}
	function ShowOnScrool(props) {
		const { children, window, direction } = props;

		const trigger = useScrollTrigger({
			target: window ? window() : undefined,
		});
		const dispalySlide = {
			display: trigger ? { xs: "block", lg: "none" } : "none",
		};

		return (
			<>
				<Slide
					appear={true}
					direction={direction}
					sx={dispalySlide}
					in={trigger}
				>
					{children ? children : ""}
				</Slide>
			</>
		);
	}

	return (
		<>
			<Box sx={{ display: "flex", backgroundColor: "transparent" }}>
				<CssBaseline />

				<AppBar
					elevation={0}
					sx={{ backgroundColor: "transparent" }}
					open={false}
				>
					<ShowOnScrool direction="down" {...props}>
						<Card sx={{ bgcolor: "transparent" }}>
							<Stack
								bgcolor="transparent"
								sx={{ bgcolor: "transparent", mt: 1 }}
								direction="row"
							>
								<CustomizedInputBase />
								<Button sx={{ minWidth: "40px", boxShadow: 5 }}>
									<ShoppingCartOutlined
										sx={{ fontSize: "30px", color: "#000" }}
									/>
								</Button>
							</Stack>
						</Card>
					</ShowOnScrool>
					<HideOnScrool {...props}>
						<Stack>
							<Header event={handleDrawerOpen} />
						</Stack>
					</HideOnScrool>
				</AppBar>
				<Drawer
					sx={{
						border: "0px",
						filter: "20px",
						display: { md: "block", xs: "block", boxShadow: 8 },
					}}
					ModalProps={{
						keepMounted: true,
					}}
					variant="permanent"
					open={open}
				>
					<Card
						sx={{
							height: "100%",
							bgcolor: "transparent",
							backdropFilter: "blure(50px) !important",
						}}
					>
						<DrawerHeader></DrawerHeader>
						<DrawerHeader></DrawerHeader>

						<List>
							{sidebarOption.map((text, index) => (
								<ListItem key={text} disablePadding sx={{ display: "block" }}>
									<Link to={text.link}>
										<ListItemButton
											sx={{
												minHeight: 48,
												justifyContent: open ? "initial" : "center",
												px: 2,
											}}
										>
											<ListItemIcon
												sx={{
													minWidth: 0,
													mr: open ? 3 : "auto",
													justifyContent: "center",
												}}
											>
												{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
											</ListItemIcon>
											<ListItemText
												primary={text.title}
												sx={{ opacity: open ? 1 : 0 }}
											/>
										</ListItemButton>
									</Link>
								</ListItem>
							))}
						</List>
					</Card>
				</Drawer>
				<Box
					component="main"
					sx={{
						flexGrow: 5,
						mt: 3,
						overflowX: "hidden",
						display: "block",
						position: "relative",
					}}
				>
					<DrawerHeader />
					{/* <Navbar/> */}
					{props.children}
					<Box
						display="block"
						position="relative"
						// height={{ lg: "100vh", xs: "120vh", sm: "120vh", md: "120vh" }}
					>
						<Footer />
					</Box>
				</Box>

				<AppBar
					position="fixed"
					color="inherit"
					sx={{
						bgcolor: "transparent",
						top: "auto",
						bottom: 0,
						display: { lg: "none", xs: "block" },
					}}
				>
					<ShowOnScrool direction="up" {...props}>
						<Stack
							display="flex !important"
							py={1}
							direction="row"
							justifyContent="space-evenly"
							bgcolor="transparent"
						>
							<Toolbar>
								<Button
									sx={{ boxShadow: 5 }}
									color="inherit"
									aria-label="open drawer"
								>
									<Home sx={{ fontSize: "40px" }} />
								</Button>
								<Box sx={{ flexGrow: 1 }} />
								<Button
									sx={{ boxShadow: 5 }}
									color="inherit"
									aria-label="open drawer"
								>
									<FeedOutlined sx={{ fontSize: "40px" }} />
								</Button>
								{/* <StyledFab color="secondary" aria-label="add">
                <FeedOutlined />
              </StyledFab> */}
								<Box sx={{ flexGrow: 1 }} />
								<Button sx={{ boxShadow: 5 }} color="inherit">
									<YouTube sx={{ fontSize: "40px" }} />
								</Button>
								<Box sx={{ flexGrow: 1 }} />
								<Button sx={{ boxShadow: 5 }} color="inherit">
									<MoreIcon sx={{ fontSize: "40px" }} />
								</Button>
							</Toolbar>
						</Stack>
					</ShowOnScrool>
				</AppBar>
			</Box>
		</>
	);
};

export default Layout;
