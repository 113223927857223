import React, { useState } from "react";
import { logo } from "../../assets";
import { Box, Button, Card, CardContent, CardMedia, Grid } from "@mui/material";
import {
	CalendarMonth,
	FastForwardRounded,
	FastRewindRounded,
	PauseRounded,
	PlayArrowRounded,
} from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import CarouselNav from "../slider/CarosalNav";
import CssCarousal from "../slider/CssCarousal";
import { CardBody } from "@material-tailwind/react";
import { Link } from "react-router-dom";

// import './css/Nav.css';/
const StudentCard = ({ product }) => {
	const [paused, setPaused] = useState(false);

	return (
		<Box
			sx={{
				":hover": {
					boxShadow: 20,
				},
			}}
			boxShadow={6}
			mx={1}
		>
			<Card
				variant="elevation"
				on
				elevation={{
					onmouseenter: 10,
				}}
				sx={{
					//  boxShadow:'55',
					bgcolor: "transparent",
					display: "flex",
					flexDirection: { xs: "column", sm: "column" },
					alignItems: "flex-start",
				}}
			>
				<Grid container>
					<CssCarousal pagination={true}>
						{product.img.map((img) => (
							<SwiperSlide key={img}>
								<CardMedia
									component="img"
									alt="Contemplative Reptile album cover"
									src={img ? img : logo}
									sx={{
										position: "relative",
										p: 0,
										height: "200px !important",
										width: { xs: "100%", sm: "100%" },
										borderRadius: 2,
										":hover": {
											transitionTimingFunction: "ease-in-out",
											backgroundColor: "blueviolet",
										},
									}}
								/>
							</SwiperSlide>
						))}
					</CssCarousal>
				</Grid>

				<div className="w-full pb-5 flex justify-center">
					<Link to={product.to}>
						<Button
							sx={{
								mt: 2,
								boxShadow: 2,
								color: "#000",
								":hover": {
									boxShadow: 8,
								},
							}}
						>
							{product.title ? product.title : ""}
						</Button>
					</Link>
				</div>
			</Card>
		</Box>
	);
};

export default StudentCard;
