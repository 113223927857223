import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./css/Nav.css";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Card, CardMedia } from "@mui/material";

import { Banner3 } from "../../assets";

export default function CarouselNav(props) {
	const SwiperRef = useRef(null);
	const slide = [
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
		{ title: "Slide 1", src: Banner3 },
	];

	const handleMouseleave = () => {
		if (SwiperRef.current) {
			SwiperRef.current.swiper.autoplay.start();
		}
	};
	const handleMouseEnter = () => {
		if (SwiperRef.current) {
			SwiperRef.current.swiper.autoplay.stop();
		}
	};

	const slideper = props.slide ? props.slide : 1;
	return (
		<>
			<Swiper
				slideActiveClass="w-[50vw]"
				slidesPerView={props.slide ? slideper : 1}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseleave}
				breakpoints={props.breakpoints ? props.breakpoints : false}
				navigation={props.navigation ? props.navigation : false}
				autoplay={{
					// pauseOnMouseEnter:`${props.mouse ?  props.mouse : true}`,
					delay: `${props.delay ? props.delay : "50s"}`,
				}}
				direction={props.direction ? props.direction : "horizontal"}
				effect="creative"
				speed={props.speed ? props.speed : 1000}
				// onMouseEnter={handleMouseEnter}
				pagination={
					props.pagination
						? {
								dynamicBullets: true,
						  }
						: false
				}
				loop={true}
				modules={[Navigation, Autoplay, Pagination]}
				className="mySwiper"
				style={{
					width: "97vw",
					backgroundColor: "transparent",
					filter: "brightness(0.7)",
				}}
			>
				{props.children ? (
					props.children
				) : (
					<>
						{slide.map((slide, id) => (
							<SwiperSlide
								key={id}
								className="NavSlide"
								style={{ width: "97vw" }}
							>
								<Card>
									<img
										style={{ filter: "brightness(0.5)" }}
										src={Banner3}
										alt="ggdd"
									/>
								</Card>
							</SwiperSlide>
						))}
					</>
				)}
			</Swiper>
		</>
	);
}
