import { Card, Container } from '@mui/material'
import React from 'react'

const ContainerCard = (props) => {
  return (
    <>
     <Container sx={{mt:5}} maxWidth={props.width}>
          <Card elevation={5} className='py-14 px-8' sx={{bgcolor:'transparent', textAlign:'center', py:5,}}>
            {props.children}
          </Card>
          </Container>
    </>
  )
}

export default ContainerCard