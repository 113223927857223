import React from "react";
import Heading from "../../component/common/Heading/Heading";
import Marquee from "react-fast-marquee";
import "./highlights.css";
import {
	hero5,
	hero6,
	hero7,
	hero8,
	hero9,
	hero10,
	hero11,
	hero12,
} from "../../assets";
const Highlights = () => {
	return (
		<>
			<Heading heading="Our Highlights"></Heading>
			<div>
				<Marquee direction="right" speed={75} delay={2}>
					<div className="image_wrapper">
						<img src={hero5} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero6} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero7} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero8} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero9} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero10} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero11} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero12} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero7} alt=" hero " />
					</div>
				</Marquee>
				<Marquee direction="left" speed={75} delay={2}>
					<div className="image_wrapper">
						<img src={hero5} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero6} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero7} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero8} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero9} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero10} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero11} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero12} alt=" hero " />
					</div>
					<div className="image_wrapper">
						<img src={hero7} alt=" hero " />
					</div>
				</Marquee>
			</div>
		</>
	);
};

export default Highlights;

// import React from "react";
// import Heading from "../../component/common/Heading/Heading";
// import GridCard from "../../component/common/cards/GridCard";
// import CssCarousal from "../../component/slider/CssCarousal";
// import ProductCard from "../../component/ProductCard";
// import { SwiperSlide } from "swiper/react";
// import { Grid } from "@mui/material";
// import ImgCard from "../../component/common/ImgCard";

// const Highlights = ({
// 	heading,
// 	listProduct,
// 	children,
// 	subtitle1,
// 	subtitle2,
// }) => {
// 	return (
// 		<>
// 			<Heading
// 				heading={heading}
// 				subtitle1={subtitle1}
// 				subtitle2={subtitle2}
// 			></Heading>

// 			<GridCard>
// 				<CssCarousal
// 					au
// 					breakpoints={{
// 						400: {
// 							width: 400,
// 							slidesPerView: "1",
// 						},
// 					}}
// 					slide={{ xs: 1, md: 3 }}
// 				>
// 					{children
// 						? children
// 						: listProduct.map((product, id) => (
// 								<SwiperSlide>
// 									<Grid lg={12} md={12} sm={12} xs={12} key={id}>
// 										<ImgCard src={product.img} />
// 									</Grid>
// 								</SwiperSlide>
// 						  ))}
// 				</CssCarousal>
// 			</GridCard>
// 		</>
// 	);
// };

// export default Highlights;
