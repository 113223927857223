import { ArrowBack } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import LineChart from '../../../component/Chart/LineChart'

const LifeTimeData = () => {
  return (
   <>
    <Box mt={{md:3,xs:0}}>
    <Typography
    position='sticky'
    component='div'
    variant='subtitle1'>
     <ArrowBack/>
     LifeTime Data
    </Typography>
    <Box sx={{mt:3}}>
    
     <LineChart/>
     <LineChart/>
     <LineChart/>
     <LineChart/>
     <LineChart/>
    </Box>

    </Box>
    </>
  )
}

export default LifeTimeData