import { ArrowBack } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import React, { useState } from 'react'
import LineChart from '../../../component/Chart/LineChart'


const MonthlyData = () => {


 
  


  return (
    <Box mt={{md:3,xs:0}}>
    <Typography
    position='sticky'
    component='div'
    variant='subtitle1'>
     <ArrowBack/>
     Monthly Data
    </Typography>
    <Box sx={{mt:3}}>
    {/* <LineChart chartData={chartData} /> */}
     <LineChart/>
     <LineChart/>
     <LineChart/>
     <LineChart/>
     <LineChart/>
    </Box>

    </Box>
  )
}

export default MonthlyData