import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";

const CustomTab = styled(Tab)(({ theme }) => ({
	marginTop: "5px",
	marginBottom: "5px",
	color: "white",
	backgroundColor: "green",
	border: "1px solid white",
	borderRadius: "10px",
	padding: theme.spacing(1, 2),
	minWidth: 0,
	marginRight: theme.spacing(2),
	transition: "background-color 0.3s ease, color 0.3s ease",
	"&.Mui-selected": {
		color: "black",
		backgroundColor: "white",
		border: "1px solid #2196f3",
	},
}));

const TabScroll = ({ categories, selectedCategory, onChange }) => {
	const handleChange = (event, newValue) => {
		onChange(newValue);
	};

	return (
		<Tabs
			value={selectedCategory}
			onChange={handleChange}
			variant="scrollable"
			scrollButtons="auto"
			textColor="inherit"
			TabIndicatorProps={{ hidden: true }}
		>
			{categories.map((category) => (
				<CustomTab
					key={category.id}
					label={category.name}
					value={category.id}
				/>
			))}
		</Tabs>
	);
};

export default TabScroll;
