import React from 'react'
import RevolutionCard from '../joinwithus/RevolutionCard'
import { Box } from '@mui/material'

const Contact = ({contact}) => {
  return (
    <Box width={{lg:600, xs:'100%'}} py={2} display='flex' >
    {contact.map((object, key)=>
    <RevolutionCard elevation={2} key={key} object={object} />
    )}
  </Box>
  )
}

export default Contact