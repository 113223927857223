import { AccountBoxTwoTone, Facebook, FactCheck, Google, Person, QuestionMark } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardMedia, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'

import {  useNavigate } from 'react-router-dom'
import VisuallyHiddenInput from '../component/common/VisuallyHiddenInput'
import SliderCard from '../component/common/SltderCard'
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'

const AddressVerification = () => {
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [type, setType] = useState('');
  const navigate = useNavigate()

  
  function handlefile(e) {
      console.log(e.target.files);
      setFile(URL.createObjectURL(e.target.files[0]));
  }
  function handlefile1(e) {
      console.log(e.target.files);
      setFile1(URL.createObjectURL(e.target.files[0]));
  }

    
    
   
    const handleChange = (event) => {
      setType(event.target.value);
    //   setPage(localStorage.getItem('page'))
    //   localStorage.setItem('type', event.target.value)
      
    };
    const [isLoding, setisLoding] = useState(true)
    useEffect(() => {
      setTimeout(() => {
        setisLoding(false)
      }, 1000);
    
    },[])
    
  if(isLoding){
    return<Loading/>
  }


  const handleSubmit =()=>{
    navigate('/Identityverify')
  
   }
  return (
  
    <Container sx={{mt:5}} maxWidth='lg'>
      <Helmet>
        <title>
          Address Verification
        </title>
      </Helmet>
      <Box sx={{ 
        boxShadow:'10',
         display :'block'
      }}>
        <Grid  direction={{lg:'row',xs:'column-reverse'}} container >
          <Grid xs={12} item lg={6}>
          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}}  rowGap={3}>
            <Stack alignItems='center' sx={{mb:3}}>
                <Typography textAlign='center' fontWeight={600} fontSize={30}  component='h1'>

               Select  Your Address Proof
                </Typography>
            </Stack>

        <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Address Proof </InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={type}
    label="?"
    onChange={handleChange}
    placeholder='Address Proof'
  >
    <MenuItem value='aadharCard'>Aadhar Card</MenuItem>
    <MenuItem value='voter'>Voter Id Card</MenuItem>
    <MenuItem value='passport'>Passport</MenuItem>
    <MenuItem value='others'>Others</MenuItem>
  </Select>
  <Grid sx={{mb:1}} container>
  <Grid display='flex' justifyContent='center' item xs={12} lg={12}>
             <Button
               component="label"
               role={undefined}
                
            
               tabIndex={-1}
               sx={
                 {
                 mb:'5%',
                 mx:'5%',
                   width:'90%',
                   height:'95%'
                 }}
               
               >
            <Card
            sx={{bgcolor:'gray',
            width:'100%',
            height:'100%'
            }}
            
            >
          {
            file ? <CardMedia
            component='img'
            alt='sbbbbr'
            src={file}
            sx={{
            overflow:'hidden',
              height:'200px',
              objectFit:'cover'
            }}
            />
:        <>
<Typography component='div' sx={{m:1}} variant='subtitle1'>
Front
</Typography>
<Box
display='flex'
justifyContent='center'
>
            <Person
            
             sx={{
             alignSelf:'center',
             mx:'auto',
              width:'150px',
              height:'150px'
            }} 
            />
</Box>
</>
          } 
             
              <VisuallyHiddenInput onChange={handlefile} type='file'/>
            </Card>
             </Button>
            </Grid>
  <Grid display='flex' item xs={12}>
             <Button
               component="label"
               role={undefined}
                
            
               tabIndex={-1}
               sx={
                 {
                 mb:'5%',
                 mx:'5%',
                   width:'90%',
                   height:'95%'
                 }}
               
               >
            <Card
            sx={{bgcolor:'gray',
            width:'100%',
            height:'100%'
            }}
            
            >
          {
            file ? <CardMedia
            component='img'
            alt='abbbr'
            src={file}
            sx={{
            overflow:'hidden',
              height:'200px',
              objectFit:'cover'
            }}
            />
:        <>
<Typography component='div' sx={{m:1}} variant='subtitle1'>
Back
</Typography>
<Box
display='flex'
justifyContent='center'
>
            <Person
            
             sx={{
             alignSelf:'center',
             mx:'auto',
              width:'150px',
              height:'150px'
            }} 
            />
</Box>
</>
          } 
             
              <VisuallyHiddenInput onChange={handlefile1} type='file'/>
            </Card>
             </Button>
            </Grid>
 
  </Grid>
  <Stack  rowGap={0} direction='row' display='flex' justifyContent='space-evenly' >
    <Button variant='contained' size='large' color='info'>
    back
    </Button>
  {file  ?<Button  variant='contained'
 
  size='large' onClick={handleSubmit} sx={{
          
           
        }} color='secondary'>
         Next
        </Button>
        :
        <Button disabled variant='contained' size='large' sx={{
        
     
      }} color='secondary'>
        Next
      </Button>
       }   

         </Stack>
</FormControl>
     {/* <Box display='flex' justifyContent='center'>

       <Avatar  sx={{height:100, width:100}}>
        {type? <AccountBoxTwoTone />:<QuestionMark fontSize='100px'/> }
       </Avatar>
          
     </Box> */}

        </Box>

      </Card>
                    </Grid>
                    <Grid  xs={12} alignItems='center' lg={6}>
                    <SliderCard/>
                    </Grid>
                  </Grid>
      <Card>

      </Card>
      </Box>
    </Container>
  
  )
}

export default AddressVerification