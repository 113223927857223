import { ArrowBack, VolunteerActivism } from '@mui/icons-material'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const CoursesEarning = () => {
  return (
    <>
    <Box my={{lg:3,xs:0}}>
         <Stack direction='row'  justifyContent='flex-start'>
          <Link to='/dashboard'>
             <ArrowBack/> 
          </Link>
             <Typography
             component='div'
             fontWeight={800}
             variant='h6'>
               Courses Earning
             </Typography>
         </Stack>
         <Stack 
          textAlign='center'  justifyContent='center'>
           <VolunteerActivism sx={{fontSize:80, mx:'auto', mb:8}}/>
             <Typography 
             component='span'
             fontWeight={700}
             variant='h4'>
               Enrolled Earning
             </Typography>
            <Card sx={{mx:5,my:2, py:3}}>
             {/* <Typography 
             component='span'
             fontWeight={700}
             variant='h5'>
               Points
             </Typography> */}
             <Typography
             component='div'
             variant='h2'>
               00.00
             </Typography>
             <Typography 
             component='div'
             variant='body1'>
             Earned through Reffrals
             </Typography>
            </Card>
             <Button 
             sx={{boxShadow:5,
               my:1, 
               borderRadius:5,
               mx:'auto',
             px:5}}
               >
               Transfer Your Points
             </Button>
         </Stack>
 
              
         </Box>
         
    </>
  )
}

export default CoursesEarning