import { Grid, Stack, Typography } from '@mui/material'
import React from 'react'

const Heading = ({heading , subtitle1 ,subtitle2}) => {
  return (
    <>
      <Grid alignItems='center' my={3} justifyContent='center'  textAlign='center'  container>
        <Stack rowGap={0}>

            <Typography    component='div' fontWeight={700} variant='h3'>
            {heading ? heading:''}
            </Typography>
            <Typography  component='div' variant='subtitle1'>
            {subtitle1 ? subtitle1:''}
            </Typography>
            <Typography  component='div' variant='subtitle1'>
            {subtitle2 ? subtitle2:''}
            </Typography>
        </Stack>
            
        </Grid>
    </>
  )
}

export default Heading