import { ExpandMore } from '@mui/icons-material'
import { Box, Button, Popover, Tooltip, Typography } from '@mui/material'
import React from 'react'

const CustomButton = ({button, title, children, buttonStyle}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <>  {button ? 
      <Box boxShadow={2}  sx={{":hover":{
        boxShadow:8,
      },
   
    }}>
      <Tooltip title={title}>

      <Button 
    // onMouseOver={handleClick} 
    //  onMouseEnter={handleClick}
    size='small' aria-describedby={id} sx={{color:'#000', border:'none',py:0.2, px:{xs:0,lg:1, minWidth:'30px'}}} onClick={handleClick}>{title? title:'Language'}</Button>
    </Tooltip>
      </Box>
      :
     <Button sx={{ color:'#000'}} color='info'  size='small' aria-describedby={id}  

      onClick={handleClick}>{title}</Button>
    }
     <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onMouseLeave={handleClose}
         autoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
 { children}
     </Popover>
     </>
  )
}

export default CustomButton