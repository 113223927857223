import { useState } from "react";
import {
	Card,
	CardContent,
	Typography,
	Modal,
	IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

const CustomCard = styled(Card)(({ theme }) => ({
	position: "relative",
	width: 100,
	height: 180,
	margin: theme.spacing(0.1),
	cursor: "pointer",
	transition: "transform 0.2s ease-in-out",
	"&:hover": {
		transform: "scale(1.05)",
	},
}));

const AvatarOverlay = styled("img")({
	position: "absolute",
	top: 10,
	left: 10,
	width: 40,
	height: 40,
	borderRadius: "50%",
	border: "2px solid #fff",
	boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
});

const TextContainer = styled("div")({
	position: "absolute",
	bottom: 10,
	left: 10,
	color: "#fff",
});

const StoryCard = ({ user, imageUrl }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<CustomCard onClick={handleOpenModal}>
				<img
					src={imageUrl}
					alt="Story"
					style={{ width: "100%", height: "100%", borderRadius: 8 }}
				/>

				<AvatarOverlay src={user.avatarUrl} alt={user.name} />

				<TextContainer>
					<Typography variant="body1">{user.name}</Typography>
				</TextContainer>
			</CustomCard>
			<Modal open={isModalOpen} onClose={handleCloseModal}>
				<div
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						backgroundColor: "#fff",
						padding: 16,
						borderRadius: 8,
						boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
					}}
				>
					<IconButton
						edge="end"
						color="inherit"
						aria-label="close"
						onClick={handleCloseModal}
						style={{ position: "absolute", top: 0, right: 2 }}
					>
						<CloseIcon />
					</IconButton>

					{/* Modal Content */}
					<Typography variant="h5" gutterBottom>
						{user.name}'s Story
					</Typography>
					<img
						src={imageUrl}
						alt="Story"
						style={{ maxWidth: "100%", maxHeight: "70vh", borderRadius: 8 }}
					/>
				</div>
			</Modal>
		</>
	);
};

export default StoryCard;
