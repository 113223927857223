import { ExpandRounded } from "@mui/icons-material";
import hero1 from "../assets/hero1.png";
import hero2 from "../assets/hero2.png";

import hero4 from "../assets/hero4.png";

const listProduct1 = [
	{ id: "1", img: hero1 },
	{ id: "2", img: hero2 },
	{ id: "3", img: hero2 },
	{ id: "4", img: hero4 },
	{ id: "5", img: hero4 },
	{ id: "6", img: hero4 },
	{ id: "7", img: hero4 },
	{ id: "8", img: hero4 },
	{ id: "9", img: hero4 },
	{ id: "7", img: hero4 },
	{ id: "8", img: hero4 },
	{ id: "9", img: hero4 },
];

const listCourses = [
	{
		id: "React_developer_course",
		title: "React Developer Course",
		img: hero1,
		price: "  45",
	},
	{
		id: "Node_developer_course",
		title: "Node Developer Course",
		img: hero2,
		price: "  45",
	},
	{
		id: "Angular_developer_course",
		title: "Angular Developer Course",
		img: hero2,
		price: "  45",
	},
	{
		id: "Full_developer_course",
		title: "Full Developer Course",
		img: hero4,
		price: "  45",
	},
	{
		id: "Backend_developer_course",
		title: "Backend Developer Course",
		img: hero4,
		price: "  45",
	},
	{
		id: "Java_developer_course",
		title: "Java Developer online offline ebsite online Course",
		img: hero4,
		price: "  45",
	},
	{
		id: "mern_developer_course",
		title: "Mern Developer Course",
		img: hero4,
		price: "  45",
	},
	{
		id: "Django_developer_course",
		title: "Django Developer Course",
		img: hero4,
		price: "  45",
	},
	{
		id: "Python_developer_course",
		title: "Python Developer Course",
		img: hero4,
		price: "  45",
	},
	{
		id: "AI_developer_course",
		title: "AI Developer Course",
		img: hero4,
		price: "  45",
	},
	{
		id: "React_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
	},
];
const listProduct = [
	{
		id: "React_developer_course",
		title: "React Developer Course",
		img: hero1,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "Node_developer_course",
		title: "React Developer Course",
		img: hero2,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "Angular_developer_course",
		title: "React Developer Course",
		img: hero2,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "Full_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "Backend_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "Java_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "mern_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "Django_developer_course",
		title: "Mean Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "Python_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "AI_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
	{
		id: "React_developer_course",
		title: "React Developer Course",
		img: hero4,
		price: "  45",
		know: "gggggggggggggg",
	},
];

const featureJob = [
	{ id: "1", img: hero1 },
	{ id: "2", img: hero2 },
	{ id: "3", img: hero2 },
];

// const listData = [
// 	{
// 		id: 1,
// 		avatar: "",
// 		name: "John Doe",
// 		date: "2023-05-20",
// 		rating: 4,
// 		text: "This is a wonderful product! I highly recommend it to everyone.",
// 	},
// 	{
// 		id: 2,
// 		avatar: "",
// 		name: "Jane Smith",
// 		date: "2023-04-15",
// 		rating: 5,
// 		text: "Excellent quality and fast shipping. Very satisfied!",
// 	},
// 	{
// 		id: 3,
// 		avatar: "",
// 		name: "Alice Johnson",
// 		date: "2023-03-10",
// 		rating: 3,
// 		text: "The product is good, but the delivery took too long.",
// 	},
// 	{
// 		id: 4,
// 		avatar: "",
// 		name: "Robert Brown",
// 		date: "2023-02-05",
// 		rating: 2,
// 		text: "Not as described, quite disappointed.",
// 	},
// 	{
// 		id: 5,
// 		avatar: "",
// 		name: "Emily Davis",
// 		date: "2023-01-01",
// 		rating: 5,
// 		text: "Absolutely amazing! Will definitely purchase again.",
// 	},
// 	{
// 		id: 6,
// 		avatar: "",
// 		name: "Emily Davis",
// 		date: "2023-01-01",
// 		rating: 5,
// 		text: "Absolutely amazing! Will definitely purchase again.",
// 	},
// ];
const listData = [
	{
		id: "1",
		rating: 5,
		title: "React Developer Course",
		text: "Sudo24 is  an organisation and edtech Startup which holds   very huge App development and web development Team",
	},
	{
		id: "2",
		rating: 4,
		title: "React Developer Course",
		text: "Sudo24 is  an organisation and edtech Startup which holds   very huge App development and web development Team",
	},
	{
		id: "3",
		rating: 4,
		title: "React Developer Course",
		text: "Sudo24 is  an organisation and edtech Startup which holds   very huge App development and web development Team",
	},
	{
		id: "4",
		rating: 3.5,
		title: "React Developer Course",
		text: "Sudo24 is  an organisation and edtech Startup which holds   very huge App development and web development Team",
	},
	{
		id: "5",
		rating: 5,
		title: "React Developer Course",
		text: "Sudo24 is  an organisation and edtech Startup which holds   very huge App development and web development Team",
	},
	{
		id: "6",
		rating: 5,
		title: "React Developer Course",
		text: "Sudo24 is  an organisation and edtech Startup which holds   very huge App development and web development Team",
	},
];

export { listProduct1, featureJob, listProduct, listData, listCourses };
