import { Box, Button, ButtonGroup, CardMedia, Checkbox, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const CartItem = ( {product, onDelete}) => {


  const [quantity, setQuantity] = useState(0)
  


  const cart = useSelector(state => state)
  console.log(cart)
  



  return (
    <Grid rowGap={1} container>
                            
    <Grid item display='flex' alignItems='center' xs={12} md={4}>
    {/* <Input aria-label='demo' sx={{my:'auto'}} value={true} type='checkbox'/> */}
    <Checkbox
    aria-label='demo'
    defaultChecked
    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, }}
  />
       <CardMedia
       component='img'
       sx={{
        objectFit:'cover',
        ":hover": {
            boxShadow:10
        }
       }}
       src={product.img}
       />
    </Grid>
    <Grid item position='relative' xs={12} md={7}>
      <Stack direction='row' justifyContent='space-between' >

      <Box>
      <Typography component='div' variant='h5'>
        {product.title}
      </Typography>
      <Typography mb={1} component='div' variant='body2'>
        Seats Available
      </Typography >
      </Box>
      <Box display={{md:'none', xs:'block'}}>
      < Button sx={{p:0,m:0, fontSize:10}} color='error' variant='contained'>
            25% Off
        </Button>
        <Typography component='div'
        variant='caption' color='red'>
            Limited Seats Available
        </Typography>
        <Typography component='div'
        variant='h6' >
            111111
        </Typography>
        <Typography component='div'
        variant='body2' >
           MRP : <strike>1555555</strike>
        </Typography>

      </Box>
      </Stack>
      <ButtonGroup   sx={{position:{md:'absolute', xs:'relative'},display:'contents', bottom:0}} size='small' variant='text'>
        <FormControl >

      <InputLabel id="demo-simple-select-label">Quantity</InputLabel>
<Select
sx={{
border:'none',
width:'100px',
height:'30px'
}}
labelId="demo-simple-select-label"
id="demo-simple-select"
value={quantity}
label="?"
placeholder=' Quantity'
onChange={(e)=>setQuantity(e.target.value)}
>
<MenuItem value='teacher'>0 (Default)</MenuItem>
<MenuItem value='student'>1</MenuItem>
<MenuItem value='student'>2</MenuItem>
<MenuItem value='student'>3</MenuItem>
<MenuItem value='student'>4</MenuItem>
<MenuItem value='student'>5</MenuItem>
<MenuItem value='student'>6</MenuItem>
<MenuItem value='student'>7</MenuItem>

</Select>  
</FormControl>
        <Divider sx={{display:{md:'none', xs:'block'}, mt:1}} />
        <Button size='small' onClick={onDelete}>
            Delete
        </Button>
        <Button size='small'>
            Save for Later
        </Button>
        <Button size='small'>
            See more Likes
        </Button>
        <Button size='small'>
            Share
        </Button>
      </ButtonGroup>
    </Grid>
    <Grid display={{xs:'flex', md:'block', xs:'none'}} justifyContent='flex-end' item  xs={12} md={1}>
        < Button sx={{p:0,m:0, fontSize:10}} color='error' variant='contained'>
            25% Off
        </Button>
        <Typography component='div'
        variant='caption' color='red'>
            Limited Seats Available
        </Typography>
        <Typography component='div'
        variant='h6' >
            111111
        </Typography>
        <Typography component='div'
        variant='body2' >
           MRP : <strike>1555555</strike>
        </Typography>

    </Grid>
  </Grid>
  )
}

export default CartItem