import { Box, Button, Popover, Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';

const BoxButton = ({button, children}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
  return (
    <>
    <Box sx={{mx:1}}>

       <Link to={`${button.id}`}>

     
        <Button variant='contained' color={button.color} sx={{boxShadow:5}} fullWidth  onClick={handleClick}>

    <Box
    
    
    display='grid'
    alignItems='center'
    textAlign='center'
   >
          <Box mx='auto'>

              {button.icon}

          </Box>
          <Stack>
              {button.title}
          </Stack>

      </Box>
   </Button>
   </Link>
   </Box>
      <Popover
        //   id={id}
          open={open}
        //   anchorEl={anchorEl}
          onClose={handleClose}
          onMouseLeave={handleClose}
          autoFocus
        //   anchorOrigin={{
        //       vertical: 'bottom',
        //       horizontal: 'left',
        //   }}
      >
                    {children}
          </Popover></>
  )
}

export default BoxButton