import { ArrowBack, MoreVertRounded } from '@mui/icons-material'
import { Box, Card, Checkbox, Grid, InputBase, Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const RecordVedio = () => {
    const card=[
        0,1,1,1,1,1,1,1,11,1,11,1,1,11,1,11,1,1,1,1,1,11,1,1,11,1,1,1,1,1
    ]
  return (
    <>
    <Box my={{lg:2,xs:0}} mx={1} position='sticky' sx={{scrollbarWidth:'none', overflow:'auto'}} boxShadow={5}>
        <Stack direction='row'  justifyContent='space-between'>
            <Link to='/dashboard'>
            <ArrowBack/> 
            </Link>
             <Box bgcolor='lightgrey' borderRadius={1}>
                <InputBase sx={{bgcolor:'white',m:0.5,borderRadius:1}}/>
             </Box>
             <MoreVertRounded/>
        </Stack>
        <Box>

     <Grid my={3} rowGap={2} container>
        {card.map((card, key)=>

        <Grid  px={1} xs={6} item lg={4}>
            <Card >
            <Box position='relative' height={{md:'200px',xs:'70px',sm:'150px'}} width={'100%'}>
            <Checkbox 
             sx={{position:'absolute', right:'0px', top:'0px'}} 
             defaultChecked />
                {/* <Check /> */}
            </Box>
            </Card>
        </Grid>
            )}
      
      
     </Grid>
        </Box>
    </Box>
    </>
  )
}

export default RecordVedio