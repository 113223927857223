import { Box, Button, Card, CardHeader, Container, Grid, Typography , CardContent, Input, CardMedia, ButtonGroup, Divider, Chip, IconButton, Avatar, Stack, Checkbox, MenuItem, Select, InputLabel, FormControl} from '@mui/material'
import React, { useEffect, useState } from 'react'
import GridCard from '../component/common/cards/GridCard'
 import { hero1, next1 } from '../assets'
import { Person } from '@mui/icons-material'
import Faq from '../component/common/Faq'
import StackCard from '../component/common/StackCard'
import CartItem from '../component/cart/CartItem'
import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'
const Cart = () => {
    const [cartProduct, setcartProduct] = useState([
        {id:1, title:'React js Developer', img:hero1},
        {id:2, title:'Node js Developer', img:hero1},
        {id:3, title:'Veu js Developer', img:hero1},
        {id:4, title:'Angular js Developer', img:hero1},
    ])
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const accorditions =[
        {id:1 , title :'10% Off on Credit Cards'},
    ]

   const Delete =  (e)=>{
    
    setcartProduct(cartProduct.filter(task => task.id !== e.id));
  
   }
    const cousrse =[
        {id:1, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:next1},
        {id:2, title:'Easily Accessible', Desc :'Learning will fell Very Comfortable with Courselab',src:next1},
      ]
 const  cartproduct= [
    {id:1, title:'React js Developer', img:hero1},
    {id:2, title:'Node js Developer', img:hero1},
    {id:3, title:'Veu js Developer', img:hero1},
    {id:4, title:'Angular js Developer', img:hero1},
   ]
   const [isLoding, setisLoding] = useState(true)
   useEffect(() => {
     setTimeout(() => {
       setisLoding(false)
     }, 1000);
   
   },[])
   
 if(isLoding){
   return<Loading/>
 }



  return (
    <>
    <Helmet>
        <title>
            Cart
        </title>
    </Helmet>
        <Grid display='block' mt={10}   direction={{xs:'column-reverse', lg:'row'}}  container >
            <Card elevation={0}  sx={{mx:{sm:1, xs:0}, backgroundColor:'transparent'}}>
              <Grid rowGap={3} container>
                <Grid xs={12} item display={{lg:'none', xs:'block'}}>
                <Card  sx={{p:2, my:1,}}>
                        <Typography component='div' variant='subtitle1'>
                            No Item Selected
                        </Typography>
                        <Button variant='contained' sx={{mb:3}} size='small' fullWidth>
                             Proceed to Buy
                        </Button> 
                      <Faq accorditions={accorditions}/>
                      </Card>
                </Grid>
                    <Grid item xs={12} lg={9}>
                     <Card>
                     {cartProduct.length >0 ?   <>
                     <CardHeader
                                       sx={{pb:0}}
                                  action={<IconButton aria-label="settings">
                                      Price
                                  </IconButton>}
                                  title="Course Cart"
                                  subheader="Slect Your Course"
                                  subheaderTypographyProps={{ variant: 'body' }} />
                                  
                                 
                                  <CardContent>
                                      {cartProduct.map((product, key) => <>
                                          <Divider sx={{ my: 2 }} />
                                          <CartItem onDelete={()=>Delete(product)} product={product} key={key} />
                                      </>
                                      )}

                                  </CardContent>
                                  </>
                    :
                     
                <Box m={3}>
                <Typography component='div' variant='h4' >
                Your Sudo24 Cart is empty.
                </Typography>
                <Button mt={1} >
                Shop today’s deals
                </Button>
                <Stack mt={2} direction='row' gap={2}>
                    <Button variant='outlined'>
                        Sign in to your Account
                    </Button>
                    <Button variant='outlined'>
                        SignUp Now
                    </Button>
                </Stack>
             </Box>
             }
             </Card>
                    </Grid>
                    <Grid item px={{lg:1, xs:0}} xs={12} lg={3}>
                      <Card  sx={{p:2, mb:2, display:{lg:'block', xs:'none'}}}>
                        <Typography component='div' variant='subtitle1'>
                            No Item Selected
                        </Typography>
                        <Button variant='contained' sx={{mb:3}} size='small' fullWidth>
                             Proceed to Buy
                        </Button> 
                      <Faq accorditions={accorditions}/>
                      </Card>
                      <Card sx={{p:2}}>
                        <Typography component='div' variant='h5'>
                        Customers who bought Courses in your Recent History also get Certifified
                        </Typography>
                        {cousrse.map((row, key)=>
                        <StackCard row={row} key={key}/>
                        )}
                      </Card>
                    </Grid>
                </Grid>
            </Card>
         
        </Grid>
     
       
       
    </>
    
  )
}

export default Cart