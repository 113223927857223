import React from 'react';
import TimelineChart from './TimeLine';
import { Card, CardHeader, IconButton, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

const LineChart = () => {
    const options= {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      }
     const  series= [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        },
        {
          name: "series-3",
          data: [91, 70,60,49, 50, 44, 40, 30, 1]
        }
      ]

  const data = [
    {
      data: [
        {
          x: 'Project 1',
          y: [new Date('2023-01-01').getTime(), new Date('2023-03-15').getTime()],
        },
        {
          x: 'Project 2',
          y: [new Date('2023-02-10').getTime(), new Date('2023-04-20').getTime()],
        },
        {
          x: 'Project 3',
          y: [new Date('2023-03-05').getTime(), new Date('2023-05-10').getTime()],
        },
      ],
    },
  ];

  return (
    <Card sx={{my:1}}>
        <CardHeader
        action={<IconButton>
            <MoreVert/>
        </IconButton>}
        title='Your Promo'/>
      
      <TimelineChart data={series}  options={options}/>
    </Card>
  );
};

export default LineChart;
