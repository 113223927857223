import hero1 from '../assets/hero1.png'
import hero2 from '../assets/hero2.png'
import hero4 from '../assets/hero4.png'


const listCourses=[
    {id:'1', title:'Mr. Rohit Sharma', img :hero1, button1 :'Explore Me', button2:'Add To Cart', desc:'course creation date', text:'fffff'},
    {id:'2', title:'Mr. Rohit Sharma', img :hero2, desc:'course creation date', text:'hh'},
    {id:'3', title:'Mr. Rohit Sharma', img :hero2, desc:'course creation date', text:'ggsgg'},
    {id:'4', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date', text:'dhdh'},    
    {id:'5', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
    {id:'6', title:'Mr. Rohit Sharma', img :hero4, desc:'course creation date'},    
   
  ]
  export {listCourses}