import { ExpandMore, Forward10Sharp } from '@mui/icons-material'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import {hero1} from '../../assets'
const BecomeStudentCard = ({data}) => {
  return (
    <Card elevation={0} sx={{my:2, mx:2, boxShadow:5}}>
        <CardMedia
        component='img'
        src={hero1}
        />
        <CardContent sx={{textAlign:'center'}}>
            <Typography 
            component='div'
            variant='h4'>
                {data.title}
            </Typography>
            <Typography 
            component='div'
            variant='caption'>
            {data.desc ? data.desc:   'Access millions of expert solutions designed for your best study sessions. Learn by seeing each clear & concise step.1'}
            </Typography>
        </CardContent>
        <CardActions sx={{placeItems:'center'}}>
            <Button sx={{mx:'auto'}} endIcon={<Forward10Sharp/>}>
                Learn More 
            </Button>
        </CardActions>
       </Card>
  )
}

export default BecomeStudentCard