import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Banner3 } from "../../assets";
import "./css/Slick.css";

const Carousel = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		window.addEventListener("resize", handleResize);
		handleResize(); // Check initial size

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
	};

	return (
		<>
			{!isMobile ? (
				<div className="slider-container">
					<Slider {...settings} style={{ filter: "brightness(0.7)" }}>
						<div>
							<img
								src={Banner3}
								alt="Slide 1"
								style={{ width: "100vw", height: "500px" }}
							/>
						</div>
						<div>
							<img
								src={Banner3}
								alt="Slide 2"
								style={{ width: "100vw", height: "500px" }}
							/>
						</div>
						<div>
							<img
								src={Banner3}
								alt="Slide 3"
								style={{ width: "100vw", height: "500px" }}
							/>
						</div>
					</Slider>
				</div>
			) : (
				<div className="mobile-background"></div>
			)}
		</>
	);
};

export default Carousel;
