const Exams=[
    {title:'Expert Q&As',desc:'Access millions of expert solutions designed for your best study sessions. Learn by seeing each clear & concise step.1'},
    {title:'Pro writing feedback',desc:'Access millions of expert solutions designed for your best study sessions. Learn by seeing each clear & concise step.1'},
    {title:'Practice exams',desc:'Access millions of expert solutions designed for your best study sessions. Learn by seeing each clear & concise step.1'},
   ]
  
  
   const studyTools =[
    {title:'Homework help',desc:'Study with 85+ million step-by-step explanations1, Expert Q&As & math support.'},
    {title:'Expert Q&As',desc:'Access millions of expert solutions designed for your best study sessions. Learn by seeing each clear & concise step.1'},
    {title:'Expert Q&As',desc:'Access millions of expert solutions designed for your best study sessions. Learn by seeing each clear & concise step.1'},
    {title:'Expert Q&As',desc:'Access millions of expert solutions designed for your best study sessions. Learn by seeing each clear & concise step.1'},
   ]
   export {studyTools, Exams}