
import React from 'react'
import{ hero1,hero2, hero4} from '../assets'

const bloglist=[
    {id:'React_developer_blogs', title:'React Developer blogs', img :hero1, know:'gggggggggggggg', time:'2 hr ago'},
    {id:'Node_developer_blogs', title:'React Developer blogs', img :hero2,   know:'gggggggggggggg', time:'2 hr ago'},
    {id:'Angular_developer_blogs', title:'React Developer blogs', img :hero2,   know:'gggggggggggggg', time:'2 hr ago'},
    {id:'Full_developer_blogs', title:'React Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
    {id:'Backend_developer_blogs', title:'React Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
    {id:'Java_developer_blogs', title:'React Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
    {id:'mern_developer_blogs', title:'React Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
    {id:'Django_developer_blogs', title:'Mean Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
    {id:'Python_developer_blogs', title:'React Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
    {id:'AI_developer_blogs', title:'React Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
    {id:'React_developer_blogs', title:'React Developer blogs', img :hero4,   know:'gggggggggggggg', time:'2 hr ago'},    
  ]
  export {bloglist}