import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Card, CardMedia, Container, Input, Stack, Typography } from '@mui/material'
import React from 'react'
import img from '../../assets/hero1.png'
import { Link } from 'react-router-dom'

const StudentEnrolled = () => {
    const earn =[
        {id:1, title:'Current Month', earning:'0.00'},
        {id:2, title:'Total Enrolled', earning:'0.00'},
        {id:3, title:'Repeat Student', earning:'0.00'},
        {id:4, title:'Last Student', earning:'0.00'},
    ]
  return (
    <>
    <Container maxWidth='sm'>
    <Box mt={{lg:3,xs:0}}>
         <Stack direction='row'  justifyContent='flex-start'>
         <Link to='/dashboard'>
             <ArrowBack/> 
             </Link>
             <Typography
             component='div'
             fontWeight={800}
             variant='h6'>
               Refer Earning
             </Typography>
         </Stack>
         <Card sx={{bgcolor:'transparent'}}>
            <CardMedia
            component='img'
            sx={{
                objectFit:'contain',
                height:'200px'
               }}
            src={img}
            />
         </Card>

         <Card sx={{pt:1,mt:{lg:1,xs:1}}}>
            <Box  mx={1} my={0.5}>
                {earn.map((card )=>
                
             <Stack 
            
             key={card.id}
             borderRadius={2}
             my={1}
             textAlign='center'
             bgcolor='lightgrey'
             fontWeight={800} direction='row' justifyContent='space-between' 
             px={1}>
                <Typography
                component='div'
                variant='h6'>
                   {card.title}
                </Typography>
                <Typography
                textAlign='center'
                component='div'
                color='lightcoral'
                variant='body1'>
                  {card.earning}
                </Typography>
             </Stack>
             )}
             <Stack my={2}>
                <Typography
                component='div'
                variant='caption'>
                    Please Enter You Email Id
                </Typography>
                <Input/>
             </Stack>
               <Stack>
             <Stack mt={{md:3,xs:0}} direction='row' justifyContent='space-evenly'>
                <Button
                sx={{boxShadow:5}}
                >
                    Add
                </Button>
                <Button
                sx={{boxShadow:5}}
                >
                    Exit
                </Button>
             </Stack>
             <Button  sx={{boxShadow:5, px:3,mx:'auto', mt:4, mb:2}}
             >
                Download Statistics
             </Button>
                    </Stack>
            </Box>
         </Card>
    </Box>
    </Container>
    </>
  )
}

export default StudentEnrolled