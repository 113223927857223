import { Box, Button, Card, CardMedia, Grid, Input, InputBase, Stack, Typography } from '@mui/material'
import React from 'react'
import img from '../../../assets/hero1.png'
import { ArrowBack } from '@mui/icons-material'
const StudentIssue = () => {
  return (
    <>
    
    <Box mt={{md:3,xs:0}}>
       <Typography
       component='div'
       variant='subtitle1'>
        <ArrowBack/>
        List Student Issue
       </Typography>
       <Box my={3}>
        <Card>
            <CardMedia
            component='img'
            sx={{height:'200px'}}
            src={img}
            />
        </Card>
        </Box>
      
        <Stack mt={3} py={2} mx={{lg:8, md:6,xs:3}} rowGap={1}>
         
         <Box borderRadius={2} px={1} boxShadow={5}>
         <InputBase placeholder='Enter Studet Id' fullWidth/>
         </Box>
         <Box borderRadius={2} px={1} boxShadow={5}>
         <InputBase placeholder='Enter Studet Name' fullWidth/>
         </Box>
         <Box borderRadius={2} px={1} boxShadow={5}>
         <InputBase placeholder='Enter Lecture Id' fullWidth/>
         </Box>
         <Box borderRadius={2} px={1} boxShadow={5}>
         <InputBase multiline
         rows={5} placeholder='Comment' fullWidth/>
         </Box>
         <Button sx={{boxShadow:5,mx:'auto', px:3}}>
            Submit
         </Button>
        </Stack>

        </Box>
        </>
  )
}

export default StudentIssue