import React, { useEffect, useState } from 'react'

import { AccessAlarm, CircleOutlined, UsbOff, UsbOutlined, UsbRounded } from '@mui/icons-material'

import ReelCard from '../component/common/ReelCard'
import { reel1, reel2, hero1 } from '../assets'
import { Box, Container, List, ListItem, Typography } from '@mui/material'
import FrenchiseCard from '../component/joinwithus/FrenchiseCard'

import Loading from '../component/common/Loading'
import { Helmet } from 'react-helmet'
 
const About = () => {
  const reels =[
    {id:1, src:reel1, },
    // {id:1, src:Reel2, },
    // {id:1, src:Reel, },
    // {id:1, src:Reel2, },
    // {id:1, src:Reel, },
    // {id:1, src:Reel2, },
    // {id:1, src:Reel, },
    // {id:1, src:Reel2, },

  ]


  const [isLoding, setisLoding] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setisLoding(false)
    }, 1000);
  
  },[])
  
if(isLoding){
  return<Loading/>
}
  return (<>
  
    <Helmet>
     <title>About US</title>
    </Helmet>
    <Container sx={{mt:5, mb:5}} >
    <Box sx={{ 
      boxShadow:'10',
    }}>
    <FrenchiseCard 
           direction={{md:'row-reverse',xs:'column-reverse'}}
           title='Sudo24 Learning Pvt ltd '
           img={hero1}

           >
           <Typography lineHeight={1.2} mb={2} component='div' fontWeight={800} color='orangered' variant='h3'>
                Private Limited
            </Typography>
            <Typography  lineHeight={1.2}  my={1} component='div' fontWeight={600} variant='h5'>
                
                Sudo24 Learning is an online learning platform that provides high-quality, affordable courses to students of all levels. Our courses are taught by experienced instructors and cover a wide range of topics, including business, technology, and personal development.
            </Typography>
         
           </FrenchiseCard>
           </Box>
           <Box my={2} boxShadow={5}>
    <FrenchiseCard 
           direction={{md:'row-reverse',xs:'column-reverse'}}
           title='About Our '
           img={hero1}
           >
           <Typography lineHeight={1.2} mb={1} component='div' fontWeight={800} color='orangered' variant='h3'>
                 Company
            </Typography>
            <Typography  lineHeight={1.2}  my={1} component='div' fontWeight={600} variant='subtitle1'>  
            Sudo24 Learning was founded in 2023 by two experienced educators who saw a need for a more affordable and accessible online learning platform. Our team is passionate about education, and we are committed to providing our students with the best possible learning experience
            </Typography>
              <Typography 
              component='div'
              variant='h5'>
                Our Mission
              </Typography>
            <Typography  lineHeight={1.2}  my={1} component='div' fontWeight={600} variant='subtitle1'>  
            Our mission is to make education accessible to everyone, regardless of their location or financial situation. We believe that everyone has the right to learn, and we are committed to providing our students with the resources they need to succeed.
            </Typography>
           </FrenchiseCard>
           </Box>
           <Box my={2} boxShadow={5}>
           <FrenchiseCard 
           direction={{md:'row',xs:'column-reverse'}}
           title=' What we<br/> Offer '
           img={hero1}
           >  
           <Typography 
           component='div'
           variant='subtitle1'
           >
            We offer a wide range of courses, taught by experienced instructors. Our courses are designed to be engaging and interactive, and we offer a variety of learning formats to meet the needs of our students. We also offer a variety of support services to help our students succeed, including online tutoring, discussion forums, and career counseling
            </Typography> 
            <Typography 
            component='div'
            variant='h4'>
              Products and Services
            </Typography>
            <Typography
            component='div'
            variant='subtitle1'>
              Sudo24 Learning offers a wide range of courses, taught by experienced instructors. Our courses cover a wide range of topics, including business, technology, academics and personal development.
            </Typography>
           </FrenchiseCard>    
           </Box>
           <Box 
           my={2}
           boxShadow={8}>
             <FrenchiseCard 
           direction={{md:'row-reverse',xs:'column-reverse'}}
           title='Become a Teacher '
           img={hero1}
           >
             <Typography 
             component='div'
             variant='subtitle1'>
              Sudo24 is an online learning platform that provides all types of education like academics, skill development, entertainment, competitional and crash courses. And it enrolls the teachers who will teach online whatever he or she wants to teach. The company will charge 10,000 INR from the teacher to enroll on the application and it is a one-time fee.
When the teacher is enrolled the company will deliver you some recording material and also gives 2000 students reach. Before teaching company gives you 15 days of training that how can use application and teach. You will earn up to 25k to 35k monthly.
             </Typography>
             <Typography
             component='div'
             variant='h4'
             fontWeight={800}>
              We provide services to the teacher
             </Typography>
              <List >
                <ListItem>
                Teacher Dashboard
                </ListItem>
                <ListItem>
Personalized Training
</ListItem>
<ListItem>
Lifetime Validity
</ListItem>
<ListItem>
Recording Equipment
</ListItem>
<ListItem>
Free Of Cost Marketing
</ListItem>
<ListItem>
Student Gathering To Buy Your Class
</ListItem>
<ListItem>
Copyright Protection For Your Content
</ListItem>
<ListItem>
2-year sheets for Notes Writing
</ListItem>
<ListItem>
T-Shirt with Logo
                </ListItem>
              </List>
              

           </FrenchiseCard>
           </Box>
           <Box my={2} boxShadow={5}>

           </Box>

           </Container></>

  )
}

export default About