import { AccountBoxTwoTone, Facebook, FactCheck, Google, QuestionMark } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardMedia, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography,  } from '@mui/material'

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';



import React, { useState } from 'react'
import CssCarousal from '../component/slider/CssCarousal'
import { SwiperSlide } from 'swiper/react'
import img from '../assets/hero1.png'
import {  useNavigate } from 'react-router-dom'
import MultipleSelect from '../component/common/Select'

const Prefrences = () => {
   const [disable, setdisable] = useState(true)
    const [type, setType] = useState(
        localStorage.getItem('_role')
        );

        const [prefrence, setPrefrence] = useState({})
        const [page, setPage] = useState(
        localStorage.getItem('page')

    )


    const theme = useTheme()
  const navigate = useNavigate()
    
    
      const handleClick= ()=>{
        if(type =='student') {
        page =='login'?  navigate('/login') :navigate('/register')
        } 
        if(type =='teacher') {
        page =='login'?  navigate('/teacherlogin') :navigate('/teacherregistration')
        } 
      }
   





    
      const handleChange =(event)=>{
        const name =event.target.name
        const value =event.target.value
       
        setPrefrence({
          ...prefrence, [name]:value
        })
      
      }

  

    const category = [
        'Academics',
        'Crash Course',
        'Comptitional',
        'Skill Development',
        'Motivational',
        'Life Style',
        'Religious',
        
      ];
    const lanuages = [
        'Hindi',
        'English',
        'Hinglish',
        'Bodo',
        'Nepali',
        'Maithili',
        'Dogri',
        'Marathi',
        'Sanskrit',
        'Kelly Snyder',
      ];
  return (
    <div>
    <Container sx={{mt:5}} maxWidth='lg'>
      <Box sx={{ 
        boxShadow:'10',
        
      }}>
        <Grid direction={{md:'row',xs:'column-reverse'}} container >
          <Grid item xs={12} lg={6}>
          <Card sx={{bgcolor:'transparent'}} variant='elevation' elevation={0}>
        <Box sx={{m:5}}  rowGap={3}>
            <Stack sx={{mb:3}}>
                <Typography fontWeight={600} fontSize={30}  component='h1'>

               Select  Your Prefrences
                </Typography>
            </Stack>
            <Stack rowGap={2}>

            <MultipleSelect label='Languages' name={'languages'} personName={prefrence.languages} handleChange={handleChange} names={lanuages}/>
            <MultipleSelect label='Category' name={'category'} handleChange={handleChange} personName={prefrence.category} names={category}/>
           
      

            {/* {prefrence ? */}
            <Button  disabled={prefrence.languages && prefrence.category ? false : true} variant='contained'
            onClick={handleClick}
            size='large' sx={{
                    
                    my:5
                  }} color='secondary'>
                    Proceed
                  </Button>
                  {/* :
                  <Button disabled variant='contained' size='large' sx={{
                  
                  my:5
                }} color='secondary'>
                  Proceed
                </Button>} */}
            </Stack>
        </Box>
      </Card>
                    </Grid>
                    <Grid xs={12} lg={6}>
                      <CssCarousal>
                      {
[0,1,3].map((key)=>
<SwiperSlide key={key}>
<CardMedia
  component="img"
  alt="Contemplative Reptile album cover"
  src={ img}
  sx={{
    position:'relative',
      p:0,
      my:2,
      width: { xs: '100%', sm: '100%' },
    borderRadius: 2,
    ":hover":{
      transitionTimingFunction:'ease-in-out',
      backgroundColor:'blueviolet'
    }
  }}
/>
</SwiperSlide>
)}

                      </CssCarousal>
                    </Grid>
                  </Grid>
      <Card>

      </Card>
      </Box>
    </Container>
  </div>
  )
}

export default Prefrences

